import * as React from "react";
import { isDesktop } from "react-device-detect";
import { Dispatch } from "@typescript-tea/core";
import { StandardButton, MenuButton } from "@genesys/ui-elements";
import {
  MainContainer,
  Root,
  FixedContainer,
  RightSideMenu,
  LeftSideMenu
} from "./elements";
import { State, Action } from "./state";
import { clientConfig } from "../config";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../shared-state";
import {
  ColoredBorder,
  GenesysLogo,
  DropDownMenu,
  UserSettingsAnchor,
  UserMenu,
  HelpMenu
} from "./components";
import { getPermissions } from "./functions";

export function MainMenuView({
  state,
  sharedState,
  dispatch,
  onGenesyslogoClick
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly onGenesyslogoClick: () => void;
}) {
  const {
    canSeeMRC,
    canUsePricing,
    canSeePricing,
    canSeeMoistureload,
    canSeeSystemSelectionGuide
  } = getPermissions(sharedState);

  const getPricingMenuOptions = () => {
    const pricingManagerOption = {
      text: sharedState.translate(LanguageTexts.pricingmanager()),
      href: "/pricing-manager"
    };

    if (!canUsePricing) {
      return [pricingManagerOption];
    } else {
      return [pricingManagerOption].concat([
        {
          text: sharedState.translate(LanguageTexts.newPricing()),
          href: "/new-pricing"
        }
      ]);
    }
  };

  const toolOptions = [
    {
      text: sharedState.translate(LanguageTexts.airMixer()),
      href: "/tools/air-mixing"
    },

    {
      text: sharedState.translate(LanguageTexts.airPowerTool()),
      href: "/tools/air-power"
    },

    {
      text: sharedState.translate(LanguageTexts.humidityConversion()),
      href: "/tools/humidity-conversion"
    },

    {
      text: sharedState.translate(LanguageTexts.psychrometricChart()),
      href: "/tools/psychrometric-chart"
    },
    {
      text: sharedState.translate(LanguageTexts.binPage()),
      href: "/tools/weather-data-binning"
    }
  ];

  const moistureLoadOptions = [
    {
      text: sharedState.translate(LanguageTexts.moistureLoadManager()),
      href: "/moisture-load-manager"
    },
    {
      text: sharedState.translate(LanguageTexts.newMoistureLoadCalc()),
      href: "/new-moisture-load"
    }
  ];

  return (
    <Root>
      <FixedContainer>
        <MainContainer>
          <RightSideMenu>
            <li>
              <div
                onClick={e => {
                  e.stopPropagation();
                  onGenesyslogoClick();
                }}
              >
                <GenesysLogo env={clientConfig.environment} />
              </div>
            </li>
            <li>
              <a href="/">
                <MenuButton
                  children={sharedState
                    .translate(LanguageTexts.dashboard())
                    .toUpperCase()}
                  hasMenu={false}
                />
              </a>
            </li>
            <li>
              <a href="/system-manager">
                <MenuButton
                  children={sharedState
                    .translate(LanguageTexts.systems())
                    .toUpperCase()}
                  hasMenu={false}
                />
              </a>
            </li>
            {(canUsePricing || canSeePricing) && (
              <li>
                <DropDownMenu
                  state={state}
                  menuType="pricing"
                  options={getPricingMenuOptions()}
                  menuTitle={sharedState
                    .translate(LanguageTexts.pricing())
                    .toUpperCase()}
                  dispatch={dispatch}
                />
              </li>
            )}
            <li>
              <DropDownMenu
                state={state}
                menuType="tools"
                options={toolOptions}
                menuTitle={sharedState
                  .translate(LanguageTexts.tools())
                  .toUpperCase()}
                dispatch={dispatch}
              />
            </li>
            {canSeeMoistureload && (
              <li>
                <DropDownMenu
                  state={state}
                  menuType="moisture-load"
                  options={moistureLoadOptions}
                  menuTitle={
                    isDesktop
                      ? sharedState
                          .translate(LanguageTexts.moistureLoad())
                          .toUpperCase()
                      : "MLC"
                  }
                  dispatch={dispatch}
                />
              </li>
            )}
            <li>
              <a href="/new-system">
                <StandardButton
                  size="Small"
                  buttonType="PrimaryBlue"
                  style={{ border: 0 }}
                >
                  {sharedState
                    .translate(LanguageTexts.createNewSystem())
                    .toUpperCase()}
                </StandardButton>
              </a>
            </li>
            {canSeeSystemSelectionGuide && (
              <li>
                <a href="/system-selection-guide">
                  <StandardButton
                    size="Small"
                    buttonType="PrimaryBlue"
                    style={{ border: 0 }}
                  >
                    Selection Guide
                  </StandardButton>
                </a>
              </li>
            )}
            {canSeeMRC && (
              <li>
                <StandardButton
                  size="Small"
                  buttonType="PrimaryBlue"
                  style={{ border: 0 }}
                  onClick={() =>
                    window.open("https://mrc.genesys.munters.com/")
                  }
                >
                  MRC
                </StandardButton>
              </li>
            )}
          </RightSideMenu>
          <LeftSideMenu>
            <li>
              <HelpMenu sharedState={sharedState} dispatch={dispatch} />
            </li>
            <li>
              <UserSettingsAnchor sharedState={sharedState} />
            </li>
            <li>
              <UserMenu sharedState={sharedState} />
            </li>
          </LeftSideMenu>
        </MainContainer>
        <ColoredBorder env={clientConfig.environment} />
      </FixedContainer>
    </Root>
  );
}
