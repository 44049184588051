/* tslint:disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Amount: string;
  DateTime: any;
  GenesysDate: number;
  Guid: string;
  JSON: any;
  PropertyFilter: string;
  PropertyValue: string;
  PropertyValueSet: string;
};

export type AcceptanceStatusFilterItemType = {
  readonly selectedStatus: ReadonlyArray<Scalars["Int"]>;
};

export type AccessoryEntryInputType = {
  /** product id id */
  readonly productId: Scalars["ID"];
  /** Properties */
  readonly properties: Scalars["String"];
};

export type AddComponentInputType = {
  /** Component id */
  readonly componentId: Scalars["Guid"];
  /** Compnent section positions */
  readonly componentSectionPositions: ReadonlyArray<ComponentSectionPositionInputType>;
  /** Product id */
  readonly productId: Scalars["String"];
  /** System id */
  readonly systemId: Scalars["Guid"];
};

export type AddComponentMutationResultType = {
  readonly __typename?: "AddComponentMutationResultType";
  /** Cache patches */
  readonly cachePatches: ReadonlyArray<CachePatchType>;
  /** id */
  readonly id: Scalars["ID"];
};

export type AddOrUpdateMCompareComponentInputType = {
  /** Product id */
  readonly productId: Scalars["String"];
  /** Properties */
  readonly properties: Scalars["String"];
  /** System id */
  readonly systemId: Scalars["ID"];
};

export type AddOrUpdateMCompareComponentMutationResultType = {
  readonly __typename?: "AddOrUpdateMCompareComponentMutationResultType";
  /** Cache patches */
  readonly cachePatches: ReadonlyArray<CachePatchType>;
  /** Id */
  readonly id: Scalars["ID"];
};

export type AdvancedProductSearchInputType = {
  /** from date */
  readonly fromDate: Scalars["String"];
  /** Operating case results property filter. */
  readonly operatingCaseResultsPropertyFilter: Scalars["String"];
  /** Product id. */
  readonly productId: Scalars["String"];
  /** Product property filter. */
  readonly productPropertyFilter: Scalars["String"];
  /** to date */
  readonly toDate: Scalars["String"];
};

export type AdvancedProductSearchItemOperatingCaseResultType = {
  readonly __typename?: "AdvancedProductSearchItemOperatingCaseResultType";
  /** Name of the operating case */
  readonly caseName: Scalars["String"];
  /** Result */
  readonly result: Scalars["String"];
};

export type AdvancedProductSearchItemType = {
  readonly __typename?: "AdvancedProductSearchItemType";
  /** Genesys number */
  readonly genesysNo: Scalars["Int"];
  /** Id */
  readonly id: Scalars["ID"];
  /** Name */
  readonly name?: Maybe<Scalars["String"]>;
  /** OperatingCase results */
  readonly operatingCaseResults: ReadonlyArray<AdvancedProductSearchItemOperatingCaseResultType>;
  /** Price date */
  readonly priceDate?: Maybe<Scalars["GenesysDate"]>;
  /** Product id */
  readonly productId: Scalars["String"];
  /** Properties */
  readonly properties?: Maybe<Scalars["String"]>;
  /** Revision number */
  readonly revisionNo: Scalars["Int"];
  /** Status date */
  readonly statusDate: Scalars["GenesysDate"];
  /** System id */
  readonly systemId?: Maybe<Scalars["Guid"]>;
  /** System status */
  readonly systemStatus: SystemStatus;
  /** Username */
  readonly userName?: Maybe<Scalars["String"]>;
};

export type AdvancedProductSearchResultType = {
  readonly __typename?: "AdvancedProductSearchResultType";
  /** Error message */
  readonly error?: Maybe<Scalars["String"]>;
  /** Id of the search request */
  readonly id: Scalars["ID"];
  /** Search results */
  readonly results: ReadonlyArray<AdvancedProductSearchItemType>;
};

export type AirFlowStateType = {
  readonly __typename?: "AirFlowStateType";
  /** Flow */
  readonly flow: Scalars["Amount"];
  /** Volume Flow */
  readonly volumeFlow: Scalars["Amount"];
};

export type AirPointPropertyType = {
  readonly __typename?: "AirPointPropertyType";
  /** Air property */
  readonly airProperty: AirProperty;
  /** Air point property id */
  readonly id: Scalars["ID"];
  /** Air property name */
  readonly name: Scalars["String"];
  /** Air point property propertyfilter */
  readonly propertyFilter?: Maybe<Scalars["PropertyFilter"]>;
  /** Air point property sort number */
  readonly sortNo: Scalars["Int"];
};

export type AirPointType = {
  readonly __typename?: "AirPointType";
  /** Air Flow state */
  readonly airFlowState: AirFlowStateType;
  /** Label of the airpoint */
  readonly label: Scalars["String"];
};

/** Air property enum */
export enum AirProperty {
  ABSOLUTE_PRESSURE = "ABSOLUTE_PRESSURE",
  DENSITY = "DENSITY",
  DEW_POINT_TEMPERATURE = "DEW_POINT_TEMPERATURE",
  HUMIDITY_RATIO = "HUMIDITY_RATIO",
  MASS_FLOW = "MASS_FLOW",
  RELATIVE_HUMIDITY = "RELATIVE_HUMIDITY",
  RELATIVE_PRESSURE = "RELATIVE_PRESSURE",
  TEMPERATURE = "TEMPERATURE",
  VOLUME_FLOW = "VOLUME_FLOW",
  WET_TEMPERATURE = "WET_TEMPERATURE"
}

export type AirstreamType = {
  readonly __typename?: "AirstreamType";
  /** Component section */
  readonly componentSections: ReadonlyArray<ComponentSectionType>;
  /** Id */
  readonly id: Scalars["ID"];
};

export type AmountFieldFormatInputType = {
  readonly amountFieldProfileId: Scalars["String"];
  readonly decimalCount: Scalars["Int"];
  readonly fieldGroup: Scalars["String"];
  readonly fieldName: Scalars["String"];
  readonly unit: Scalars["String"];
};

export type AmountFieldFormatType = {
  readonly __typename?: "AmountFieldFormatType";
  /** Number of decimals */
  readonly decimalCount: Scalars["Int"];
  /** Field group */
  readonly fieldGroup: Scalars["String"];
  /** Field name */
  readonly fieldName: Scalars["String"];
  /** Unique id for the fieldformat */
  readonly id: Scalars["ID"];
  /** Measuresystem for the field format */
  readonly measureSystem: Scalars["Int"];
  /** Unit string */
  readonly unit: Scalars["String"];
};

export type AmountFieldIdType = {
  readonly __typename?: "AmountFieldIdType";
  /** Field group */
  readonly fieldGroup: Scalars["String"];
  /** Field name */
  readonly fieldName: Scalars["String"];
};

export type AmountFieldProfileType = {
  readonly __typename?: "AmountFieldProfileType";
  /** FieldFormats for profile with fieldGroup */
  readonly amountFieldFormats: ReadonlyArray<AmountFieldFormatType>;
  /** Amount field profile id */
  readonly id: Scalars["ID"];
  /** If profile can be edited */
  readonly isEditable: Scalars["Boolean"];
  /** Measuresystem for the profile */
  readonly measureSystem: Scalars["Int"];
  /** Name of the profile */
  readonly name: Scalars["String"];
};

export type AmountFieldValueType = {
  readonly __typename?: "AmountFieldValueType";
  /** Amount */
  readonly amount: Scalars["String"];
  /** Amount field */
  readonly amountField: AmountFieldIdType;
};

export type AmountProfileInputType = {
  readonly id: Scalars["String"];
  readonly measureSystem: Scalars["Int"];
  readonly name: Scalars["String"];
};

export type ApplicationClaimType = {
  readonly __typename?: "ApplicationClaimType";
  /** Claim context */
  readonly context: Scalars["String"];
  /** unique id for claim */
  readonly id: Scalars["ID"];
  /** Claim key */
  readonly key: Scalars["String"];
  /** Claim values */
  readonly values: ReadonlyArray<Scalars["String"]>;
};

export type BinLocationType = {
  readonly __typename?: "BinLocationType";
  /** Bin data location id. */
  readonly binDataLocationId: Scalars["String"];
  /** Country name. */
  readonly countryName: Scalars["String"];
  /** Latitude. */
  readonly latitude: Scalars["Float"];
  /** Location name. */
  readonly locationName: Scalars["String"];
  /** Longitude. */
  readonly longitude: Scalars["Float"];
  /** Region name. */
  readonly regionName: Scalars["String"];
};

export type BinOperatingCaseInputType = {
  /** Bin data */
  readonly binData: Scalars["String"];
  /** Bin index */
  readonly binIndex: Scalars["Int"];
  /** Settings */
  readonly settings: Scalars["String"];
};

export type BoxConnectionPointType = {
  readonly __typename?: "BoxConnectionPointType";
  /** Box */
  readonly box: Scalars["String"];
  /** Connection type */
  readonly connectionType: Scalars["String"];
  /** Diagram type */
  readonly diagramType: Scalars["String"];
  /** Hide state point */
  readonly hideStatePoint: Scalars["Boolean"];
  /** Id */
  readonly id: Scalars["ID"];
  /** Mirror x */
  readonly mirrorX: Scalars["Boolean"];
  /** Mirror y */
  readonly mirrorY: Scalars["Boolean"];
  /** Position x */
  readonly positionX: Scalars["Float"];
  /** Position y */
  readonly positionY: Scalars["Float"];
  /** Position z */
  readonly positionZ: Scalars["Float"];
  /** Product id */
  readonly productId: Scalars["String"];
  /** Product section id */
  readonly productSectionId: Scalars["String"];
  /** Property filter */
  readonly propertyFilter?: Maybe<Scalars["PropertyFilter"]>;
  /** Rotation */
  readonly rotation: Scalars["Float"];
};

export type BoxLinePointType = {
  readonly __typename?: "BoxLinePointType";
  /** Box */
  readonly box: Scalars["String"];
  /** Connection id */
  readonly connectionId: Scalars["String"];
  /** Diagram type */
  readonly diagramType: Scalars["String"];
  /** Direction */
  readonly direction: Scalars["String"];
  /** Id */
  readonly id: Scalars["ID"];
  /** Line type */
  readonly lineType: Scalars["String"];
  /** Position x */
  readonly positionX: Scalars["Float"];
  /** Position y */
  readonly positionY: Scalars["Float"];
  /** Property filter */
  readonly propertyFilter?: Maybe<Scalars["PropertyFilter"]>;
};

export type BoxSymbolType = {
  readonly __typename?: "BoxSymbolType";
  /** Absolute rotation and scale */
  readonly absoluteRotationAndScale: Scalars["Boolean"];
  /** Box */
  readonly box: Scalars["String"];
  /** Diagram type */
  readonly diagramType: Scalars["String"];
  /** Id */
  readonly id: Scalars["ID"];
  /** Mirror x */
  readonly mirrorX: Scalars["Boolean"];
  /** Mirror y */
  readonly mirrorY: Scalars["Boolean"];
  /** Position x */
  readonly positionX: Scalars["Float"];
  /** Poistion y */
  readonly positionY: Scalars["Float"];
  /** Position z */
  readonly positionZ: Scalars["Float"];
  /** Property filter */
  readonly propertyFilter?: Maybe<Scalars["PropertyFilter"]>;
  /** Rotation */
  readonly rotation: Scalars["Float"];
  /** Size x */
  readonly sizeX: Scalars["Float"];
  /** Size y */
  readonly sizeY: Scalars["Float"];
  /** Symbol id */
  readonly symbolId: Scalars["String"];
  /** Use for selection bounds */
  readonly useForSelectionBounds: Scalars["Boolean"];
};

export type BoxTextType = {
  readonly __typename?: "BoxTextType";
  /** Absolute position */
  readonly absolutePosition: Scalars["Boolean"];
  /** Box */
  readonly box: Scalars["String"];
  /** Diagram type */
  readonly diagramType: Scalars["String"];
  /** Font size */
  readonly fontSize: Scalars["Float"];
  /** Id */
  readonly id: Scalars["ID"];
  /** Position x */
  readonly positionX: Scalars["Float"];
  /** Position y */
  readonly positionY: Scalars["Float"];
  /** Position z */
  readonly positionZ: Scalars["Float"];
  /** Property filter */
  readonly propertyFilter?: Maybe<Scalars["PropertyFilter"]>;
  /** Rotation */
  readonly rotation: Scalars["Float"];
  /** Source */
  readonly source: Scalars["String"];
  /** Text */
  readonly text: Scalars["String"];
  /** Use as label */
  readonly useAsLabel: Scalars["Boolean"];
};

export type BuildInRevitStateInputType = {
  /** System id */
  readonly systemId: Scalars["ID"];
};

export type BuildInRevitStateType = {
  readonly __typename?: "BuildInRevitStateType";
  /** Image data */
  readonly imageData?: Maybe<Scalars["String"]>;
  /** Model link */
  readonly modelLink: Scalars["String"];
  /** State */
  readonly state: Scalars["Int"];
  /** State description */
  readonly stateDescription: Scalars["String"];
};

export type BuildInSolidContactStateInputType = {
  /** System id */
  readonly systemId: Scalars["ID"];
};

export type BuildInSolidContactStateType = {
  readonly __typename?: "BuildInSolidContactStateType";
  /** Image data */
  readonly imageData?: Maybe<Scalars["String"]>;
  /** Model link */
  readonly modelLink: Scalars["String"];
  /** State */
  readonly state: Scalars["Int"];
  /** State description */
  readonly stateDescription: Scalars["String"];
};

export type Co2FactorType = {
  readonly __typename?: "CO2FactorType";
  /** Country */
  readonly country: Scalars["ID"];
  /** Emission index */
  readonly emissionIndex: Scalars["ID"];
  /** Year */
  readonly year: Scalars["ID"];
};

export type CachePatchType =
  | DeleteEntityCachePatchType
  | InsertElementCachePatchType
  | InvalidateFieldCachePatchType
  | RemoveEntityElementCachePatchType
  | UpdateFieldCachePatchType;

export type CalculateEnergyBinCaseInputType = {
  /** Bin case id */
  readonly binCaseId: Scalars["ID"];
  /** System id */
  readonly systemId: Scalars["ID"];
};

export type CalculateLockedSystemInputType = {
  /** Climate data settings as property value set */
  readonly climateData: Scalars["String"];
  /** components as property value set */
  readonly components: ReadonlyArray<ComponentPropertiesInputType>;
  /** Operating cases as property value set */
  readonly operatingCases: ReadonlyArray<ComponentPropertiesInputType>;
  /** System id */
  readonly systemId: Scalars["ID"];
};

export type CalculateLockedSystemResultType = {
  readonly __typename?: "CalculateLockedSystemResultType";
  /** No errors during calculation */
  readonly hasResults: Scalars["Boolean"];
  /** Operating cases */
  readonly operatingCases: ReadonlyArray<OperatingCaseType>;
};

export type CalculateNewSystemResultType = {
  readonly __typename?: "CalculateNewSystemResultType";
  /** Components With Errors */
  readonly componentsWithErrors: ReadonlyArray<ComponentWithErrorsType>;
  /** Operating cases */
  readonly operatingCases: ReadonlyArray<OperatingCaseType>;
};

export type CaseFilterType = {
  readonly __typename?: "CaseFilterType";
  /** Calculation type */
  readonly calculationType: ComponentCalculationType;
  /** Case type */
  readonly caseType: CaseType;
  /** Claim filter */
  readonly claimFilter: Scalars["String"];
  /** Mechanical Sketch Provider id */
  readonly id: Scalars["ID"];
};

export type CaseFlowSuggestionType = {
  readonly __typename?: "CaseFlowSuggestionType";
  /** What is the source of this flow? */
  readonly flowSourceId: FlowSourceEnum;
  /** ID */
  readonly id: Scalars["Int"];
  /** Is this a valid flow? */
  readonly isValid: Scalars["Boolean"];
  /** Values to show. */
  readonly result: Scalars["PropertyValueSet"];
};

export type CaseMaxLoadResultType = {
  readonly __typename?: "CaseMaxLoadResultType";
  /** Case name resource string. */
  readonly caseName: Scalars["String"];
  /** Which type of case is this? */
  readonly caseType: MaxLoadTypeEnum;
  /** Flow suggestions. */
  readonly flowSuggestions: ReadonlyArray<CaseFlowSuggestionType>;
  /** ID */
  readonly id: Scalars["ID"];
  /** Text resource string for the type of need. */
  readonly needResourceString: Scalars["String"];
  /** Results for this case. */
  readonly operatingCaseResults: Scalars["PropertyValueSet"];
  /** List of warnings for this operating case. */
  readonly roomWarnings: ReadonlyArray<RoomWarningEnum>;
  /** Selected flow id */
  readonly selectedFlowId?: Maybe<Scalars["Int"]>;
  /** Used room control settings for this case. */
  readonly usedRoomControlSettings: Scalars["PropertyValueSet"];
};

export type CaseOverrideInputType = {
  /** Moisture load settings of the case group. */
  readonly moistureLoadSettings: Scalars["String"];
  /** Id of the operating case group. */
  readonly operatingCaseGroup: OperatingCaseGroupEnum;
};

/** Case type */
export enum CaseType {
  BIN = "BIN",
  DESIGN = "DESIGN",
  ERP = "ERP",
  EUROVENT = "EUROVENT",
  RATING = "RATING"
}

export type ClimateDataBinType = {
  readonly __typename?: "ClimateDataBinType";
  /** Average temp in the bin. */
  readonly averageTemp: Scalars["Amount"];
  /** Bin id. */
  readonly binId: Scalars["Int"];
  /** Average atmospheric pressure in the bin. */
  readonly binPressure: Scalars["Amount"];
  /** Total time spent in this climate bin. */
  readonly binTime: Scalars["Amount"];
  /** April time spent in this climate bin. */
  readonly binTimeApril: Scalars["Amount"];
  /** August time spent in this climate bin. */
  readonly binTimeAugust: Scalars["Amount"];
  /** December time spent in this climate bin. */
  readonly binTimeDecember: Scalars["Amount"];
  /** February time spent in this climate bin. */
  readonly binTimeFebruary: Scalars["Amount"];
  /** January time spent in this climate bin. */
  readonly binTimeJanuary: Scalars["Amount"];
  /** July time spent in this climate bin. */
  readonly binTimeJuly: Scalars["Amount"];
  /** June time spent in this climate bin. */
  readonly binTimeJune: Scalars["Amount"];
  /** March time spent in this climate bin. */
  readonly binTimeMarch: Scalars["Amount"];
  /** May time spent in this climate bin. */
  readonly binTimeMay: Scalars["Amount"];
  /** November time spent in this climate bin. */
  readonly binTimeNovember: Scalars["Amount"];
  /** October time spent in this climate bin. */
  readonly binTimeOctober: Scalars["Amount"];
  /** September time spent in this climate bin. */
  readonly binTimeSeptember: Scalars["Amount"];
  /** Average specific humidity in the bin. */
  readonly humidity: Scalars["Amount"];
  /** Key. */
  readonly id: Scalars["ID"];
  /** Mid point dewpoint temp of the bin. */
  readonly midPointDewPointTemp?: Maybe<Scalars["Amount"]>;
  /** Mid point hour of the bin. */
  readonly midPointHourly?: Maybe<Scalars["Amount"]>;
  /** Mid point humidity ratio of the bin. */
  readonly midPointHumidityRatio?: Maybe<Scalars["Amount"]>;
  /** Mid point specific enthalpy of the bin. */
  readonly midPointSpecificEnthalpy?: Maybe<Scalars["Amount"]>;
  /** Mid point temp of the bin. */
  readonly midPointTemp?: Maybe<Scalars["Amount"]>;
  /** Mid point wet temp of the bin. */
  readonly midPointWetTemp?: Maybe<Scalars["Amount"]>;
  /** Average wind speed in the bin. */
  readonly wind: Scalars["Amount"];
};

export type CoeffInputType = {
  /** A Dry */
  readonly aDry: Scalars["Float"];
  /** A Wet */
  readonly aWet: Scalars["Float"];
  /** B Dry */
  readonly bDry: Scalars["Float"];
  /** B Wet */
  readonly bWet: Scalars["Float"];
  /** C Dry */
  readonly cDry: Scalars["Float"];
  /** C Wet */
  readonly cWet: Scalars["Float"];
  /** D Dry */
  readonly dDry: Scalars["Float"];
  /** D Wet */
  readonly dWet: Scalars["Float"];
};

export type CoeffResultType = {
  readonly __typename?: "CoeffResultType";
  /** Air velocity */
  readonly airVelocity: Scalars["String"];
  /** Airflow */
  readonly airflow: Scalars["String"];
  /** Coil pro air pressure drop */
  readonly coilProAirPressureDrop: Scalars["String"];
  /** Coil pro power */
  readonly coilProPower: Scalars["String"];
  /** Diff air pressure drop */
  readonly diffAirPressureDrop: Scalars["String"];
  /** Diff air pressure drop percentage */
  readonly diffAirPressureDropPercentage: Scalars["String"];
  /** Diff power */
  readonly diffPower: Scalars["String"];
  /** Diff power percentage */
  readonly diffPowerPercentage: Scalars["String"];
  /** Supplier air pressure drop */
  readonly supplierAirPressureDrop: Scalars["String"];
  /** Supplier power */
  readonly supplierPower: Scalars["String"];
};

export type CoeffType = {
  readonly __typename?: "CoeffType";
  /** A Dry */
  readonly aDry: Scalars["Float"];
  /** A Wet */
  readonly aWet: Scalars["Float"];
  /** B Dry */
  readonly bDry: Scalars["Float"];
  /** B Wet */
  readonly bWet: Scalars["Float"];
  /** C Dry */
  readonly cDry: Scalars["Float"];
  /** C Wet */
  readonly cWet: Scalars["Float"];
  /** D Dry */
  readonly dDry: Scalars["Float"];
  /** D Wet */
  readonly dWet: Scalars["Float"];
};

export type CoilCoeffCaseFluidInputType = {
  /** Fluid flow */
  readonly fluidFlow: Scalars["String"];
  /** Fluid temperature in */
  readonly fluidTemperatureIn: Scalars["String"];
  /** Humidity in */
  readonly humidityIn: Scalars["String"];
  /** Pressure in */
  readonly pressureIn: Scalars["String"];
  /** Temperature in */
  readonly temperatureIn: Scalars["String"];
};

export type CoilCoeffCaseFreonInputType = {
  /** Freon state */
  readonly freonState: FreonStateInputType;
  /** Humidity in */
  readonly humidityIn: Scalars["String"];
  /** Pressure in */
  readonly pressureIn: Scalars["String"];
  /** Temperature in */
  readonly temperatureIn: Scalars["String"];
};

export type CoilCoeffCaseSteamInputType = {
  /** Humidity in */
  readonly humidityIn: Scalars["String"];
  /** Pressure in */
  readonly pressureIn: Scalars["String"];
  /** Steam state */
  readonly steamState: SteamStateInputType;
  /** Temperature in */
  readonly temperatureIn: Scalars["String"];
};

export type CollectorInputType = {
  /** Diameter */
  readonly diameter: Scalars["String"];
  /** Material type */
  readonly materialType: Scalars["Int"];
  /** Number of collectors */
  readonly numberOfCollectors: Scalars["Int"];
  /** Thickness */
  readonly thickness: Scalars["String"];
};

export type CompareSystemsInputType = {
  /** Ref Genesys number */
  readonly referenceGenesysNo: Scalars["Int"];
  /** Ref Rev */
  readonly referenceRev: Scalars["Int"];
  /** Test Genesys number */
  readonly testGenesysNo: Scalars["Int"];
  /** Test Rev */
  readonly testRev: Scalars["Int"];
};

export type ComponentAlternativeVariantResultType = {
  readonly __typename?: "ComponentAlternativeVariantResultType";
  /** unique id */
  readonly id: Scalars["ID"];
  /** Result */
  readonly result: Scalars["PropertyValueSet"];
  /** result id */
  readonly resultId: Scalars["String"];
};

export type ComponentAlternativeVariantType = {
  readonly __typename?: "ComponentAlternativeVariantType";
  /** unique id */
  readonly id: Scalars["ID"];
  /** Relative cost */
  readonly relativeCost?: Maybe<Scalars["Float"]>;
  /** results */
  readonly results: ReadonlyArray<ComponentAlternativeVariantResultType>;
};

/** Component calculation type */
export enum ComponentCalculationType {
  MAX_RESULT = "MAX_RESULT",
  SELECTION = "SELECTION",
  SYSTEM_SIMULATION = "SYSTEM_SIMULATION"
}

export type ComponentCostPriceInformationInputType = {
  /** Currency code */
  readonly currency: Scalars["String"];
  /** Product id */
  readonly productId: Scalars["String"];
  /** Properties */
  readonly properties?: InputMaybe<Scalars["String"]>;
  /** Sales organisation id */
  readonly salesOrganisationId: Scalars["String"];
};

export type ComponentDocumentType = {
  readonly __typename?: "ComponentDocumentType";
  /** Id */
  readonly id: Scalars["ID"];
  /** Name */
  readonly name?: Maybe<Scalars["String"]>;
};

export type ComponentMessageType = {
  readonly __typename?: "ComponentMessageType";
  /** Id */
  readonly id: Scalars["ID"];
  /** Message code */
  readonly messageCode: Scalars["Int"];
  /** Message parameters */
  readonly messageParameters?: Maybe<Scalars["String"]>;
  /** Message severity */
  readonly messageSeverity: Scalars["Int"];
  /** Operating case result id */
  readonly operatingCaseResultId?: Maybe<Scalars["Guid"]>;
  /** Source status */
  readonly sourceStatus: Scalars["Int"];
};

export type ComponentPriceExplanationType = {
  readonly __typename?: "ComponentPriceExplanationType";
  /** Breakdown table */
  readonly breakDownTable: ReadonlyArray<CostBreakDownType>;
  /** Factors table */
  readonly factorsTable: ReadonlyArray<CostFactorType>;
  /** Type of cost */
  readonly type: Scalars["String"];
};

export type ComponentPropertiesInputType = {
  /** id */
  readonly id: Scalars["ID"];
  /** property value set as string */
  readonly properties: Scalars["String"];
};

export type ComponentSectionPositionInputType = {
  /** Airstream id */
  readonly airstreamId: Scalars["String"];
  /** Before section id */
  readonly beforeSectionId: Scalars["String"];
  /** Product section id */
  readonly productSectionId: Scalars["String"];
};

export type ComponentSectionType = {
  readonly __typename?: "ComponentSectionType";
  /** Casing component section id */
  readonly casingComponentSectionId?: Maybe<Scalars["Guid"]>;
  /** component Id */
  readonly componentId: Scalars["Guid"];
  /** Id */
  readonly id: Scalars["ID"];
  /** Movable */
  readonly movable: Scalars["Boolean"];
  /** Product section id */
  readonly productSectionId: Scalars["String"];
  /** Product section sort no */
  readonly productSectionSortNo: Scalars["Int"];
  /** Sort no */
  readonly sortNo: Scalars["Int"];
  /** System air stream id */
  readonly systemAirStreamId?: Maybe<Scalars["Guid"]>;
};

export type ComponentType = {
  readonly __typename?: "ComponentType";
  /** Accessory to id */
  readonly accessoryToId?: Maybe<Scalars["Guid"]>;
  /** alternative variants */
  readonly componentAlternativeVariants: ReadonlyArray<ComponentAlternativeVariantType>;
  /** Component Specifications */
  readonly componentSpec?: Maybe<Scalars["String"]>;
  /** Documents */
  readonly documents: ReadonlyArray<ComponentDocumentType>;
  /** External notes */
  readonly externalNotes?: Maybe<Scalars["String"]>;
  /** Id */
  readonly id: Scalars["ID"];
  /** Internal notes */
  readonly internalNotes?: Maybe<Scalars["String"]>;
  /** Manual price */
  readonly manualPrice?: Maybe<Scalars["Float"]>;
  /** Messages */
  readonly messages: ReadonlyArray<ComponentMessageType>;
  /** Product id */
  readonly productId: Scalars["String"];
  /** Properties */
  readonly properties?: Maybe<Scalars["String"]>;
  /** Sales price */
  readonly salesPrice?: Maybe<Scalars["Float"]>;
  /** Section */
  readonly sections: ReadonlyArray<ComponentSectionType>;
  /** Sort no */
  readonly sortNo: Scalars["Float"];
};

export type ComponentWithErrorsType = {
  readonly __typename?: "ComponentWithErrorsType";
  /** ComponentId */
  readonly componentId: Scalars["ID"];
  /** Component Messages */
  readonly messages: ReadonlyArray<ComponentMessageType>;
  /** ProductId */
  readonly productId: Scalars["String"];
  /** Properties */
  readonly properties: Scalars["String"];
};

export type CondensationAnalysisForOpcType = {
  readonly __typename?: "CondensationAnalysisForOpcType";
  /** Dew point temperature */
  readonly dewPointTemperature: Scalars["Amount"];
  /** Opc Id */
  readonly opcId: Scalars["ID"];
  /** Surface temp assessment for all airpoints */
  readonly surfaceTempsForAirPoint?: Maybe<
    ReadonlyArray<SurfaceTempAssessmentType>
  >;
};

export type CondensorCoefficientsInputType = {
  /** A max */
  readonly aMax: Scalars["Float"];
  /** A min */
  readonly aMin: Scalars["Float"];
  /** A step */
  readonly aStep: Scalars["Float"];
  /** B max */
  readonly bMax: Scalars["Float"];
  /** B min */
  readonly bMin: Scalars["Float"];
  /** B step */
  readonly bStep: Scalars["Float"];
  /** C max */
  readonly cMax: Scalars["Float"];
  /** C min */
  readonly cMin: Scalars["Float"];
  /** C step */
  readonly cStep: Scalars["Float"];
  /** D max */
  readonly dMax: Scalars["Float"];
  /** D min */
  readonly dMin: Scalars["Float"];
  /** D step */
  readonly dStep: Scalars["Float"];
  /** Geometry */
  readonly geometry: GeometryInputType;
  /** Heating case freon */
  readonly heatingCaseFreon: CoilCoeffCaseFreonInputType;
  /** Heating velocity input */
  readonly heatingVelocityInput: ReadonlyArray<VelocityInputType>;
};

export type CoolingCoefficientsResultsType = {
  readonly __typename?: "CoolingCoefficientsResultsType";
  /** Coeffs */
  readonly coeffs: CoeffType;
  /** Cooling results */
  readonly coolingResults?: Maybe<ReadonlyArray<CoeffResultType>>;
  /** Error text */
  readonly errorText: Scalars["String"];
  /** Has error */
  readonly hasError: Scalars["Boolean"];
  /** Heating results */
  readonly heatingResults?: Maybe<ReadonlyArray<CoeffResultType>>;
};

export type CostBreakDownType = {
  readonly __typename?: "CostBreakDownType";
  /** Description */
  readonly description: Scalars["String"];
  /** Name */
  readonly name: Scalars["String"];
  /** PartId */
  readonly partId: Scalars["String"];
  /** Cost */
  readonly value: Scalars["String"];
};

export type CostFactorType = {
  readonly __typename?: "CostFactorType";
  /** Description */
  readonly description: Scalars["String"];
  /** Factor */
  readonly factor: Scalars["String"];
  /** Cost */
  readonly value: Scalars["String"];
};

export type CountryType = {
  readonly __typename?: "CountryType";
  /** Id of the country */
  readonly id: Scalars["ID"];
  /** Name of the country */
  readonly name: Scalars["String"];
  /** Regions of the country */
  readonly regions: ReadonlyArray<RegionType>;
};

export type CreateMoistureLoadInputType = {
  /** Moisture load labels */
  readonly labels?: InputMaybe<ReadonlyArray<LabelInputType>>;
  /** Moisture load name */
  readonly name: Scalars["String"];
};

export type CreateMoistureLoadMutationResultType = {
  readonly __typename?: "CreateMoistureLoadMutationResultType";
  /** id */
  readonly id: Scalars["ID"];
  /** Moisture load id */
  readonly moistureLoadId: Scalars["ID"];
  /** Moisture Load No */
  readonly moistureLoadNo: Scalars["Int"];
  /** Revision No */
  readonly revisionNo: Scalars["Int"];
};

export type CreatePricingMutationResultType = {
  readonly __typename?: "CreatePricingMutationResultType";
  /** Cache patches */
  readonly cachePatches: ReadonlyArray<CachePatchType>;
  /** id */
  readonly id: Scalars["ID"];
  /** Pricing id */
  readonly pricingId: Scalars["ID"];
  /** Pricing No */
  readonly pricingNo: Scalars["Int"];
  /** Revision No */
  readonly revisionNo: Scalars["Int"];
};

export type CreateSystemInputType = {
  /** Add mcompare product */
  readonly addMCompare: Scalars["Boolean"];
  /** Climate data settings as property value set */
  readonly climateData: Scalars["String"];
  /** Currency code */
  readonly currencyCode: Scalars["String"];
  /** Generate bin cases */
  readonly generateBinCases: Scalars["Boolean"];
  /** System id */
  readonly id: Scalars["ID"];
  /** System labels */
  readonly labels?: InputMaybe<ReadonlyArray<LabelInputType>>;
  /** Moisture Load Id */
  readonly moistureLoadId?: InputMaybe<Scalars["ID"]>;
  /** System name */
  readonly name: Scalars["String"];
  /** New properties */
  readonly newProperties: Scalars["String"];
  /** Operating cases as property value set */
  readonly operatingCases: ReadonlyArray<Scalars["String"]>;
  /** Sales organisation id */
  readonly salesOrganisationId: Scalars["String"];
  /** System type id */
  readonly systemTypeId: Scalars["String"];
  /** Target status */
  readonly targetStatus: Scalars["Int"];
  /** Template components */
  readonly templateComponents: ReadonlyArray<
    InputMaybe<TemplateComponentInputType>
  >;
};

export type CreateSystemType = {
  readonly __typename?: "CreateSystemType";
  /** Counter */
  readonly counter: Scalars["Float"];
  /** System type */
  readonly systemType: Scalars["String"];
};

export type CreateSystemsResultType = {
  readonly __typename?: "CreateSystemsResultType";
  /** Genesys No */
  readonly genesysNo: Scalars["Int"];
  /** System ID */
  readonly id: Scalars["ID"];
  /** Revision No */
  readonly revisionNo: Scalars["Int"];
};

export type CurrencyExchangeRateTemplateType = {
  readonly __typename?: "CurrencyExchangeRateTemplateType";
  /** Comment */
  readonly comment: Scalars["String"];
  /** Template id */
  readonly id: Scalars["ID"];
  /** Name */
  readonly name: Scalars["String"];
};

export type CurrencyRateType = {
  readonly __typename?: "CurrencyRateType";
  /** Currency information */
  readonly currency: CurrencyType;
  /** Exchange rate template */
  readonly currencyExchangeRateTemplate: CurrencyExchangeRateTemplateType;
  /** Exchange rate to SEK */
  readonly exchangeRate: Scalars["Float"];
  /** Currency rate id */
  readonly id: Scalars["ID"];
  /** Starting date of when the rate is valid */
  readonly validFrom?: Maybe<Scalars["GenesysDate"]>;
};

export type CurrencyType = {
  readonly __typename?: "CurrencyType";
  /** Currency id */
  readonly id: Scalars["ID"];
  /** Currency */
  readonly name: Scalars["String"];
  /** Sign after */
  readonly signAfter?: Maybe<Scalars["String"]>;
  /** Sign before */
  readonly signBefore?: Maybe<Scalars["String"]>;
};

export type DataPointType = {
  readonly __typename?: "DataPointType";
  /** Annual occurence of the DataPoint */
  readonly annualOccurence?: Maybe<Scalars["String"]>;
  /** Case type of the DataPoint */
  readonly caseType: Scalars["String"];
  /** Climate data type of the DataPoint */
  readonly climateDataType: Scalars["String"];
  /** Humidity of the DataPoint */
  readonly humidity: Scalars["Amount"];
  /** Id of the DataPoint */
  readonly id: Scalars["ID"];
  /** Temperature of the DataPoint */
  readonly temperature: Scalars["Amount"];
  /** WindSpeed of the DataPoint */
  readonly windSpeed: Scalars["Amount"];
};

export type DateFilterGroupType = {
  readonly enabled?: InputMaybe<Scalars["Boolean"]>;
  readonly groupBy?: InputMaybe<Scalars["Boolean"]>;
  readonly selectedEndDateStringified?: InputMaybe<Scalars["String"]>;
  readonly selectedStartDateStringified?: InputMaybe<Scalars["String"]>;
};

export type DeleteAmountFieldFormatInputType = {
  readonly amountFieldProfileId: Scalars["String"];
  readonly fieldGroup: Scalars["String"];
  readonly fieldName: Scalars["String"];
};

export type DeleteComponentInputType = {
  /** Component id */
  readonly componentId: Scalars["Guid"];
  /** System id */
  readonly systemId: Scalars["Guid"];
};

export type DeleteComponentMutationResultType = {
  readonly __typename?: "DeleteComponentMutationResultType";
  /** Cache patches */
  readonly cachePatches: ReadonlyArray<CachePatchType>;
  /** id */
  readonly id: Scalars["ID"];
};

export type DeleteEntityCachePatchType = {
  readonly __typename?: "DeleteEntityCachePatchType";
  /** id */
  readonly deletedId: Scalars["String"];
  /** Typename */
  readonly typename: Scalars["String"];
};

export type DeleteMCompareComponentInputType = {
  /** System id */
  readonly systemId: Scalars["ID"];
};

export type DeleteMCompareComponentMutationResultType = {
  readonly __typename?: "DeleteMCompareComponentMutationResultType";
  /** Cache patches */
  readonly cachePatches: ReadonlyArray<CachePatchType>;
  /** Id */
  readonly id: Scalars["ID"];
};

export type DeleteSystemFileType = {
  readonly __typename?: "DeleteSystemFileType";
  /** If the deletion was succesfull */
  readonly deletionSuccessful: Scalars["Boolean"];
  /** Error messages */
  readonly errorMessage: Scalars["String"];
};

export type DeletedFilterGroupType = {
  readonly enabled?: InputMaybe<Scalars["Boolean"]>;
  readonly groupBy?: InputMaybe<Scalars["Boolean"]>;
  readonly selectedDeletedOptions: ReadonlyArray<Scalars["Int"]>;
};

export type DeletetionMutationResultType = {
  readonly __typename?: "DeletetionMutationResultType";
  /** If the deletion was succesfull */
  readonly deletionSuccessful: Scalars["Boolean"];
  /** Error messages */
  readonly errorMessage: Scalars["String"];
};

export type DiagramWarningType = {
  readonly __typename?: "DiagramWarningType";
  /** Results this warning belongs to as ResultNameType. */
  readonly plainTextResultNames: ReadonlyArray<Scalars["String"]>;
  /** Which type of warning?. */
  readonly warning: RoomWarningEnum;
};

export type DxCoefficientsInputType = {
  /** A max */
  readonly aMax: Scalars["Float"];
  /** A min */
  readonly aMin: Scalars["Float"];
  /** A step */
  readonly aStep: Scalars["Float"];
  /** B max */
  readonly bMax: Scalars["Float"];
  /** B min */
  readonly bMin: Scalars["Float"];
  /** B step */
  readonly bStep: Scalars["Float"];
  /** C max */
  readonly cMax: Scalars["Float"];
  /** C min */
  readonly cMin: Scalars["Float"];
  /** C step */
  readonly cStep: Scalars["Float"];
  /** Cooling case freon */
  readonly coolingCaseFreon: CoilCoeffCaseFreonInputType;
  /** Cooling velocity input */
  readonly coolingVelocityInput: ReadonlyArray<VelocityInputType>;
  /** D max */
  readonly dMax: Scalars["Float"];
  /** D min */
  readonly dMin: Scalars["Float"];
  /** D step */
  readonly dStep: Scalars["Float"];
  /** Geometry */
  readonly geometry: GeometryInputType;
  /** Heating case freon */
  readonly heatingCaseFreon: CoilCoeffCaseFreonInputType;
  /** Heating velocity input */
  readonly heatingVelocityInput: ReadonlyArray<VelocityInputType>;
};

export type EnergyChartPresetType = {
  readonly __typename?: "EnergyChartPresetType";
  /** Energy chart preset id */
  readonly id: Scalars["ID"];
  /** Energy chart preset Name */
  readonly name: Scalars["String"];
  /** Energy chart preset Y1AxisType */
  readonly y1AxisType: Scalars["String"];
  /** Energy chart preset Y1Label */
  readonly y1Label: Scalars["String"];
  /** Energy chart preset Y1PerfParams */
  readonly y1PerfParams: ReadonlyArray<Scalars["String"]>;
  /** Energy chart preset Y1UnitIp */
  readonly y1UnitIp: Scalars["String"];
  /** Energy chart preset Y1UnitSi */
  readonly y1UnitSi: Scalars["String"];
  /** Energy chart preset Y2AxisType */
  readonly y2AxisType: Scalars["String"];
  /** Energy chart preset Y2Label */
  readonly y2Label: Scalars["String"];
  /** Energy chart preset Y2PerfParams */
  readonly y2PerfParams: ReadonlyArray<Scalars["String"]>;
  /** Energy chart preset Y2UnitIp */
  readonly y2UnitIp: Scalars["String"];
  /** Energy chart preset Y2UnitSi */
  readonly y2UnitSi: Scalars["String"];
};

export type EnergyCompareSummaryType = {
  readonly __typename?: "EnergyCompareSummaryType";
  /** Energy annual consumption */
  readonly energyAnnualConsumption: ReadonlyArray<EnergyParamType>;
  /** Energy annual cost */
  readonly energyAnnualCost: ReadonlyArray<EnergyCostGraphType>;
  /** Energy annual extra */
  readonly energyAnnualExtra: ReadonlyArray<EnergyParamType>;
  /** Energy emission */
  readonly energyEmission: ReadonlyArray<EnergyParamType>;
  /** LCC cost */
  readonly lccCost: ReadonlyArray<EnergyCostGraphType>;
};

export type EnergyCostGraphType = {
  readonly __typename?: "EnergyCostGraphType";
  /** Cost */
  readonly cost: Scalars["Float"];
  /** Currency symbol */
  readonly currencySymbol: Scalars["String"];
  /** Name */
  readonly name: Scalars["String"];
};

/** Energy Cost Type */
export enum EnergyCostType {
  COOLING_WATER_COST = "COOLING_WATER_COST",
  ELECTRICITY_COST = "ELECTRICITY_COST",
  GAS_COST = "GAS_COST",
  HEATING_WATER_COST = "HEATING_WATER_COST",
  HUMIDIFIER_WATER_COST = "HUMIDIFIER_WATER_COST",
  NO_COST = "NO_COST",
  SEWAGE_COST = "SEWAGE_COST",
  STEAM_COST = "STEAM_COST",
  WATER_COST = "WATER_COST"
}

export type EnergyListItemType = {
  readonly __typename?: "EnergyListItemType";
  /** Energy item id */
  readonly id: Scalars["ID"];
  /** Energy item name */
  readonly name: Scalars["String"];
  /** Energy item SortNo */
  readonly sortNo: Scalars["Int"];
};

export type EnergyParamType = {
  readonly __typename?: "EnergyParamType";
  /** Amount field value */
  readonly amountFieldValue: AmountFieldValueType;
  /** Param name */
  readonly paramName: Scalars["String"];
};

export type EnergySettingsInputType = {
  /** Setting name */
  readonly settingName: Scalars["String"];
  /** Value */
  readonly value: Scalars["String"];
};

export type EnergySettingsType = {
  readonly __typename?: "EnergySettingsType";
  /** Id */
  readonly id: Scalars["ID"];
  /** Setting name */
  readonly settingName: Scalars["String"];
  /** Setting value */
  readonly settingValue: Scalars["String"];
};

export type EnergySummaryType = {
  readonly __typename?: "EnergySummaryType";
  /** Energy annual consumption */
  readonly energyAnnualConsumption: ReadonlyArray<EnergyParamType>;
  /** Energy annual cost */
  readonly energyAnnualCost: ReadonlyArray<EnergyCostGraphType>;
  /** Energy annual extra */
  readonly energyAnnualExtra: ReadonlyArray<EnergyParamType>;
  /** Energy emission */
  readonly energyEmission: ReadonlyArray<EnergyParamType>;
  /** LCC cost */
  readonly lccCost: ReadonlyArray<EnergyCostGraphType>;
};

export type EnergyTotalItemType = {
  readonly __typename?: "EnergyTotalItemType";
  /** Energy item CostType */
  readonly costType: EnergyCostType;
  /** Energy item id */
  readonly id: Scalars["ID"];
  /** Energy item name */
  readonly name: Scalars["String"];
  /** Energy item QuantityId */
  readonly quantityId: Scalars["String"];
  /** Energy item SortNo */
  readonly sortNo: Scalars["Int"];
  /** Visibility filter */
  readonly visibilityFilter: Scalars["String"];
};

export type EntityFieldValueType = {
  readonly __typename?: "EntityFieldValueType";
  readonly booleanValue?: Maybe<Scalars["Boolean"]>;
  readonly entityFieldValueArray?: Maybe<
    ReadonlyArray<Maybe<EntityFieldValueType>>
  >;
  readonly floatValue?: Maybe<Scalars["Float"]>;
  readonly intValue?: Maybe<Scalars["Int"]>;
  readonly stringValue?: Maybe<Scalars["String"]>;
};

/** Issue Types */
export enum ErrorAndWarningType {
  PROPERTY_DIFFERENT_STANDARD_TRANSLATIONS = "PROPERTY_DIFFERENT_STANDARD_TRANSLATIONS",
  PROPERTY_MISSING_DEFAULT_LANGUAGE_STANDARD_TRANSLATIONS = "PROPERTY_MISSING_DEFAULT_LANGUAGE_STANDARD_TRANSLATIONS",
  PROPERTY_MULTIPLE_STANDARD_TRANSLATION = "PROPERTY_MULTIPLE_STANDARD_TRANSLATION",
  PROPERTY_NON_STANDARD_TRANSLATION_WARNING = "PROPERTY_NON_STANDARD_TRANSLATION_WARNING",
  PROPERTY_TYPE_WARNING = "PROPERTY_TYPE_WARNING",
  PROPERTY_UNNECESSARY_TRANSLATION = "PROPERTY_UNNECESSARY_TRANSLATION",
  PROPERTY_VALUE_DIFFERENT_STANDARD_TRANSLATIONS = "PROPERTY_VALUE_DIFFERENT_STANDARD_TRANSLATIONS",
  PROPERTY_VALUE_MISSING_DEFAULT_LANGUAGE_STANDARD_TRANSLATIONS = "PROPERTY_VALUE_MISSING_DEFAULT_LANGUAGE_STANDARD_TRANSLATIONS",
  PROPERTY_VALUE_MULTIPLE_STANDARD_TRANSLATION = "PROPERTY_VALUE_MULTIPLE_STANDARD_TRANSLATION",
  PROPERTY_VALUE_UNNECESSARY_TRANSLATION = "PROPERTY_VALUE_UNNECESSARY_TRANSLATION",
  PROPERY_VALUE_NON_STANDARD_TRANSLATION_WARNING = "PROPERY_VALUE_NON_STANDARD_TRANSLATION_WARNING"
}

export type ExchangeComponentInputType = {
  /** Component id to add */
  readonly componentIdToAdd: Scalars["Guid"];
  /** Component id to delete */
  readonly componentIdToDelete: Scalars["Guid"];
  /** Compnent section positions to add */
  readonly componentSectionPositionsToAdd: ReadonlyArray<ComponentSectionPositionInputType>;
  /** Product id to add */
  readonly productIdToAdd: Scalars["String"];
  /** System id */
  readonly systemId: Scalars["Guid"];
};

export type ExchangeComponentMutationResultType = {
  readonly __typename?: "ExchangeComponentMutationResultType";
  /** Cache patches */
  readonly cachePatches: ReadonlyArray<CachePatchType>;
  /** id */
  readonly id: Scalars["ID"];
};

export type FanCurveInputType = {
  /** Fluid temperature in */
  readonly airFlow: Scalars["String"];
  /** Calculate using flow as input */
  readonly gridPower: Scalars["String"];
  /** Fluid temperature out */
  readonly pressure: Scalars["String"];
  /** Fluid flow */
  readonly shaftPower: Scalars["String"];
};

export type FanModelInputType = {
  /** Fan Model */
  readonly fanModel: Scalars["String"];
  /** Fan Speed */
  readonly fanSpeed: Scalars["String"];
  /** Max Flow */
  readonly maxFlow: Scalars["String"];
  /** Min Flow */
  readonly minFlow: Scalars["String"];
  /** Outlet Area */
  readonly outletArea: Scalars["String"];
  /** Standard Density */
  readonly standardDensity: Scalars["String"];
};

export type FanPerformanceInputType = {
  /** Flow in */
  readonly actualDensity: Scalars["String"];
  /** Safety on capacity */
  readonly cosPhi: Scalars["String"];
  /** Fan curve rows */
  readonly fanCurveRows: ReadonlyArray<FanCurveInputType>;
  /** Fan model */
  readonly fanModel: FanModelInputType;
  /** Safety on capacity */
  readonly fanQuantity: Scalars["String"];
  /** Flow in */
  readonly flowIn: Scalars["String"];
  /** Flow in */
  readonly gridPowerExponent: Scalars["String"];
  /** Humidity in */
  readonly humidityIn: Scalars["String"];
  /** Safety on capacity */
  readonly phases: Scalars["String"];
  /** Pressure in */
  readonly pressureIn: Scalars["String"];
  /** Safety on capacity */
  readonly targetPressure: Scalars["String"];
  /** Temperature in */
  readonly temperatureIn: Scalars["String"];
  /** Safety on capacity */
  readonly voltage: Scalars["String"];
};

export type FanResultsType = {
  readonly __typename?: "FanResultsType";
  /** Air velocity */
  readonly airFlowPerFan: Scalars["String"];
  /** Air velocity */
  readonly airVelocity: Scalars["String"];
  /** Flow */
  readonly cosPhi: Scalars["String"];
  /** Flow */
  readonly current: Scalars["String"];
  /** Pressure out */
  readonly dynamicPressure: Scalars["String"];
  /** Error code */
  readonly errorCode: Scalars["Int"];
  /** Error text */
  readonly errorText?: Maybe<Scalars["String"]>;
  /** Air velocity */
  readonly fanMaxSpeed: Scalars["String"];
  /** Air velocity */
  readonly fanSpeed: Scalars["String"];
  /** Has error */
  readonly hasError: Scalars["Boolean"];
  /** Humidity out */
  readonly humidityOut: Scalars["String"];
  /** Flow */
  readonly massFlow: Scalars["String"];
  /** Air velocity */
  readonly shaftPower: Scalars["String"];
  /** Air velocity */
  readonly soundPowerLevelsInlet: Scalars["String"];
  /** Air velocity */
  readonly soundPowerLevelsOutlet: Scalars["String"];
  /** Air velocity */
  readonly specificFanPower: Scalars["String"];
  /** Air velocity */
  readonly staticEfficiency: Scalars["String"];
  /** Pressure out */
  readonly staticPressure: Scalars["String"];
  /** Temperature out */
  readonly temperatureOut: Scalars["String"];
  /** Air velocity */
  readonly totalEfficiency: Scalars["String"];
  /** Air velocity */
  readonly totalPowerConsumption: Scalars["String"];
  /** Air velocity */
  readonly totalPressure: Scalars["String"];
  /** Air velocity */
  readonly version: Scalars["String"];
};

export type FieldDefaultFormatType = {
  readonly __typename?: "FieldDefaultFormatType";
  /** Decimal count */
  readonly decimalCount: Scalars["Int"];
  /** Field group */
  readonly fieldGroup: Scalars["String"];
  /** Field name */
  readonly fieldName: Scalars["String"];
  /** Field default id */
  readonly id: Scalars["ID"];
  /** Measure system. 1=SI, 2=IP */
  readonly measureSystem: Scalars["Int"];
  /** Unit */
  readonly unit: Scalars["String"];
};

export type FinInputType = {
  /** Fouling */
  readonly fouling: Scalars["Float"];
  /** Material thermal conductivity */
  readonly materialThermalConductivity: Scalars["String"];
  /** Thickness */
  readonly thickness: Scalars["String"];
};

/** Source of flow. */
export enum FlowSourceEnum {
  MAKE_UP_FLOW = "MAKE_UP_FLOW",
  MAX_COOLING_FLOW = "MAX_COOLING_FLOW",
  MAX_DEHUMIDIFICATION_FLOW = "MAX_DEHUMIDIFICATION_FLOW",
  MAX_EXCHANGES = "MAX_EXCHANGES",
  MAX_HEATING_FLOW = "MAX_HEATING_FLOW",
  MAX_HUMIDIFICATION_FLOW = "MAX_HUMIDIFICATION_FLOW",
  MIN_COOLING_FLOW = "MIN_COOLING_FLOW",
  MIN_DEHUMIDIFICATION_FLOW = "MIN_DEHUMIDIFICATION_FLOW",
  MIN_EXCHANGES = "MIN_EXCHANGES",
  MIN_HEATING_FLOW = "MIN_HEATING_FLOW",
  MIN_HUMIDIFICATION_FLOW = "MIN_HUMIDIFICATION_FLOW",
  OK_GENERATED = "OK_GENERATED"
}

export type FluidCompositionInputType = {
  /** Glycole percentage */
  readonly glycolePercentage: Scalars["String"];
  /** Glycole type */
  readonly glycoleType: Scalars["Int"];
  /** Use volume percentage */
  readonly useVolumePercentage: Scalars["Boolean"];
};

export type FluidCoolingCoefficientsInputType = {
  /** A max */
  readonly aMax: Scalars["Float"];
  /** A min */
  readonly aMin: Scalars["Float"];
  /** A step */
  readonly aStep: Scalars["Float"];
  /** B max */
  readonly bMax: Scalars["Float"];
  /** B min */
  readonly bMin: Scalars["Float"];
  /** B step */
  readonly bStep: Scalars["Float"];
  /** C max */
  readonly cMax: Scalars["Float"];
  /** C min */
  readonly cMin: Scalars["Float"];
  /** C step */
  readonly cStep: Scalars["Float"];
  /** Cooling case fluid */
  readonly coolingCaseFluid: CoilCoeffCaseFluidInputType;
  /** Cooling velocity input */
  readonly coolingVelocityInput: ReadonlyArray<VelocityInputType>;
  /** D max */
  readonly dMax: Scalars["Float"];
  /** D min */
  readonly dMin: Scalars["Float"];
  /** D step */
  readonly dStep: Scalars["Float"];
  /** Geometry */
  readonly geometry: GeometryInputType;
  /** Heating case fluid */
  readonly heatingCaseFluid: CoilCoeffCaseFluidInputType;
  /** Heating velocity input */
  readonly heatingVelocityInput: ReadonlyArray<VelocityInputType>;
};

export type FluidHeatingCoefficientsInputType = {
  /** A max */
  readonly aMax: Scalars["Float"];
  /** A min */
  readonly aMin: Scalars["Float"];
  /** A step */
  readonly aStep: Scalars["Float"];
  /** B max */
  readonly bMax: Scalars["Float"];
  /** B min */
  readonly bMin: Scalars["Float"];
  /** B step */
  readonly bStep: Scalars["Float"];
  /** C max */
  readonly cMax: Scalars["Float"];
  /** C min */
  readonly cMin: Scalars["Float"];
  /** C step */
  readonly cStep: Scalars["Float"];
  /** D max */
  readonly dMax: Scalars["Float"];
  /** D min */
  readonly dMin: Scalars["Float"];
  /** D step */
  readonly dStep: Scalars["Float"];
  /** Geometry */
  readonly geometry: GeometryInputType;
  /** Heating case fluid */
  readonly heatingCaseFluid: CoilCoeffCaseFluidInputType;
  /** Heating velocity input */
  readonly heatingVelocityInput: ReadonlyArray<VelocityInputType>;
};

export type FluidInputInputType = {
  /** Calculate using flow as input */
  readonly calculateUsingFlowAsInput: Scalars["Boolean"];
  /** Fluid flow */
  readonly fluidFlow: Scalars["String"];
  /** Fluid temperature in */
  readonly fluidTemperatureIn: Scalars["String"];
  /** Fluid temperature out */
  readonly fluidTemperatureOut: Scalars["String"];
};

export type FluidMaxPerformanceInputType = {
  /** Flow in */
  readonly flowIn: Scalars["String"];
  /** Fluid composition */
  readonly fluidComposition: FluidCompositionInputType;
  /** Fluid input */
  readonly fluidInput: FluidInputInputType;
  /** Geometry */
  readonly geometry: GeometryInputType;
  /** Humidity in */
  readonly humidityIn: Scalars["String"];
  /** Pressure in */
  readonly pressureIn: Scalars["String"];
  /** Safety on capacity */
  readonly safetyOnCapacity: Scalars["String"];
  /** Safety on surface */
  readonly safetyOnSurface: Scalars["String"];
  /** Temperature in */
  readonly temperatureIn: Scalars["String"];
};

export type FluidResultsType = {
  readonly __typename?: "FluidResultsType";
  /** Air pressure drop */
  readonly airPressureDrop: Scalars["String"];
  /** Air pressure drop dry */
  readonly airPressureDropDry: Scalars["String"];
  /** Air total capacity */
  readonly airTotalCapacity: Scalars["String"];
  /** Air velocity */
  readonly airVelocity: Scalars["String"];
  /** Condensed water */
  readonly condensedWater: Scalars["String"];
  /** Error code */
  readonly errorCode: Scalars["Int"];
  /** Error text */
  readonly errorText?: Maybe<Scalars["String"]>;
  /** Flow */
  readonly flow: Scalars["String"];
  /** Fluid density */
  readonly fluidDensity: Scalars["String"];
  /** Fluid pressure drop */
  readonly fluidPressureDrop: Scalars["String"];
  /** Fluid temperature in */
  readonly fluidTemperatureIn: Scalars["String"];
  /** Fluid temperature out */
  readonly fluidTemperatureOut: Scalars["String"];
  /** Fluid velocity */
  readonly fluidVelocity: Scalars["String"];
  /** Fluid volume flow */
  readonly fluidVolumeFlow: Scalars["String"];
  /** Has error */
  readonly hasError: Scalars["Boolean"];
  /** Humidity out */
  readonly humidityOut: Scalars["String"];
  /** Pressure out */
  readonly pressureOut: Scalars["String"];
  /** Reynolds number fluid */
  readonly reynoldsNumberFluid: Scalars["String"];
  /** Sensible total capacity ratio */
  readonly sensibleTotalCapacityRatio: Scalars["String"];
  /** Supplier code */
  readonly supplierCode: Scalars["String"];
  /** Temperature out */
  readonly temperatureOut: Scalars["String"];
  /** Wet ratio */
  readonly wetRatio: Scalars["String"];
};

export type FluidTargetTemperatureInputType = {
  /** Flow in */
  readonly flowIn: Scalars["String"];
  /** Fluid composition */
  readonly fluidComposition: FluidCompositionInputType;
  /** Fluid Temperature in */
  readonly fluidTemperatureIn: Scalars["String"];
  /** Geometry */
  readonly geometry: GeometryInputType;
  /** Humidity in */
  readonly humidityIn: Scalars["String"];
  /** Pressure in */
  readonly pressureIn: Scalars["String"];
  /** Safety on capacity */
  readonly safetyOnCapacity: Scalars["String"];
  /** SafetyOnSurface */
  readonly safetyOnSurface: Scalars["String"];
  /** Temperature in */
  readonly temperatureIn: Scalars["String"];
  /** Temperature target */
  readonly temperatureTarget: Scalars["String"];
};

export type FreonMaxPerformanceInputType = {
  /** Flow in */
  readonly flowIn: Scalars["String"];
  /** Freon state */
  readonly freonState: FreonStateInputType;
  /** Geometry */
  readonly geometry: GeometryInputType;
  /** Humidity in */
  readonly humidityIn: Scalars["String"];
  /** Pressure in */
  readonly pressureIn: Scalars["String"];
  /** Safety on capacity */
  readonly safetyOnCapacity: Scalars["String"];
  /** Safety on surface */
  readonly safetyOnSurface: Scalars["String"];
  /** Temperature in */
  readonly temperatureIn: Scalars["String"];
};

export type FreonResultsType = {
  readonly __typename?: "FreonResultsType";
  /** Air pressure drop */
  readonly airPressureDrop: Scalars["String"];
  /** Air pressure drop dry */
  readonly airPressureDropDry: Scalars["String"];
  /** Air total capacity */
  readonly airTotalCapacity: Scalars["String"];
  /** Air velocity */
  readonly airVelocity: Scalars["String"];
  /** Condensed water */
  readonly condensedWater: Scalars["String"];
  /** Error code */
  readonly errorCode: Scalars["Int"];
  /** Error text */
  readonly errorText: Scalars["String"];
  /** Flow */
  readonly flow: Scalars["String"];
  /** Freon gas velocity */
  readonly freonGasVelocity: Scalars["String"];
  /** Freon liquid velocity */
  readonly freonLiquidVelocity: Scalars["String"];
  /** Freon mass flow */
  readonly freonMassFlow: Scalars["String"];
  /** Freon pressure drop */
  readonly freonPressureDrop: Scalars["String"];
  /** Has error */
  readonly hasError: Scalars["Boolean"];
  /** Humidity out */
  readonly humidityOut: Scalars["String"];
  /** Marginal */
  readonly marginal: Scalars["String"];
  /** Pressure out */
  readonly pressureOut: Scalars["String"];
  /** Sensible total capacity ratio */
  readonly sensibleTotalCapacityRatio: Scalars["String"];
  /** Supplier code */
  readonly supplierCode: Scalars["String"];
  /** Temperature out */
  readonly temperatureOut: Scalars["String"];
};

export type FreonStateInputType = {
  /** Condensing temperature */
  readonly condensingTemperature: Scalars["String"];
  /** Evaporation temperature */
  readonly evaporationTemperature: Scalars["String"];
  /** Freon */
  readonly freon: Scalars["Int"];
  /** Sub cooling */
  readonly subCooling: Scalars["String"];
  /** Super heat */
  readonly superHeat: Scalars["String"];
};

export type FreonTargetTemperatureInputType = {
  /** Flow in */
  readonly flowIn: Scalars["String"];
  /** Freon state */
  readonly freonState: FreonStateInputType;
  /** Geometry */
  readonly geometry: GeometryInputType;
  /** Humidity in */
  readonly humidityIn: Scalars["String"];
  /** Pressure in */
  readonly pressureIn: Scalars["String"];
  /** Safety on capacity */
  readonly safetyOnCapacity: Scalars["String"];
  /** SafetyOnSurface */
  readonly safetyOnSurface: Scalars["String"];
  /** Temperature in */
  readonly temperatureIn: Scalars["String"];
  /** Temperature target */
  readonly temperatureTarget: Scalars["String"];
};

export type FreonTargetTemperatureResultsType = {
  readonly __typename?: "FreonTargetTemperatureResultsType";
  /** Margin on total power */
  readonly marginOnTotalPower: Scalars["String"];
  /** Results */
  readonly results: FreonResultsType;
  /** Target reached */
  readonly targetReached: Scalars["Boolean"];
};

/** Product type with less specific variant data types */
export type GenericProductType = {
  readonly __typename?: "GenericProductType";
  /** Box connection points */
  readonly boxConnectionPoints: ReadonlyArray<BoxConnectionPointType>;
  /** Box line points */
  readonly boxLinePoints: ReadonlyArray<BoxLinePointType>;
  /** Box symbols */
  readonly boxSymbols: ReadonlyArray<BoxSymbolType>;
  /** Box texts */
  readonly boxTexts: ReadonlyArray<BoxTextType>;
  /** Product description */
  readonly description: Scalars["String"];
  readonly id: Scalars["ID"];
  /** Box line points */
  readonly imageDataMappings: ReadonlyArray<ImageDataMappingType>;
  /** Product Images */
  readonly images: ReadonlyArray<ImageDataType>;
  /** Manual mode */
  readonly manualMode?: Maybe<Scalars["PropertyValueSet"]>;
  /** Menu group */
  readonly maxAlternatives: Scalars["Int"];
  /** Menu group */
  readonly menuGroup?: Maybe<Scalars["String"]>;
  /** Menu group */
  readonly optional: Scalars["Boolean"];
  /** Product data table */
  readonly productDataTable?: Maybe<ProductDataTableType>;
  /** Product sections */
  readonly productSections: ReadonlyArray<ProductSectionType>;
  /** Product type */
  readonly productType: ProductType;
  /** Product properties */
  readonly properties: ReadonlyArray<ProductPropertyType>;
  /** Range filter */
  readonly rangeFilter?: Maybe<Scalars["PropertyFilter"]>;
  /** Show alternatives filter */
  readonly showAlternativesFilter?: Maybe<Scalars["PropertyFilter"]>;
  /** Property sort number */
  readonly sortNo?: Maybe<Scalars["Int"]>;
  /** System Type */
  readonly systemType: SystemTypeGraphType;
  /** Wiki Js Path */
  readonly wikiJsPath?: Maybe<Scalars["String"]>;
};

/** Product type with less specific variant data types */
export type GenericProductTypeProductDataTableArgs = {
  tableName: Scalars["String"];
};

export type GenericSettingsType = {
  readonly __typename?: "GenericSettingsType";
  /** Setting name */
  readonly settingName: Scalars["String"];
  /** Setting value */
  readonly settingValue: Scalars["String"];
};

export type GenesysNumberPrefixType = {
  readonly __typename?: "GenesysNumberPrefixType";
  /** Genesys number prefix */
  readonly genesysNo: Scalars["String"];
  readonly id: Scalars["ID"];
  /** MoistureLoadNo number prefix */
  readonly moistureLoadNo: Scalars["String"];
  /** Pricing number prefix */
  readonly pricingNo: Scalars["String"];
};

export type GenesysRouteType = {
  readonly __typename?: "GenesysRouteType";
  /** Route id */
  readonly id: Scalars["ID"];
  /** Route */
  readonly route: Scalars["String"];
  /** Url */
  readonly url: Scalars["String"];
};

export type GeometryInputType = {
  /** Number of circuits */
  readonly circuits: Scalars["Int"];
  /** Coefficients */
  readonly coeffInput: CoeffInputType;
  /** Connection side */
  readonly connectionSide: Scalars["Int"];
  /** Distance between rows */
  readonly distanceBetweenRows: Scalars["String"];
  /** DistanceBetweenTubes */
  readonly distanceBetweenTubes: Scalars["String"];
  /** Exchanger flow */
  readonly exchangerFlow: Scalars["Int"];
  /** Fin */
  readonly fin: FinInputType;
  /** Fin spacing */
  readonly finSpacing: Scalars["String"];
  /** Inlet collector */
  readonly inletCollector: CollectorInputType;
  /** Inlet header */
  readonly inletHeader?: InputMaybe<HeaderInputType>;
  /** Outlet collector */
  readonly outletCollector: CollectorInputType;
  /** Outlet header */
  readonly outletHeader?: InputMaybe<HeaderInputType>;
  /** Number of tube rows */
  readonly rows: Scalars["Int"];
  /** Staggered */
  readonly staggered: Scalars["Boolean"];
  /** Tube */
  readonly tube: TubeInputType;
  /** Tube length */
  readonly tubeLength: Scalars["String"];
  /** Number of tubes */
  readonly tubes: Scalars["Int"];
};

export type GetCalculatedPropertiesForAccessoryInputType = {
  /** Accessory product id */
  readonly accessoryProductId: Scalars["String"];
  /** Accessory selected properties */
  readonly accessorySelectedProperties: Scalars["String"];
  /** component id */
  readonly parentComponentId: Scalars["String"];
  /** System id */
  readonly systemId: Scalars["String"];
};

export type GetCalculatedPropertiesInputType = {
  /** Component id */
  readonly componentId: Scalars["Guid"];
  /** Component Properties */
  readonly componentProperties: Scalars["String"];
  /** System id */
  readonly systemId: Scalars["Guid"];
};

export type GetProductInputType = {
  /** Product id */
  readonly productId: Scalars["String"];
};

export type GetSystemTypeInputType = {
  /** System type id */
  readonly systemTypeId: Scalars["String"];
};

export type HeaderInputType = {
  /** Diameter */
  readonly diameter: Scalars["String"];
  /** Material type */
  readonly materialType: Scalars["Int"];
  /** Number of headers */
  readonly numberOfHeaders: Scalars["Int"];
  /** Thickness */
  readonly thickness: Scalars["String"];
};

export type HeatingCoefficientsResultsType = {
  readonly __typename?: "HeatingCoefficientsResultsType";
  /** Coeffs */
  readonly coeffs: CoeffType;
  /** Error text */
  readonly errorText: Scalars["String"];
  /** Has error */
  readonly hasError: Scalars["Boolean"];
  /** Heating results */
  readonly heatingResults?: Maybe<ReadonlyArray<CoeffResultType>>;
};

export type ImageDataMappingType = {
  readonly __typename?: "ImageDataMappingType";
  /** Claim filter */
  readonly claimFilter?: Maybe<Scalars["String"]>;
  /** Property filter */
  readonly propertyFilter?: Maybe<Scalars["PropertyFilter"]>;
  /** Sort number */
  readonly sortNo: Scalars["Int"];
  /** Type */
  readonly type: Scalars["String"];
  /** Value source */
  readonly valueSource: Scalars["String"];
  /** Value source parameter */
  readonly valueSourceParameter: Scalars["String"];
  /** x position */
  readonly x: Scalars["Float"];
  /** y position */
  readonly y: Scalars["Float"];
};

export type ImageDataType = {
  readonly __typename?: "ImageDataType";
  /** Image id */
  readonly id: Scalars["ID"];
  /** Image format */
  readonly imageFormat: ImageFormat;
  /** Image usage */
  readonly imageUsage: ImageUsage;
  /** Image name */
  readonly name: Scalars["String"];
  /** Image product id */
  readonly productId: Scalars["String"];
  /** Image property filter */
  readonly propertyFilter?: Maybe<Scalars["PropertyFilter"]>;
  /** Image url */
  readonly url: Scalars["String"];
};

/** Image data format */
export enum ImageFormat {
  DWG = "DWG",
  DXF_2_D = "DXF_2_D",
  DXF_3_D = "DXF_3_D",
  JPG = "JPG",
  PNG = "PNG",
  SVG = "SVG",
  X_3_D = "X_3_D"
}

/** Case id */
export enum ImageUsage {
  ANY = "ANY",
  DESIGN_PERF_PARAMS = "DESIGN_PERF_PARAMS",
  DIAGRAM_SYMBOL = "DIAGRAM_SYMBOL",
  MECHANICAL_SKETCH = "MECHANICAL_SKETCH",
  NEW_SYSTEM_WIZARD = "NEW_SYSTEM_WIZARD",
  REPORT = "REPORT",
  SKETCH_SHAPE = "SKETCH_SHAPE",
  SPECIFICATIONS = "SPECIFICATIONS"
}

export type InsertElementCachePatchType = {
  readonly __typename?: "InsertElementCachePatchType";
  /** Field value */
  readonly entityFieldValue: EntityFieldValueType;
  /** Field name */
  readonly fieldName: Scalars["String"];
  /** id */
  readonly id?: Maybe<Scalars["String"]>;
  /** Insert index */
  readonly index: Scalars["Int"];
  /** Typename */
  readonly typename: Scalars["String"];
};

export type InvalidateFieldCachePatchType = {
  readonly __typename?: "InvalidateFieldCachePatchType";
  /** Field name */
  readonly fieldName: Scalars["String"];
  /** id */
  readonly id?: Maybe<Scalars["String"]>;
  /** Typename */
  readonly typename: Scalars["String"];
};

export type IssueType = {
  readonly __typename?: "IssueType";
  /** Parameters needed */
  readonly params: ParamType;
  /** Product key */
  readonly productKey: Scalars["String"];
  /** property Name */
  readonly propertyName: Scalars["String"];
  /** Type of issue */
  readonly type: ErrorAndWarningType;
};

export type LabelInputType = {
  /** Optional Label id */
  readonly id?: InputMaybe<Scalars["ID"]>;
  /** label name */
  readonly name: Scalars["String"];
};

export type LabelType = {
  readonly __typename?: "LabelType";
  /** Amount field profile id */
  readonly id: Scalars["ID"];
  /** Name of the system */
  readonly name: Scalars["String"];
  /** Created by user */
  readonly userProfileId: Scalars["Guid"];
};

export type LanguageType = {
  readonly __typename?: "LanguageType";
  /** Language id */
  readonly id: Scalars["ID"];
  /** Static language texts */
  readonly texts: Scalars["JSON"];
};

export type LocaleType = {
  readonly __typename?: "LocaleType";
  /** Locale id */
  readonly id: Scalars["ID"];
  /** Locale displayname */
  readonly name: Scalars["String"];
};

export type LocationType = {
  readonly __typename?: "LocationType";
  /** Bin location id of the location */
  readonly binLocationId: Scalars["String"];
  /** Elevation of the location */
  readonly elevation: Scalars["Amount"];
  /** Extreme max wet bulb */
  readonly extremeMaxWB: Scalars["Amount"];
  /** Id of the location (WMO) */
  readonly id: Scalars["ID"];
  /** Latitude of the location */
  readonly latitude: Scalars["Amount"];
  /** Longitude of the location */
  readonly longitude: Scalars["Amount"];
  /** Name of the location */
  readonly name: Scalars["String"];
};

export type MCompareSummaryType = {
  readonly __typename?: "MCompareSummaryType";
  /** Energy annual consumption */
  readonly energyAnnualConsumption: ReadonlyArray<EnergyParamType>;
  /** Energy annual cost */
  readonly energyAnnualCost: ReadonlyArray<EnergyCostGraphType>;
  /** Energy annual extra */
  readonly energyAnnualExtra: ReadonlyArray<EnergyParamType>;
  /** Energy emission */
  readonly energyEmission: ReadonlyArray<EnergyParamType>;
  /** LCC cost */
  readonly lccCost: ReadonlyArray<EnergyCostGraphType>;
};

/** Type of case. */
export enum MaxLoadTypeEnum {
  MAX_COOLING = "MAX_COOLING",
  MAX_DEHUMIDIFICATION = "MAX_DEHUMIDIFICATION",
  MAX_HEATING = "MAX_HEATING",
  MAX_HUMIDIFICATION = "MAX_HUMIDIFICATION"
}

export type MechanicalSketchInputType = {
  /** Provider */
  readonly provider: Scalars["String"];
  /** System id */
  readonly systemId: Scalars["ID"];
};

export type MechanicalSketchMetaDataType = {
  readonly __typename?: "MechanicalSketchMetaDataType";
  /** Casings */
  readonly casings: ReadonlyArray<SketchComponentType>;
  /** Components */
  readonly components: ReadonlyArray<SketchComponentType>;
};

export type MechanicalSketchProviderType = {
  readonly __typename?: "MechanicalSketchProviderType";
  /** Mechanical Sketch Provider id */
  readonly id: Scalars["ID"];
  /** Mechanical Sketch Provider MechanicalSketchProvider */
  readonly mechanicalSketchProvider: SketchProvider;
  /** Mechanical Sketch Provider PropertyFilter */
  readonly propertyFilter?: Maybe<Scalars["PropertyFilter"]>;
};

export type MlcUsageResultType = {
  readonly __typename?: "MlcUsageResultType";
  /** Id of the search request */
  readonly id: Scalars["ID"];
  /** Search results */
  readonly results: ReadonlyArray<MlcUsageSummaryItemType>;
};

export type MlcUsageSearchFilterInputType = {
  readonly dateFilterGroup?: InputMaybe<DateFilterGroupType>;
  readonly deletedFilterGroup?: InputMaybe<DeletedFilterGroupType>;
  readonly includeMno?: InputMaybe<Scalars["Boolean"]>;
  readonly statusFilterGroup?: InputMaybe<StatusFilterGroupType>;
  readonly usersFilterGroup?: InputMaybe<UsersFilterGroupType>;
};

export type MlcUsageSummaryItemType = {
  readonly __typename?: "MlcUsageSummaryItemType";
  /** Connected Gno */
  readonly connectedGno?: Maybe<Scalars["String"]>;
  /** Date. */
  readonly date?: Maybe<Scalars["GenesysDate"]>;
  /** Id */
  readonly id?: Maybe<Scalars["ID"]>;
  /** isDeleted */
  readonly isDeleted?: Maybe<Scalars["Boolean"]>;
  /** Last Usage. */
  readonly lastUsage?: Maybe<Scalars["GenesysDate"]>;
  /** mlcItemsCont */
  readonly mLCItemsCount: Scalars["Int"];
  /** Mno string. */
  readonly mNoString?: Maybe<Scalars["String"]>;
  /** Name of mlc item */
  readonly name?: Maybe<Scalars["String"]>;
  /** status */
  readonly status?: Maybe<Scalars["Int"]>;
  /** username */
  readonly userName?: Maybe<Scalars["String"]>;
};

/** Moisture / heat load types. */
export enum MoistureHeatLoadTypeEnum {
  DIFFUSION_THROUGH_MATERIALS = "DIFFUSION_THROUGH_MATERIALS",
  EMISSION_FROM_COMBUSTION = "EMISSION_FROM_COMBUSTION",
  EMISSION_FROM_PEOPLE = "EMISSION_FROM_PEOPLE",
  EVAPORATION_FROM_MATERIALS = "EVAPORATION_FROM_MATERIALS",
  EVAPORATION_OF_WATER = "EVAPORATION_OF_WATER",
  HEAT_TRANSMISSION = "HEAT_TRANSMISSION",
  INTENTIONAL_VENTILATION = "INTENTIONAL_VENTILATION",
  OPEN_DOOR = "OPEN_DOOR",
  STATIC_HEAT_LOAD = "STATIC_HEAT_LOAD",
  STATIC_MOISTURE_LOAD = "STATIC_MOISTURE_LOAD",
  UNINTENDED_VENTILATION = "UNINTENDED_VENTILATION"
}

export type MoistureLoadCalcInputSettingsType = {
  readonly __typename?: "MoistureLoadCalcInputSettingsType";
  /** BinDataLocation */
  readonly binDataLocation: Scalars["String"];
  /** BinSize */
  readonly binSize: Scalars["String"];
  /** CaseOverrides for April */
  readonly caseOverridesApril?: Maybe<Scalars["String"]>;
  /** CaseOverrides for August */
  readonly caseOverridesAugust?: Maybe<Scalars["String"]>;
  /** CaseOverrides for December */
  readonly caseOverridesDecember?: Maybe<Scalars["String"]>;
  /** CaseOverridesDesignSummer */
  readonly caseOverridesDesignSummer?: Maybe<Scalars["String"]>;
  /** CaseOverridesDesignWinter */
  readonly caseOverridesDesignWinter?: Maybe<Scalars["String"]>;
  /** CaseOverrides for February */
  readonly caseOverridesFebruary?: Maybe<Scalars["String"]>;
  /** CaseOverrides for January */
  readonly caseOverridesJanuary?: Maybe<Scalars["String"]>;
  /** CaseOverrides for July */
  readonly caseOverridesJuly?: Maybe<Scalars["String"]>;
  /** CaseOverrides for June */
  readonly caseOverridesJune?: Maybe<Scalars["String"]>;
  /** CaseOverrides for March */
  readonly caseOverridesMarch?: Maybe<Scalars["String"]>;
  /** CaseOverrides for May */
  readonly caseOverridesMay?: Maybe<Scalars["String"]>;
  /** CaseOverrides for November */
  readonly caseOverridesNovember?: Maybe<Scalars["String"]>;
  /** CaseOverrides for October */
  readonly caseOverridesOctober?: Maybe<Scalars["String"]>;
  /** CaseOverrides for September */
  readonly caseOverridesSeptember?: Maybe<Scalars["String"]>;
  /** Climate settings Stored as stringified PropertyValueSet */
  readonly climateSettings: Scalars["String"];
  /** CoolingDataType */
  readonly coolingDataType: Scalars["String"];
  /** OperationTime for the Diffusion Through Materials moisture load. */
  readonly diffusionThroughMaterialsOperationTime?: Maybe<Scalars["String"]>;
  /** OperationTime for the Emission From Combustion moisture load. */
  readonly emissionFromCombustionOperationTime?: Maybe<Scalars["String"]>;
  /** OperationTime for the Emission From People moisture load. */
  readonly emissionFromPeopleOperationTime?: Maybe<Scalars["String"]>;
  /** OperationTime for the Evaporation From Materials moisture load. */
  readonly evaporationFromMaterialsOperationTime?: Maybe<Scalars["String"]>;
  /** OperationTime for the Evaporation Of Water moisture load. */
  readonly evaporationOfWaterOperationTime?: Maybe<Scalars["String"]>;
  /** OperationTime for the Heat Transmission moisture load. */
  readonly heatTransmissionOperationTime?: Maybe<Scalars["String"]>;
  /** Unique id */
  readonly id: Scalars["ID"];
  /** OperationTime for the Intentional Ventilation moisture load. */
  readonly intentionalVentilationOperationTime?: Maybe<Scalars["String"]>;
  /** OperationTime for the Opend door load. */
  readonly openDoorOperationTime?: Maybe<Scalars["String"]>;
  /** Stored as stringified PropertyValueSet. */
  readonly settings: Scalars["String"];
  /** OperationTime for the Static heat load. */
  readonly staticHeatLoadOperationTime?: Maybe<Scalars["String"]>;
  /** OperationTime for the Static moisture load. */
  readonly staticMoistureLoadOperationTime?: Maybe<Scalars["String"]>;
  /** OperationTime for the System. */
  readonly systemOperationTime?: Maybe<Scalars["String"]>;
  /** OperationTime for the Unintended Ventilation moisture load. */
  readonly unintendedVentilationOperationTime?: Maybe<Scalars["String"]>;
};

export type MoistureLoadCalcInputType = {
  /** Bin data location id. */
  readonly binDataLocationId: Scalars["String"];
  /** Bin size. */
  readonly binSize: Scalars["Int"];
  /** List of case specific settings. */
  readonly caseOverrides: ReadonlyArray<CaseOverrideInputType>;
  /** Climate cooling data type (i.e. DB/MCWB etc). */
  readonly climateDataCoolingType: Scalars["String"];
  /** Climate data as stringified property value set. */
  readonly climateDesignData: Scalars["String"];
  /** Moisture load settings as stringified property value set. */
  readonly moistureLoadSettings: Scalars["String"];
  /** List of load specific operation times. */
  readonly operationTimes: ReadonlyArray<MoistureLoadOperationTimesInputType>;
  /** System operation time as stringified property value set. */
  readonly systemOperationTime: Scalars["String"];
};

export type MoistureLoadDiscreteItemType = {
  readonly __typename?: "MoistureLoadDiscreteItemType";
  /** Show description */
  readonly showDescription: Scalars["Boolean"];
  /** Property filter (can be null). */
  readonly validationFilter?: Maybe<Scalars["String"]>;
  /** Property integer value. */
  readonly value?: Maybe<Scalars["Int"]>;
};

export type MoistureLoadFileType = {
  readonly __typename?: "MoistureLoadFileType";
  /** acces control list for users */
  readonly aclClaims: ReadonlyArray<Scalars["String"]>;
  /** acces control list for users */
  readonly aclUsers: ReadonlyArray<Scalars["String"]>;
  /** Profile Id of the user who deleted it */
  readonly deletedByUserProfileID?: Maybe<Scalars["ID"]>;
  /** Moisture load file id */
  readonly id: Scalars["ID"];
  /** If the moisture load has been deleted */
  readonly isDeleted: Scalars["Boolean"];
  /** moisture load file labels */
  readonly labels: ReadonlyArray<LabelType>;
  /** Latest moisture load revision */
  readonly latestMoistureLoad: MoistureLoadItemType;
  /** Moisture load number */
  readonly moistureLoadNo: Scalars["Int"];
  /** moisture loads */
  readonly moistureLoads: ReadonlyArray<MoistureLoadItemType>;
  /** Name of moisture load */
  readonly name: Scalars["String"];
  /** Owner of the moisture load file */
  readonly owner: UserInfoType;
};

export type MoistureLoadInfoType = {
  readonly __typename?: "MoistureLoadInfoType";
  /** Id */
  readonly id: Scalars["ID"];
  /** MoistureLoad No */
  readonly moistureLoadNo: Scalars["Int"];
  /** Revision No */
  readonly revisionNo: Scalars["Int"];
};

export type MoistureLoadInputType = {
  /** Bin data location id. */
  readonly binDataLocationId: Scalars["String"];
  /** Bin size. */
  readonly binSize: Scalars["Int"];
  /** List of case specific settings. */
  readonly caseOverrides: ReadonlyArray<CaseOverrideInputType>;
  /** Climate cooling data type (i.e. DB/MCWB etc). */
  readonly climateDataCoolingType: Scalars["String"];
  /** Climate data as stringified property value set. */
  readonly climateDesignData: Scalars["String"];
  /** Log all calculations? */
  readonly logAllSteps: Scalars["Boolean"];
  /** Moisture load settings as stringified property value set. */
  readonly moistureLoadSettings: Scalars["String"];
  /** List of load specific operation times. */
  readonly operationTimes: ReadonlyArray<MoistureLoadOperationTimesInputType>;
  /** show dev view? */
  readonly showDevView: Scalars["Boolean"];
  /** System operation time as stringified property value set. */
  readonly systemOperationTime: Scalars["String"];
};

export type MoistureLoadItemType = {
  readonly __typename?: "MoistureLoadItemType";
  /** Moisture Load comment */
  readonly comment?: Maybe<Scalars["String"]>;
  /** Moisture load id */
  readonly id: Scalars["ID"];
  /** Moisture load result */
  readonly moistureLoadResult?: Maybe<MoistureLoadResultType>;
  /** Moisture load file */
  readonly moistureloadFile: MoistureLoadFileType;
  /** Moisture load input */
  readonly moistureloadInput?: Maybe<MoistureLoadCalcInputSettingsType>;
  /** Moisture load name */
  readonly name: Scalars["String"];
  /** Revision number */
  readonly revisionNo: Scalars["Int"];
  /** Status */
  readonly status: Scalars["Int"];
  /** Status date */
  readonly statusDate: Scalars["GenesysDate"];
  /** Moisture load result */
  readonly systemInformation?: Maybe<ReadonlyArray<SystemInfoType>>;
};

export type MoistureLoadOperationTimesInputType = {
  /** Which heat/moisture load type. */
  readonly loadType: MoistureHeatLoadTypeEnum;
  /** Settings for this load type. */
  readonly operationTimeSettings: Scalars["String"];
};

export type MoistureLoadPropertyDefinitionType = {
  readonly __typename?: "MoistureLoadPropertyDefinitionType";
  /** Default value of property if its an amount property. */
  readonly defaultValue?: Maybe<Scalars["Amount"]>;
  /** Enabled filter on the property. */
  readonly enabledFilter?: Maybe<Scalars["String"]>;
  /** Validation filter on the true checkbox (CheckboxType). */
  readonly falsePropertyFilter?: Maybe<Scalars["String"]>;
  /** Property definition id */
  readonly id: Scalars["ID"];
  /** Property values. */
  readonly items?: Maybe<ReadonlyArray<MoistureLoadDiscreteItemType>>;
  /** Which type of selector to render. */
  readonly moistureLoadPropertySelectorType: MoistureLoadPropertySelectorType;
  /** Property name. */
  readonly propertyName: Scalars["String"];
  /** Show description */
  readonly showDescription: Scalars["Boolean"];
  /** Validation filter on the true checkbox (CheckboxType). */
  readonly truePropertyFilter?: Maybe<Scalars["String"]>;
  /** Validation filter on property level. */
  readonly validationFilter?: Maybe<Scalars["String"]>;
  /** Visible filter on the property. */
  readonly visibilityFilter?: Maybe<Scalars["String"]>;
};

/** Property selector type */
export enum MoistureLoadPropertySelectorType {
  AMOUNT = "AMOUNT",
  CHECK_BOX = "CHECK_BOX",
  DISCRETE = "DISCRETE"
}

export type MoistureLoadResponseType = {
  readonly __typename?: "MoistureLoadResponseType";
  /** Log traceid */
  readonly logTraceId?: Maybe<Scalars["String"]>;
  /** Errors for the moisture load settings. */
  readonly moistureLoadSettingsErrors: ReadonlyArray<MoistureLoadSettingsErrorsType>;
  /** Results from the calculation. */
  readonly result?: Maybe<MoistureLoadResultType>;
  /** Returns true if calculation succeeded. */
  readonly success: Scalars["Boolean"];
};

export type MoistureLoadResultType = {
  readonly __typename?: "MoistureLoadResultType";
  /** Results for all bins (only if you ask for them). */
  readonly allBinResults: ReadonlyArray<Scalars["PropertyValueSet"]>;
  /** Results to be displayed in diagrams. */
  readonly diagramResults: ReadonlyArray<Scalars["PropertyValueSet"]>;
  /** Names for the properties to be dispalyed. */
  readonly diagramResultsToShow: ReadonlyArray<Scalars["String"]>;
  /** System defining results. */
  readonly maxLoadResults: ReadonlyArray<CaseMaxLoadResultType>;
  /** List of results binned by month. */
  readonly monthlyResults: ReadonlyArray<NamedResultType>;
  /** Warnings and errors related to the result. */
  readonly resultWarnings: ResultWarningAndErrorsType;
  /** Names for the properties to be dispalyed. */
  readonly summaryAndMonthlyResultsToShow: ReadonlyArray<Scalars["String"]>;
  /** Contains design results. */
  readonly summaryResults: ReadonlyArray<NamedResultType>;
};

export type MoistureLoadRulesType = {
  readonly __typename?: "MoistureLoadRulesType";
  /** Default settings */
  readonly defaultSettings: Scalars["PropertyValueSet"];
  /** Id for cacheing */
  readonly id: Scalars["ID"];
  /** Moisture load property rules. */
  readonly propertyDefinitions: ReadonlyArray<MoistureLoadPropertyDefinitionType>;
};

export type MoistureLoadSearchFilterInputType = {
  /** Paginatation */
  readonly endRow: Scalars["Int"];
  /** Order by column */
  readonly orderByColumn: OrderColumnInputType;
  /** Search filter */
  readonly searchKey: Scalars["String"];
  /** Search scope */
  readonly searchScope: MoistureLoadSearchScope;
  /** Paginatation */
  readonly startRow: Scalars["Int"];
};

export type MoistureLoadSearchResultType = {
  readonly __typename?: "MoistureLoadSearchResultType";
  /** Id of the search request */
  readonly id: Scalars["ID"];
  /** Search results */
  readonly results: ReadonlyArray<MoistureLoadFileType>;
  readonly totalResults: Scalars["Int"];
};

/** Search scope */
export enum MoistureLoadSearchScope {
  ALL = "ALL",
  MY = "MY"
}

export type MoistureLoadSettingsErrorsType = {
  readonly __typename?: "MoistureLoadSettingsErrorsType";
  /** Error details. */
  readonly errors: ReadonlyArray<Scalars["String"]>;
  /** Settings. */
  readonly moistureLoadSettings: Scalars["PropertyValueSet"];
  /** Result group */
  readonly resultGroup: Scalars["String"];
};

export type MoistureLoadStatusType = {
  readonly __typename?: "MoistureLoadStatusType";
  /** Status code */
  readonly id: Scalars["ID"];
  /** Status name */
  readonly statusName: Scalars["String"];
};

export type MoistureLoadSystemSearchResultType = {
  readonly __typename?: "MoistureLoadSystemSearchResultType";
  /** Moisture load id */
  readonly id: Scalars["ID"];
  /** Moisture load number */
  readonly moistureLoadNo: Scalars["Int"];
  /** Name */
  readonly name: Scalars["String"];
  /** Revision number */
  readonly revisionNo: Scalars["Int"];
};

export type MoistureLoadToolSettingsInputType = {
  readonly binDataLocation: Scalars["String"];
  readonly binSize: Scalars["String"];
  readonly caseOverridesApril: Scalars["String"];
  readonly caseOverridesAugust: Scalars["String"];
  readonly caseOverridesDecember: Scalars["String"];
  readonly caseOverridesDesignSummer: Scalars["String"];
  readonly caseOverridesDesignWinter: Scalars["String"];
  readonly caseOverridesFebruary: Scalars["String"];
  readonly caseOverridesJanuary: Scalars["String"];
  readonly caseOverridesJuly: Scalars["String"];
  readonly caseOverridesJune: Scalars["String"];
  readonly caseOverridesMarch: Scalars["String"];
  readonly caseOverridesMay: Scalars["String"];
  readonly caseOverridesNovember: Scalars["String"];
  readonly caseOverridesOctober: Scalars["String"];
  readonly caseOverridesSeptember: Scalars["String"];
  readonly climateSettings: Scalars["String"];
  readonly coolingDataType: Scalars["String"];
  readonly diffusionThroughMaterialsOperationTime: Scalars["String"];
  readonly emissionFromCombustionOperationTime: Scalars["String"];
  readonly emissionFromPeopleOperationTime: Scalars["String"];
  readonly evaporationFromMaterialsOperationTime: Scalars["String"];
  readonly evaporationOfWaterOperationTime: Scalars["String"];
  readonly heatTransmissionOperationTime: Scalars["String"];
  readonly intentionalVentilationOperationTime: Scalars["String"];
  readonly openDoorOperationTime: Scalars["String"];
  readonly settings: Scalars["String"];
  readonly staticHeatLoadOperationTime: Scalars["String"];
  readonly staticMoistureLoadOperationTime: Scalars["String"];
  readonly systemOperationTime: Scalars["String"];
  readonly unintendedVentilationOperationTime: Scalars["String"];
};

export type MoveComponentInputType = {
  /** Component id */
  readonly componentId: Scalars["Guid"];
  /** Compnent section positions */
  readonly componentSectionPositions: ReadonlyArray<ComponentSectionPositionInputType>;
  /** System id */
  readonly systemId: Scalars["Guid"];
};

export type MoveComponentMutationResultType = {
  readonly __typename?: "MoveComponentMutationResultType";
  /** Cache patches */
  readonly cachePatches: ReadonlyArray<CachePatchType>;
  /** id */
  readonly id: Scalars["ID"];
};

export type Mutation = {
  readonly __typename?: "Mutation";
  /** Add component to system */
  readonly addComponent: AddComponentMutationResultType;
  /** Add or update MCompare component */
  readonly addOrUpdateMCompareComponent: AddOrUpdateMCompareComponentMutationResultType;
  /** Calculate energy bin case */
  readonly calculateEnergyBinCase: Scalars["Boolean"];
  /** Clear product cache */
  readonly clearProductCache: Scalars["Boolean"];
  /** Create a copy of the specified moisture load to a new moisture load file */
  readonly copyMoistureLoadToNewFile: MoistureLoadFileType;
  /** Create a copy of the specified pricing to a new pricing file */
  readonly copyPricingToNewPricingFile: PricingFileType;
  /** Copies a system and transfer the owenership to the caller */
  readonly copySystemAndTransferToSelf: SystemFileType;
  /** Create a copy of the specified system to a new system file */
  readonly copySystemToNewSystemFile: SystemFileType;
  /** Create user label */
  readonly createLabel: UserProfileType;
  /** Creates a new moisture load */
  readonly createMoistureLoad: CreateMoistureLoadMutationResultType;
  /** Creates a new moisture load revision. Requires that the latest moistureload is locked */
  readonly createNewMoistureLoadRevision: CreateMoistureLoadMutationResultType;
  /** Create a new pricing revision. Requires that the latest pricing is locked */
  readonly createNewPricingRevision: CreatePricingMutationResultType;
  /** Create a new system revision. Requires that the latest system is locked */
  readonly createNewSystemRevision: SystemFileType;
  /** Creates new system(s) */
  readonly createNewSystems: ReadonlyArray<CreateSystemsResultType>;
  /** Creates a new pricing */
  readonly createPricing: CreatePricingMutationResultType;
  /** Creates new system(s) */
  readonly createSystem: ReadonlyArray<Scalars["ID"]>;
  /** Delete amount field format */
  readonly deleteAmountFieldFormat: AmountFieldProfileType;
  /** Delete amount profile */
  readonly deleteAmountProfileSettings: UserSettingsType;
  /** Delete component in system */
  readonly deleteComponent: DeleteComponentMutationResultType;
  /** Delete user label */
  readonly deleteLabel: UserProfileType;
  /** Delete MCompare component */
  readonly deleteMCompareComponent: DeleteMCompareComponentMutationResultType;
  /** Deletes multiple moistureLoad fields */
  readonly deleteMoistureLoadFiles: DeletetionMutationResultType;
  /** Deletes multiple pricing files and there related pricings and pricingrows */
  readonly deletePricingFiles: Scalars["Boolean"];
  /** Deletes a system file and it's related systems */
  readonly deleteSystemFile: DeleteSystemFileType;
  /** Edit revision comment */
  readonly editRevisionComment: SystemItemType;
  /** Exchange component in system */
  readonly exchangeComponent: ExchangeComponentMutationResultType;
  /** Import system model */
  readonly importSystemModel?: Maybe<SystemItemType>;
  /** locks a mnoisture load */
  readonly lockMoistureLoad: UpdateMoistureLoadMutationType;
  /** Move component in system */
  readonly moveComponent: MoveComponentMutationResultType;
  /** Create a new system revision from nevada. Requires that the latest system is locked */
  readonly nevadaCreateNewSystemRevision: NevadaCreateSystemRevResultType;
  /** Recover system file */
  readonly recoverSystemFile: SystemFileType;
  /** Request build in Revit */
  readonly requestBuildInRevit: Scalars["Boolean"];
  /** Request build in SolidContact */
  readonly requestBuildInSolidContact: Scalars["Boolean"];
  /** Resets the current user settings */
  readonly resetUserSettings: UserSettingsType;
  /** Save amount field format */
  readonly saveAmountFieldFormat: AmountFieldProfileType;
  /** Save bin selections and cases */
  readonly saveBinSelectionsAndCases: SaveBinSelectionsAndCasesMutationResultType;
  /** Save energy settings */
  readonly saveEnergySettings: SaveEnergySettingsMutationResultType;
  /** updates the input for a moisture load settings */
  readonly saveMoistureLoadInputs: UpdateMoistureLoadInputMutationResultType;
  /** updates moisture load */
  readonly saveMoistureLoadInputsAndCalculate: MoistureLoadResponseType;
  /** Save operating cases */
  readonly saveOperatingCases: SaveOperatingCasesMutationResultType;
  /** Set active amount profile */
  readonly setActiveAmountProfile: UserSettingsType;
  /** Create or update amount profile */
  readonly setAmountProfileSettings: UserSettingsType;
  /** Set initial settings */
  readonly setInitialSettings: UserSettingsType;
  /** Update access to a moisture load file */
  readonly setMoistureLoadACL: UpdateMoistureLoadMutationType;
  /** Set moisture load settings */
  readonly setMoistureLoadSettings: MoistureLoadCalcInputSettingsType;
  /** Sets the flow to be used for that moisture load */
  readonly setSelectedLoadCaseFlow: UpdateMoistureLoadMutationType;
  /** Set new moisture load id */
  readonly setSystemMoistureLoadId: Scalars["Boolean"];
  /** Set system price information */
  readonly setSystemPriceInformation: SetSystemPriceInformationMutationResultType;
  /** Update climate settings */
  readonly setUserSettingsClimate: UserSettingsType;
  /** Update culture settings */
  readonly setUserSettingsCulture: UserSettingsType;
  /** Update psychrometric chart */
  readonly setUserSettingsPsychrometricChart: UserSettingsType;
  /** Update user sales settings */
  readonly setUserSettingsSales: UserSettingsType;
  /** Update systems summary settings */
  readonly setUserSettingsSystemsSummary: UserSettingsType;
  /** sets if the user has accepted or rejected the terms and conditions */
  readonly setUserTermsAndConditionsStatus: UserProfileType;
  /** Toggle favorite system */
  readonly toggleFavoriteSystem: ReadonlyArray<Maybe<Scalars["String"]>>;
  /** Toggle report for system type */
  readonly toggleReportsForsystemType: UserSettingsType;
  /** Update component properties and accessories */
  readonly updateComponentAccessories: UpdateComponentAccessoriesMutationResultType;
  /** Update component notes */
  readonly updateComponentNotes: UpdateComponentNotesMutationResultType;
  /** Update component properties */
  readonly updateComponentProperties: UpdateComponentPropertiesMutationResultType;
  /** Update user label */
  readonly updateLabel: LabelType;
  /** Updates last opened systems */
  readonly updateLastOpenedSystems: UpdateLastOpenedSystemsMutationResultType;
  /** Update moisture load file */
  readonly updateMoistureLoadFile: UpdateMoistureLoadMutationType;
  /** Update pricing */
  readonly updatePricing: UpdatePricingMutationResultType;
  /** Update pricing file */
  readonly updatePricingFile: UpdatePricingFileResultType;
  /** Update pricing name */
  readonly updatePricingName: UpdatePricingMutationResultType;
  /** Set the id of the seen news item */
  readonly updateSeenNewsItem: UserSettingsType;
  /** Update system */
  readonly updateSystem: UpdateSystemResultType;
  /** Update system file */
  readonly updateSystemFile: UpdateSystemFileResultType;
  /** Update system manager settings */
  readonly updateSystemManagerSettings: UpdateSystemManagerSettingsMutationResultType;
  /** Update system non blocking */
  readonly updateSystemNonBlocking: UpdateSystemResultNonBlockingType;
  /** Update wizard settings */
  readonly updateWizardSettings: UpdateWizardSettingsMutationResultType;
};

export type MutationAddComponentArgs = {
  input: AddComponentInputType;
};

export type MutationAddOrUpdateMCompareComponentArgs = {
  input: AddOrUpdateMCompareComponentInputType;
};

export type MutationCalculateEnergyBinCaseArgs = {
  input: CalculateEnergyBinCaseInputType;
};

export type MutationCopyMoistureLoadToNewFileArgs = {
  moistureLoadId: Scalars["ID"];
};

export type MutationCopyPricingToNewPricingFileArgs = {
  pricingId: Scalars["ID"];
};

export type MutationCopySystemAndTransferToSelfArgs = {
  systemId: Scalars["ID"];
  systemName: Scalars["String"];
};

export type MutationCopySystemToNewSystemFileArgs = {
  systemId: Scalars["ID"];
};

export type MutationCreateLabelArgs = {
  label: LabelInputType;
};

export type MutationCreateMoistureLoadArgs = {
  inputs: CreateMoistureLoadInputType;
};

export type MutationCreateNewMoistureLoadRevisionArgs = {
  moistureLoadFileId: Scalars["ID"];
  name: Scalars["String"];
};

export type MutationCreateNewPricingRevisionArgs = {
  name: Scalars["String"];
  pricingFileId: Scalars["ID"];
};

export type MutationCreateNewSystemRevisionArgs = {
  comment: Scalars["String"];
  revisionNo?: InputMaybe<Scalars["Float"]>;
  systemFileId: Scalars["ID"];
};

export type MutationCreateNewSystemsArgs = {
  systemSettings: ReadonlyArray<CreateSystemInputType>;
};

export type MutationCreatePricingArgs = {
  labels: ReadonlyArray<LabelInputType>;
  name: Scalars["String"];
  systemId?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateSystemArgs = {
  systemSettings: ReadonlyArray<CreateSystemInputType>;
};

export type MutationDeleteAmountFieldFormatArgs = {
  amountFieldFormat: DeleteAmountFieldFormatInputType;
};

export type MutationDeleteAmountProfileSettingsArgs = {
  id: Scalars["String"];
};

export type MutationDeleteComponentArgs = {
  input: DeleteComponentInputType;
};

export type MutationDeleteLabelArgs = {
  labelId: Scalars["ID"];
};

export type MutationDeleteMCompareComponentArgs = {
  input: DeleteMCompareComponentInputType;
};

export type MutationDeleteMoistureLoadFilesArgs = {
  moistureLoadFileIds: ReadonlyArray<Scalars["ID"]>;
};

export type MutationDeletePricingFilesArgs = {
  pricingFileIds: ReadonlyArray<Scalars["ID"]>;
};

export type MutationDeleteSystemFileArgs = {
  systemFileIds: ReadonlyArray<Scalars["ID"]>;
};

export type MutationEditRevisionCommentArgs = {
  comment?: InputMaybe<Scalars["String"]>;
  systemId: Scalars["ID"];
};

export type MutationExchangeComponentArgs = {
  input: ExchangeComponentInputType;
};

export type MutationImportSystemModelArgs = {
  serializedModel: Scalars["String"];
};

export type MutationLockMoistureLoadArgs = {
  moistureLoadId: Scalars["ID"];
};

export type MutationMoveComponentArgs = {
  input: MoveComponentInputType;
};

export type MutationNevadaCreateNewSystemRevisionArgs = {
  comment: Scalars["String"];
  revisionNo?: InputMaybe<Scalars["Float"]>;
  systemFileId: Scalars["ID"];
};

export type MutationRecoverSystemFileArgs = {
  systemFileId: Scalars["ID"];
};

export type MutationRequestBuildInRevitArgs = {
  input: RequestBuildInRevitInputType;
};

export type MutationRequestBuildInSolidContactArgs = {
  input: RequestBuildInSolidContactInputType;
};

export type MutationSaveAmountFieldFormatArgs = {
  amountFieldFormat: AmountFieldFormatInputType;
};

export type MutationSaveBinSelectionsAndCasesArgs = {
  input: SaveBinSelectionsAndCasesInputType;
};

export type MutationSaveEnergySettingsArgs = {
  input: SaveEnergySettingsInputType;
};

export type MutationSaveMoistureLoadInputsArgs = {
  inputSettings: MoistureLoadCalcInputType;
  moistureLoadId: Scalars["ID"];
};

export type MutationSaveMoistureLoadInputsAndCalculateArgs = {
  inputSettings: MoistureLoadInputType;
  moistureLoadId: Scalars["ID"];
};

export type MutationSaveOperatingCasesArgs = {
  input: OperatingCasesInputType;
};

export type MutationSetActiveAmountProfileArgs = {
  amountProfileId: Scalars["String"];
};

export type MutationSetAmountProfileSettingsArgs = {
  amountProfile: AmountProfileInputType;
};

export type MutationSetInitialSettingsArgs = {
  amountProfileId: Scalars["String"];
  userSettingsClimate: UserSettingsClimateInputType;
  userSettingsCulture: UserSettingsCultureInputType;
};

export type MutationSetMoistureLoadAclArgs = {
  input: UpdateMoistureLoadFileAccessInputType;
};

export type MutationSetMoistureLoadSettingsArgs = {
  userSettingsMoistureLoadSettings: MoistureLoadToolSettingsInputType;
};

export type MutationSetSelectedLoadCaseFlowArgs = {
  caseId: Scalars["ID"];
  flowId?: InputMaybe<Scalars["Int"]>;
  moistureloadId: Scalars["ID"];
};

export type MutationSetSystemMoistureLoadIdArgs = {
  moistureLoadId?: InputMaybe<Scalars["ID"]>;
  systemId: Scalars["ID"];
};

export type MutationSetSystemPriceInformationArgs = {
  input: SetSystemPriceInformationInputType;
};

export type MutationSetUserSettingsClimateArgs = {
  userSettingsClimate: UserSettingsClimateInputType;
};

export type MutationSetUserSettingsCultureArgs = {
  userSettingsCulture: UserSettingsCultureInputType;
};

export type MutationSetUserSettingsPsychrometricChartArgs = {
  userSettingsPsychrometricChart: UserSettingsPsychrometricChartInputType;
};

export type MutationSetUserSettingsSalesArgs = {
  userSettingsSales: UserSettingsSalesInputType;
};

export type MutationSetUserSettingsSystemsSummaryArgs = {
  userSettingsSystemsSummary: UserSettingsSystemsSummaryInputType;
};

export type MutationSetUserTermsAndConditionsStatusArgs = {
  hasAccepted: Scalars["Boolean"];
};

export type MutationToggleFavoriteSystemArgs = {
  systemId: Scalars["String"];
};

export type MutationToggleReportsForsystemTypeArgs = {
  reportInfo: ReportInputType;
};

export type MutationUpdateComponentAccessoriesArgs = {
  input: UpdateComponentAccessoriesInputType;
};

export type MutationUpdateComponentNotesArgs = {
  input: UpdateComponentNotesInputType;
};

export type MutationUpdateComponentPropertiesArgs = {
  input: UpdateComponentPropertiesInputType;
};

export type MutationUpdateLabelArgs = {
  label: LabelInputType;
};

export type MutationUpdateLastOpenedSystemsArgs = {
  systemId: Scalars["String"];
};

export type MutationUpdateMoistureLoadFileArgs = {
  input: UpdateMoistureLoadFileInputType;
};

export type MutationUpdatePricingArgs = {
  pricing: PricingInputType;
};

export type MutationUpdatePricingFileArgs = {
  pricingFile: PricingFileInputType;
};

export type MutationUpdatePricingNameArgs = {
  name: Scalars["String"];
  pricingId: Scalars["ID"];
};

export type MutationUpdateSeenNewsItemArgs = {
  id: ReadonlyArray<Scalars["Int"]>;
};

export type MutationUpdateSystemArgs = {
  input: UpdateSystemInputType;
};

export type MutationUpdateSystemFileArgs = {
  systemFile: SystemFileInputType;
};

export type MutationUpdateSystemManagerSettingsArgs = {
  systemManagerSettings: SystemManagerSettingsInputType;
};

export type MutationUpdateSystemNonBlockingArgs = {
  input: UpdateSystemInputType;
};

export type MutationUpdateWizardSettingsArgs = {
  wizardSettings: WizardSettingsInputType;
};

export type NamedResultType = {
  readonly __typename?: "NamedResultType";
  /** Result. */
  readonly propertyValueSet: Scalars["PropertyValueSet"];
  /** Localized name of the result. */
  readonly resultName: ResultName;
};

export type NevadaCompareResultType = {
  readonly __typename?: "NevadaCompareResultType";
  readonly components: ReadonlyArray<NevadaComponentType>;
};

export type NevadaCompareSystemRevisionsInputType = {
  /** Genesys number */
  readonly genesysNo: Scalars["Int"];
  /** Revision key */
  readonly revisionKey: Scalars["Int"];
  /** Revision key */
  readonly revisionTest: Scalars["Int"];
};

export type NevadaComponentPriceType = {
  readonly __typename?: "NevadaComponentPriceType";
  readonly name: Scalars["String"];
  readonly refSystem: Scalars["Float"];
  readonly testSystem: Scalars["Float"];
};

export type NevadaComponentType = {
  readonly __typename?: "NevadaComponentType";
  readonly name: Scalars["String"];
  readonly operatingCases: ReadonlyArray<NevadaOperatingCaseType>;
  readonly price: ReadonlyArray<NevadaComponentPriceType>;
  readonly productId: Scalars["String"];
  readonly properties: NevadaPropertiesType;
  readonly specifications: NevadaPropertiesType;
};

export type NevadaCreateSystemRevResultType = {
  readonly __typename?: "NevadaCreateSystemRevResultType";
  /** The error message */
  readonly errorMessage: Scalars["String"];
  /** Has error */
  readonly hasError: Scalars["Boolean"];
  /** The file itself */
  readonly systemFile?: Maybe<SystemFileType>;
};

export type NevadaOperatingCaseType = {
  readonly __typename?: "NevadaOperatingCaseType";
  readonly name: Scalars["String"];
  readonly refSystem: Scalars["PropertyValueSet"];
  readonly testSystem: Scalars["PropertyValueSet"];
};

export type NevadaPropertiesType = {
  readonly __typename?: "NevadaPropertiesType";
  readonly refSystem?: Maybe<Scalars["PropertyValueSet"]>;
  readonly testSystem?: Maybe<Scalars["PropertyValueSet"]>;
};

export type NevadaResultType = {
  readonly __typename?: "NevadaResultType";
  readonly compareResult?: Maybe<NevadaCompareResultType>;
  readonly errorCode: Scalars["Int"];
  readonly errorText: Scalars["String"];
  readonly hasError: Scalars["Boolean"];
  readonly warnings?: Maybe<ReadonlyArray<Scalars["String"]>>;
};

export type OilonCalculationCaseInputType = {
  /** HighTemperatureStageCondenserInletTemperature */
  readonly highTemperatureStageCondenserInletTemperature: Scalars["String"];
  /** HighTemperatureStageCondenserOutletTemperature */
  readonly highTemperatureStageCondenserOutletTemperature: Scalars["String"];
  /** HighTemperatureStageCondenserPipeLength */
  readonly highTemperatureStageCondenserPipeLength: Scalars["String"];
  /** HighTemperatureStageHeatingCapacityRequirement */
  readonly highTemperatureStageHeatingCapacityRequirement: Scalars["String"];
  /** HighTemperatureStageInUse */
  readonly highTemperatureStageInUse: Scalars["Boolean"];
  /** LowTemperatureStageCondenserInletTemperature */
  readonly lowTemperatureStageCondenserInletTemperature: Scalars["String"];
  /** LowTemperatureStageCondenserOutletTemperature */
  readonly lowTemperatureStageCondenserOutletTemperature: Scalars["String"];
  /** LowTemperatureStageCondenserPipeLength */
  readonly lowTemperatureStageCondenserPipeLength: Scalars["String"];
  /** LowTemperatureStageCoolingCapacityRequirement */
  readonly lowTemperatureStageCoolingCapacityRequirement: Scalars["String"];
  /** LowTemperatureStageEvaporatorInletTemperature */
  readonly lowTemperatureStageEvaporatorInletTemperature: Scalars["String"];
  /** LowTemperatureStageEvaporatorOutletTemperature */
  readonly lowTemperatureStageEvaporatorOutletTemperature: Scalars["String"];
  /** LowTemperatureStageEvaporatorPipeLength */
  readonly lowTemperatureStageEvaporatorPipeLength: Scalars["String"];
  /** LowTemperatureStageHeatingCapacityRequirement */
  readonly lowTemperatureStageHeatingCapacityRequirement: Scalars["String"];
  /** Name */
  readonly name: Scalars["String"];
};

export type OilonCalculationCaseResultType = {
  readonly __typename?: "OilonCalculationCaseResultType";
  /** Cop */
  readonly cop: Scalars["String"];
  /** HighTemperatureStageCirculationPumpPower */
  readonly highTemperatureStageCirculationPumpPower: Scalars["String"];
  /** HighTemperatureStageCondenserFlowRate */
  readonly highTemperatureStageCondenserFlowRate: Scalars["String"];
  /** HighTemperatureStageCop */
  readonly highTemperatureStageCop: Scalars["String"];
  /** HighTemperatureStageElectricPowerConsumed */
  readonly highTemperatureStageElectricPowerConsumed: Scalars["String"];
  /** HighTemperatureStageHeatingCapacity */
  readonly highTemperatureStageHeatingCapacity: Scalars["String"];
  /** HighTemperatureStageLiquidOutletTemperature */
  readonly highTemperatureStageLiquidOutletTemperature: Scalars["String"];
  /** HighTemperatureStageRelativeCapacity */
  readonly highTemperatureStageRelativeCapacity: Scalars["String"];
  /** HighTemperatureStageRelativeCapacityAtMinimumSpeed */
  readonly highTemperatureStageRelativeCapacityAtMinimumSpeed: Scalars["String"];
  /** LowTemperatureStageCirculationPumpPower */
  readonly lowTemperatureStageCirculationPumpPower: Scalars["String"];
  /** LowTemperatureStageCondenserFlowRate */
  readonly lowTemperatureStageCondenserFlowRate: Scalars["String"];
  /** LowTemperatureStageCondenserLiquidOutletTemperature */
  readonly lowTemperatureStageCondenserLiquidOutletTemperature: Scalars["String"];
  /** LowTemperatureStageCoolingCapacity */
  readonly lowTemperatureStageCoolingCapacity: Scalars["String"];
  /** LowTemperatureStageCop */
  readonly lowTemperatureStageCop: Scalars["String"];
  /** LowTemperatureStageElectricPowerConsumed */
  readonly lowTemperatureStageElectricPowerConsumed: Scalars["String"];
  /** LowTemperatureStageEvaporatorFlowRate */
  readonly lowTemperatureStageEvaporatorFlowRate: Scalars["String"];
  /** LowTemperatureStageEvaporatorLiquidOutletTemperature */
  readonly lowTemperatureStageEvaporatorLiquidOutletTemperature: Scalars["String"];
  /** LowTemperatureStageHeatingCapacity */
  readonly lowTemperatureStageHeatingCapacity: Scalars["String"];
  /** LowTemperatureStageRelativeCapacity */
  readonly lowTemperatureStageRelativeCapacity: Scalars["String"];
  /** LowTemperatureStageRelativeCapacityAtMinimumSpeed */
  readonly lowTemperatureStageRelativeCapacityAtMinimumSpeed: Scalars["String"];
  /** Case name */
  readonly name: Scalars["String"];
  /** WetAirCapacityRequired */
  readonly wetAirCapacityRequired: Scalars["String"];
};

export type OilonInputType = {
  /** CalculationCases */
  readonly calculationCases?: InputMaybe<
    ReadonlyArray<OilonCalculationCaseInputType>
  >;
  /** EvaporatorFluidConcentration */
  readonly evaporatorFluidConcentration: Scalars["String"];
  /** EvaporatorFluidType */
  readonly evaporatorFluidType: Scalars["Int"];
  /** HeatPumpConfigurationType */
  readonly heatPumpConfigurationType: Scalars["Int"];
  /** Reference */
  readonly reference: Scalars["String"];
  /** WetAirAvailableAsHeatSink */
  readonly wetAirAvailableAsHeatSink: Scalars["Boolean"];
  /** WetAirAvailableAsHeatSource */
  readonly wetAirAvailableAsHeatSource: Scalars["Boolean"];
};

export type OilonResultType = {
  readonly __typename?: "OilonResultType";
  /** CalculationCases */
  readonly calculationCases?: Maybe<
    ReadonlyArray<OilonCalculationCaseResultType>
  >;
  /** Errors */
  readonly errors?: Maybe<ReadonlyArray<Scalars["String"]>>;
  /** Has results */
  readonly hasResult: Scalars["Boolean"];
  /** OilonId */
  readonly oilonId: Scalars["String"];
};

export enum OperatingCaseGroupEnum {
  APRIL = "APRIL",
  AUGUST = "AUGUST",
  DECEMBER = "DECEMBER",
  DESIGN_SUMMER = "DESIGN_SUMMER",
  DESIGN_WINTER = "DESIGN_WINTER",
  FEBRUARY = "FEBRUARY",
  JANUARY = "JANUARY",
  JULY = "JULY",
  JUNE = "JUNE",
  MARCH = "MARCH",
  MAY = "MAY",
  NOVEMBER = "NOVEMBER",
  OCTOBER = "OCTOBER",
  SEPTEMBER = "SEPTEMBER"
}

export type OperatingCaseResultType = {
  readonly __typename?: "OperatingCaseResultType";
  /** Calculation type */
  readonly calculationType: ComponentCalculationType;
  /** Component id */
  readonly componentId: Scalars["Guid"];
  /** Id */
  readonly id: Scalars["ID"];
  /** Is design case */
  readonly isDesignCase: Scalars["Boolean"];
  /** Settings */
  readonly settings?: Maybe<Scalars["String"]>;
  /** Sort no */
  readonly sortNo: Scalars["Float"];
};

export type OperatingCaseType = {
  readonly __typename?: "OperatingCaseType";
  /** Bin data */
  readonly binData?: Maybe<Scalars["String"]>;
  /** Case name */
  readonly caseName: Scalars["String"];
  /** Case type */
  readonly caseType: Scalars["Float"];
  /** Component */
  readonly condensationAnalysisForOpc?: Maybe<CondensationAnalysisForOpcType>;
  /** Custom case name */
  readonly customCaseName?: Maybe<Scalars["String"]>;
  /** Id */
  readonly id: Scalars["ID"];
  /** Is auto generated */
  readonly isAutoGenerated: Scalars["Boolean"];
  /** Mandatory */
  readonly mandatory: Scalars["Boolean"];
  /** Results */
  readonly results: ReadonlyArray<OperatingCaseResultType>;
  /** Settings */
  readonly settings?: Maybe<Scalars["String"]>;
  /** Sort no */
  readonly sortNo: Scalars["Float"];
  /** Source state source */
  readonly sourceStateSource?: Maybe<Scalars["String"]>;
};

export type OperatingCasesInputType = {
  /** Climate data */
  readonly climateData: Scalars["String"];
  /** operating cases properties */
  readonly operatingCases: ReadonlyArray<Scalars["String"]>;
  /** System id */
  readonly systemId: Scalars["ID"];
};

export type OrderColumnInputType = {
  /** Is descending */
  readonly isDescending: Scalars["Boolean"];
  /** Column name */
  readonly name: Scalars["String"];
};

export type ParamType = {
  readonly __typename?: "ParamType";
  /** Generation message */
  readonly languages: ReadonlyArray<Scalars["String"]>;
  /** Generation message */
  readonly propertyValue?: Maybe<Scalars["Int"]>;
};

/** Position validation */
export enum PositionValidation {
  NONE = "NONE",
  PLACEMENT = "PLACEMENT",
  SEQUENCE = "SEQUENCE"
}

export type PricingArticleType = {
  readonly __typename?: "PricingArticleType";
  /** Article number */
  readonly articleNo: Scalars["String"];
  /** Cost per unit */
  readonly costPerUnit: Scalars["Int"];
  /** Currency code */
  readonly currencyCode: Scalars["String"];
  /** Description */
  readonly description: Scalars["String"];
  /** Pricing article id */
  readonly id: Scalars["ID"];
  /** List Price per unit */
  readonly listPricePerUnit: Scalars["Int"];
  /** Quantity */
  readonly quantity: Scalars["Int"];
  /** Sales organisation id */
  readonly salesOrganisationId: Scalars["String"];
  /** Sort number */
  readonly sortNo: Scalars["Int"];
  /** Template group */
  readonly templateGroup?: Maybe<Scalars["String"]>;
  /** TransferPrice per unit */
  readonly transferPricePerUnit: Scalars["Int"];
  /** Unit */
  readonly unit: Scalars["String"];
};

export type PricingFileInputType = {
  /** Pricing file id */
  readonly id: Scalars["ID"];
  /** Labels ids */
  readonly labelIds: ReadonlyArray<Scalars["ID"]>;
  /** Pricing name */
  readonly name: Scalars["String"];
  /** Pricing owner username */
  readonly owner: Scalars["String"];
};

export type PricingFileType = {
  readonly __typename?: "PricingFileType";
  /** Pricing file id */
  readonly id: Scalars["ID"];
  /** Pricing file labels */
  readonly labels: ReadonlyArray<LabelType>;
  /** Latest pricing revision */
  readonly latestPricing: PricingType;
  /** Name of the Pricing */
  readonly name: Scalars["String"];
  /** Owner of the pricing file */
  readonly owner: UserInfoType;
  /** Pricing number */
  readonly pricingNo: Scalars["Int"];
  /** Pricings */
  readonly pricings: ReadonlyArray<PricingType>;
};

export type PricingInformationType = {
  readonly __typename?: "PricingInformationType";
  /** Id */
  readonly id: Scalars["ID"];
  /** Pricing no */
  readonly pricingNo: Scalars["Int"];
  /** Revision no */
  readonly revisionNo: Scalars["Int"];
};

export type PricingInputType = {
  /** Comment */
  readonly comment?: InputMaybe<Scalars["String"]>;
  /** Currency code */
  readonly currencyCode?: InputMaybe<Scalars["String"]>;
  /** Pricing id */
  readonly id: Scalars["ID"];
  /** Master mode */
  readonly masterMode: Scalars["Boolean"];
  /** Master sales organisation id */
  readonly masterSalesOrganisationId?: InputMaybe<Scalars["String"]>;
  /** Pricing name */
  readonly name: Scalars["String"];
  /** Owner username */
  readonly ownerUserName: Scalars["String"];
  /** Pricing rows */
  readonly rows: ReadonlyArray<PricingRowInputType>;
  /** Sales organisation id */
  readonly salesOrganisationId?: InputMaybe<Scalars["String"]>;
  /** status */
  readonly status: PricingStatus;
};

export type PricingRowInputType = {
  /** Article number */
  readonly articleNo: Scalars["String"];
  /** Cost per unit */
  readonly costPerUnit?: InputMaybe<Scalars["Float"]>;
  /** Description */
  readonly description: Scalars["String"];
  /** Pricing row id */
  readonly id: Scalars["ID"];
  /** list price per unit */
  readonly listPricePerUnit?: InputMaybe<Scalars["Float"]>;
  /** Master price per unit */
  readonly masterPricePerUnit?: InputMaybe<Scalars["Float"]>;
  /** PID */
  readonly pid?: InputMaybe<Scalars["String"]>;
  /** pricing id */
  readonly pricingId: Scalars["String"];
  /** Quantity */
  readonly quantity: Scalars["Float"];
  /** Row number */
  readonly rowNo: Scalars["Int"];
  /** System id */
  readonly systemId?: InputMaybe<Scalars["String"]>;
  /** transfer price per unit */
  readonly transferPricePerUnit?: InputMaybe<Scalars["Float"]>;
  /** Row type */
  readonly type: Scalars["String"];
  /** Unit */
  readonly unit?: InputMaybe<Scalars["String"]>;
  /** Visualizer code */
  readonly visualizerCode?: InputMaybe<Scalars["String"]>;
};

export type PricingRowType = {
  readonly __typename?: "PricingRowType";
  /** Description */
  readonly articleNo: Scalars["String"];
  /** Cost per unit */
  readonly costPerUnit?: Maybe<Scalars["Float"]>;
  /** Description */
  readonly description: Scalars["String"];
  /** Pricing row id */
  readonly id: Scalars["ID"];
  /** list price per unit */
  readonly listPricePerUnit?: Maybe<Scalars["Float"]>;
  /** Master price per unit */
  readonly masterPricePerUnit?: Maybe<Scalars["Float"]>;
  /** Project identification */
  readonly pid?: Maybe<Scalars["String"]>;
  /** Quantity */
  readonly quantity: Scalars["Float"];
  /** Row number */
  readonly rowNo: Scalars["Int"];
  /** System id */
  readonly systemId?: Maybe<Scalars["ID"]>;
  /** Get The SytemTypeId of the system */
  readonly systemTypeId?: Maybe<Scalars["String"]>;
  /** transfer price per unit */
  readonly transferPricePerUnit?: Maybe<Scalars["Float"]>;
  /** Row type */
  readonly type: Scalars["String"];
  /** Unit string */
  readonly unit: Scalars["String"];
  /** Visualizer code */
  readonly visualizerCode: Scalars["String"];
};

export type PricingSearchFilterInputType = {
  /** Paginatation */
  readonly endRow: Scalars["Int"];
  /** Order by column */
  readonly orderByColumn: OrderColumnInputType;
  /** Search filter */
  readonly searchKey: Scalars["String"];
  /** Search scope */
  readonly searchScope: PricingSearchScopeEnum;
  /** Paginatation */
  readonly startRow: Scalars["Int"];
};

export type PricingSearchResultType = {
  readonly __typename?: "PricingSearchResultType";
  /** Id of the search request */
  readonly id: Scalars["ID"];
  /** Search results */
  readonly results: ReadonlyArray<PricingFileType>;
  /** Total results */
  readonly totalResults: Scalars["Int"];
};

/** Search scope */
export enum PricingSearchScopeEnum {
  ALL = "ALL",
  MY = "MY"
}

/** Pricing status */
export enum PricingStatus {
  CREATED = "CREATED",
  LOCKED = "LOCKED"
}

export type PricingType = {
  readonly __typename?: "PricingType";
  /** Pricing comment */
  readonly comment?: Maybe<Scalars["String"]>;
  /** Currency code */
  readonly currencyCode: Scalars["String"];
  /** Exchange rate template id */
  readonly exchangeRateTemplateId?: Maybe<Scalars["String"]>;
  /** Pricing id */
  readonly id: Scalars["ID"];
  /** Master mode */
  readonly masterMode: Scalars["Boolean"];
  /** Master sales organisation code */
  readonly masterSalesOrganisationId?: Maybe<Scalars["String"]>;
  /** Pricing name */
  readonly name: Scalars["String"];
  /** Owner of the pricing */
  readonly owner: UserInfoType;
  /** Pricing file */
  readonly pricingFile: PricingFileType;
  /** Pricing rows */
  readonly pricingRows: ReadonlyArray<PricingRowType>;
  /** Revision number */
  readonly revisionNo: Scalars["Int"];
  /** Sales organisation code */
  readonly salesOrganisationId?: Maybe<Scalars["String"]>;
  /** Pricing status */
  readonly status: PricingStatus;
  /** Status date */
  readonly statusDate: Scalars["GenesysDate"];
};

export type ProductCostValidationInputType = {
  readonly costId: Scalars["Int"];
  readonly errorsOnly: Scalars["Boolean"];
  readonly productId: Scalars["String"];
  readonly rangeProperties: Scalars["String"];
  readonly systemTypeId: Scalars["String"];
};

export type ProductDataTableDefinitionType = {
  readonly __typename?: "ProductDataTableDefinitionType";
  readonly id: Scalars["ID"];
  readonly tableName: Scalars["String"];
  readonly variantDataType: VariantDataType;
};

export type ProductDataTableRowType = {
  readonly __typename?: "ProductDataTableRowType";
  /** Id */
  readonly id: Scalars["ID"];
  /** SortNo */
  readonly propertyFilter?: Maybe<Scalars["PropertyFilter"]>;
  /** SortNo */
  readonly sortNo: Scalars["Int"];
  /** values */
  readonly values: Scalars["PropertyValueSet"];
};

export type ProductDataTableType = {
  readonly __typename?: "ProductDataTableType";
  /** Id field */
  readonly id: Scalars["ID"];
  /** rows */
  readonly rows: ReadonlyArray<ProductDataTableRowType>;
  /** table name */
  readonly tableName: Scalars["String"];
};

export type ProductDataType = {
  readonly __typename?: "ProductDataType";
  /** Active systemTypes */
  readonly activeSystemTypes: ReadonlyArray<SystemTypeGraphType>;
  /** Get all currencies */
  readonly allCurrencies: ReadonlyArray<CurrencyType>;
  /** billOfMaterialItemNosForProduct */
  readonly billOfMaterialItemNosForProduct: ReadonlyArray<Scalars["Int"]>;
  /** The version of hourly data fetched from climate data service */
  readonly binDataHourlyVersion: Scalars["String"];
  /** All bin data location */
  readonly binDataLocations: ReadonlyArray<BinLocationType>;
  /** breakDownItemNosForProduct */
  readonly breakDownItemNosForProduct: ReadonlyArray<Scalars["Int"]>;
  /** All bins for a given location, bin size, and data type. */
  readonly climateDataBins: ReadonlyArray<ClimateDataBinType>;
  /** co2 emission indexes for countries */
  readonly co2EmissionIndexes: ReadonlyArray<Co2FactorType>;
  /** Breakdowns & factors for price calculations */
  readonly componentCostPriceInformation?: Maybe<
    ReadonlyArray<ComponentPriceExplanationType>
  >;
  /** costOperationNosForProduct */
  readonly costOperationNosForProduct: ReadonlyArray<Scalars["Int"]>;
  /** Countries */
  readonly countries: ReadonlyArray<CountryType>;
  /** currency exchange rates */
  readonly currencyRates: ReadonlyArray<CurrencyRateType>;
  /** Data points for location id */
  readonly dataPointsForLocationId?: Maybe<ReadonlyArray<DataPointType>>;
  /** Field default unit info */
  readonly fieldDefaults: ReadonlyArray<FieldDefaultFormatType>;
  /** Get prefixes */
  readonly genesysNumberPrefixes: GenesysNumberPrefixType;
  /** getProductBestPracticeWarnings */
  readonly getProductBestPracticeWarnings: ReadonlyArray<Scalars["String"]>;
  /** getProductBillOfMaterialErrorsForItemNo */
  readonly getProductBillOfMaterialErrorsForItemNo: ReadonlyArray<
    Scalars["String"]
  >;
  /** getProductBreakDownErrorsForItemNo */
  readonly getProductBreakDownErrorsForItemNo: ReadonlyArray<Scalars["String"]>;
  /** getProductCalculationMappingErrors */
  readonly getProductCalculationMappingErrors: ReadonlyArray<Scalars["String"]>;
  /** getProductCircularReferenceErrors */
  readonly getProductCircularReferenceErrors: ReadonlyArray<Scalars["String"]>;
  /** getProductCostOperationsErrorsForOperationNo */
  readonly getProductCostOperationsErrorsForOperationNo: ReadonlyArray<
    Scalars["String"]
  >;
  /** getProductFilterErrors */
  readonly getProductFilterErrors: ReadonlyArray<Scalars["String"]>;
  /** getProductGeneralErrors */
  readonly getProductGeneralErrors: ReadonlyArray<Scalars["String"]>;
  /** getProductPreValidationResult */
  readonly getProductPreValidationResult: ValidationPreValidationResultType;
  /** getProductPropertyTextIssues */
  readonly getProductPropertyTextIssues: ProductPropertyTextIssueType;
  /** getProductPropertyUsageErrors */
  readonly getProductPropertyUsageErrors: ReadonlyArray<Scalars["String"]>;
  /** getProductValueSourceUsageErrors */
  readonly getProductValueSourceUsageErrors: ReadonlyArray<Scalars["String"]>;
  /** getProductVariantDataTableErrors */
  readonly getProductVariantDataTableErrors: ReadonlyArray<Scalars["String"]>;
  /** getRotorProductIds */
  readonly getRotorProductIds: ReadonlyArray<Scalars["String"]>;
  /** getRotorProductValidationErrors */
  readonly getRotorProductValidationErrors: ReadonlyArray<Scalars["String"]>;
  /** getSystemTypeCalculationMappingErrors */
  readonly getSystemTypeCalculationMappingErrors: ReadonlyArray<
    Scalars["String"]
  >;
  /** getSystemTypeCircularReferencesErrors */
  readonly getSystemTypeCircularReferencesErrors: ReadonlyArray<
    Scalars["String"]
  >;
  /** getSystemTypeCostTablesErrors */
  readonly getSystemTypeCostTablesErrors: ReadonlyArray<Scalars["String"]>;
  /** getSystemTypeEnergyErrors */
  readonly getSystemTypeEnergyErrors: ReadonlyArray<Scalars["String"]>;
  /** getSystemTypePreValidationResult */
  readonly getSystemTypePreValidationResult: ValidationPreValidationResultType;
  /** getSystemTypeProductPropertyIssues */
  readonly getSystemTypeProductPropertyIssues: ReadonlyArray<Scalars["String"]>;
  /** getSystemTypeProductPropertyTextIssues */
  readonly getSystemTypeProductPropertyTextIssues: ProductPropertyTextIssueType;
  /** getSystemTypeRangeFilterErrors */
  readonly getSystemTypeRangeFilterErrors: ReadonlyArray<Scalars["String"]>;
  /** getSystemTypeSysAndNewFilterErrors */
  readonly getSystemTypeSysAndNewFilterErrors: ReadonlyArray<Scalars["String"]>;
  /** getSystemTypeTemplateErrors */
  readonly getSystemTypeTemplateErrors: ReadonlyArray<Scalars["String"]>;
  /** getSystemTypeValueSourceUsageErrors */
  readonly getSystemTypeValueSourceUsageErrors: ReadonlyArray<
    Scalars["String"]
  >;
  /** getVariantListingPreValidationResult */
  readonly getVariantListingPreValidationResult: VariantListingPreValidationResultType;
  /** getVariantListingResult */
  readonly getVariantListingResult: VariantListingResultType;
  /** Language info. Null if missing */
  readonly language?: Maybe<LanguageType>;
  /** Gets all active languages */
  readonly languages: ReadonlyArray<LanguageType>;
  /** Gets available locales */
  readonly locales: ReadonlyArray<LocaleType>;
  /** Moisture load status codes */
  readonly mlcStatus: ReadonlyArray<MoistureLoadStatusType>;
  /** Moisture load rules */
  readonly moistureLoadRules: MoistureLoadRulesType;
  readonly product: GenericProductType;
  readonly productPricePreviewExport: ReadonlyArray<ProductPricePreviewExportType>;
  /** productPropertySearch */
  readonly productPropertySearch: ReadonlyArray<Scalars["String"]>;
  /** All psycpro climate data bins for a given location, bin size, and data type. */
  readonly psycProClimateDataBins: ReadonlyArray<PsycProClimateDataBinType>;
  /** Quantity default unit info */
  readonly quantityDefaults: ReadonlyArray<QuantityDefaultFormatType>;
  /** Calculation routes */
  readonly routes: ReadonlyArray<GenesysRouteType>;
  /** Gets all sales organisations */
  readonly salesOrganisations: ReadonlyArray<SalesOrganisationType>;
  /** Server information */
  readonly serverInformation: ServerInformationType;
  /** System status codes */
  readonly systemStatus: ReadonlyArray<SystemStatusType>;
  /** System Type information */
  readonly systemType: SystemTypeGraphType;
  /** systemTypePropertySearch */
  readonly systemTypePropertySearch: ReadonlyArray<Scalars["String"]>;
  /** system types */
  readonly systemTypes: ReadonlyArray<SystemTypeGraphType>;
  /** template component sections from template component ids */
  readonly templateComponentSectionsFromComponents: ReadonlyArray<TemplateComponentComponentSectionType>;
  /** Gets all occurrences of a translation sequence */
  readonly translationOccurrrence: ReadonlyArray<TranslationOccurenceType>;
  /** variantDataTableDefinitionsForProduct */
  readonly variantDataTableDefinitionsForProduct: ReadonlyArray<ProductDataTableDefinitionType>;
};

export type ProductDataTypeBillOfMaterialItemNosForProductArgs = {
  input: GetProductInputType;
};

export type ProductDataTypeBreakDownItemNosForProductArgs = {
  input: GetProductInputType;
};

export type ProductDataTypeClimateDataBinsArgs = {
  input: Scalars["String"];
};

export type ProductDataTypeComponentCostPriceInformationArgs = {
  input: ComponentCostPriceInformationInputType;
};

export type ProductDataTypeCostOperationNosForProductArgs = {
  input: GetProductInputType;
};

export type ProductDataTypeDataPointsForLocationIdArgs = {
  locationId: Scalars["String"];
};

export type ProductDataTypeGetProductBestPracticeWarningsArgs = {
  input: ProductValidationInputType;
};

export type ProductDataTypeGetProductBillOfMaterialErrorsForItemNoArgs = {
  input: ProductCostValidationInputType;
};

export type ProductDataTypeGetProductBreakDownErrorsForItemNoArgs = {
  input: ProductCostValidationInputType;
};

export type ProductDataTypeGetProductCalculationMappingErrorsArgs = {
  input: ProductValidationInputType;
};

export type ProductDataTypeGetProductCircularReferenceErrorsArgs = {
  input: ProductValidationInputType;
};

export type ProductDataTypeGetProductCostOperationsErrorsForOperationNoArgs = {
  input: ProductCostValidationInputType;
};

export type ProductDataTypeGetProductFilterErrorsArgs = {
  input: ProductValidationInputType;
};

export type ProductDataTypeGetProductGeneralErrorsArgs = {
  input: ProductValidationInputType;
};

export type ProductDataTypeGetProductPreValidationResultArgs = {
  input: ProductValidationInputType;
};

export type ProductDataTypeGetProductPropertyTextIssuesArgs = {
  input: ProductTextValidationInputType;
};

export type ProductDataTypeGetProductPropertyUsageErrorsArgs = {
  input: ProductValidationInputType;
};

export type ProductDataTypeGetProductValueSourceUsageErrorsArgs = {
  input: ProductValidationInputType;
};

export type ProductDataTypeGetProductVariantDataTableErrorsArgs = {
  input: VariantDataValidationInputType;
};

export type ProductDataTypeGetRotorProductValidationErrorsArgs = {
  input: RotorProductValidationInputType;
};

export type ProductDataTypeGetSystemTypeCalculationMappingErrorsArgs = {
  input: SystemTypeValidationInputType;
};

export type ProductDataTypeGetSystemTypeCircularReferencesErrorsArgs = {
  input: SystemTypeValidationInputType;
};

export type ProductDataTypeGetSystemTypeCostTablesErrorsArgs = {
  input: SystemTypeValidationInputType;
};

export type ProductDataTypeGetSystemTypeEnergyErrorsArgs = {
  input: SystemTypeValidationInputType;
};

export type ProductDataTypeGetSystemTypePreValidationResultArgs = {
  input: SystemTypeValidationInputType;
};

export type ProductDataTypeGetSystemTypeProductPropertyIssuesArgs = {
  input: SystemTypeValidationInputType;
};

export type ProductDataTypeGetSystemTypeProductPropertyTextIssuesArgs = {
  input: SystemTypeTextValidationInputType;
};

export type ProductDataTypeGetSystemTypeRangeFilterErrorsArgs = {
  input: SystemTypeValidationInputType;
};

export type ProductDataTypeGetSystemTypeSysAndNewFilterErrorsArgs = {
  input: SystemTypeValidationInputType;
};

export type ProductDataTypeGetSystemTypeTemplateErrorsArgs = {
  input: SystemTypeValidationInputType;
};

export type ProductDataTypeGetSystemTypeValueSourceUsageErrorsArgs = {
  input: SystemTypeValidationInputType;
};

export type ProductDataTypeGetVariantListingPreValidationResultArgs = {
  input: VariantListingPreValidationInputType;
};

export type ProductDataTypeGetVariantListingResultArgs = {
  input: VariantListingInputType;
};

export type ProductDataTypeLanguageArgs = {
  languageCode: Scalars["String"];
};

export type ProductDataTypeProductArgs = {
  input: GetProductInputType;
};

export type ProductDataTypeProductPricePreviewExportArgs = {
  input: ProductPricePreviewExportInputType;
};

export type ProductDataTypeProductPropertySearchArgs = {
  input: ProductPropertySearchInputType;
};

export type ProductDataTypePsycProClimateDataBinsArgs = {
  input: Scalars["String"];
};

export type ProductDataTypeSystemTypeArgs = {
  input: GetSystemTypeInputType;
};

export type ProductDataTypeSystemTypePropertySearchArgs = {
  input: SystemTypePropertySearchInputType;
};

export type ProductDataTypeSystemTypesArgs = {
  input?: InputMaybe<ReadonlyArray<GetSystemTypeInputType>>;
};

export type ProductDataTypeTemplateComponentSectionsFromComponentsArgs = {
  ids: ReadonlyArray<Scalars["String"]>;
  systemTypeId: Scalars["String"];
};

export type ProductDataTypeTranslationOccurrrenceArgs = {
  languageCodes: ReadonlyArray<Scalars["String"]>;
  translation: Scalars["String"];
};

export type ProductDataTypeVariantDataTableDefinitionsForProductArgs = {
  input: GetProductInputType;
};

export type ProductPricePreviewExportInputType = {
  /** Product id */
  readonly productId: Scalars["String"];
  /** Properties */
  readonly properties?: InputMaybe<Scalars["String"]>;
  /** Variant Properties */
  readonly variantProperties: ReadonlyArray<Scalars["String"]>;
};

export type ProductPricePreviewExportType = {
  readonly __typename?: "ProductPricePreviewExportType";
  /** Sales organisations */
  readonly salesOrgResult: ReadonlyArray<ProductPriceSalesOrgResultType>;
  /** Product price preview variants */
  readonly variant: Scalars["String"];
};

export type ProductPriceSalesOrgResultType = {
  readonly __typename?: "ProductPriceSalesOrgResultType";
  /** Cost */
  readonly cost?: Maybe<Scalars["Float"]>;
  /** Currency */
  readonly currency: Scalars["String"];
  /** Name */
  readonly name: Scalars["String"];
  /** Region price factor */
  readonly regionPriceFactor?: Maybe<Scalars["Float"]>;
  /** Transfer price factor */
  readonly transferPriceFactor?: Maybe<Scalars["Float"]>;
};

export type ProductPropertyDefaultValueType = {
  readonly __typename?: "ProductPropertyDefaultValueType";
  /** Property filter */
  readonly propertyFilter: Scalars["PropertyFilter"];
  /** Value */
  readonly value: Scalars["PropertyValue"];
};

export type ProductPropertyDescriptionTextType = {
  readonly __typename?: "ProductPropertyDescriptionTextType";
  /** Product property description text id */
  readonly id: Scalars["ID"];
  /** Text language */
  readonly language?: Maybe<Scalars["String"]>;
  /** Property filter */
  readonly propertyFilter: Scalars["PropertyFilter"];
  /** Text */
  readonly text?: Maybe<Scalars["String"]>;
};

export type ProductPropertySearchInputType = {
  readonly productId: Scalars["String"];
  readonly propertyName: Scalars["String"];
};

export type ProductPropertyTextIssueType = {
  readonly __typename?: "ProductPropertyTextIssueType";
  /** Issues in report form */
  readonly errorReport: ReadonlyArray<Scalars["String"]>;
  /** Issues as params */
  readonly issues: ReadonlyArray<IssueType>;
};

export type ProductPropertyType = {
  readonly __typename?: "ProductPropertyType";
  /** Default values */
  readonly defaultValues: ReadonlyArray<ProductPropertyDefaultValueType>;
  /** Property description texts */
  readonly descriptionTexts: ReadonlyArray<ProductPropertyDescriptionTextType>;
  /** Group name */
  readonly groupName: Scalars["String"];
  /** Product property id */
  readonly id: Scalars["ID"];
  /** If property has image property values */
  readonly isImageProperty: Scalars["Boolean"];
  /** Property name */
  readonly name: Scalars["String"];
  /** Product id */
  readonly productId: Scalars["String"];
  /** Quantity */
  readonly quantity: Scalars["String"];
  /** Quantity conversion parameters */
  readonly quantityConversionParams: ReadonlyArray<Scalars["String"]>;
  /** Sort number */
  readonly sortNo?: Maybe<Scalars["Int"]>;
  /** Validation filter */
  readonly validationFilter: Scalars["PropertyFilter"];
  /** Property value source */
  readonly valueSources: ReadonlyArray<ProductPropertyValueSourceType>;
  /** Property values */
  readonly values: ReadonlyArray<ProductPropertyValueType>;
  /** Visibility filter */
  readonly visibilityFilter: Scalars["PropertyFilter"];
};

export type ProductPropertyValueDescriptionTextType = {
  readonly __typename?: "ProductPropertyValueDescriptionTextType";
  /** Product property value description text id */
  readonly id: Scalars["ID"];
  /** Text language */
  readonly language?: Maybe<Scalars["String"]>;
  /** Property filter */
  readonly propertyFilter: Scalars["PropertyFilter"];
  /** Text */
  readonly text?: Maybe<Scalars["String"]>;
};

export type ProductPropertyValueSourceType = {
  readonly __typename?: "ProductPropertyValueSourceType";
  /** Property value source claim filter */
  readonly claimFilter: Scalars["PropertyFilter"];
  /** Product property value source id */
  readonly id: Scalars["ID"];
  /** Property value source id */
  readonly parameters?: Maybe<Scalars["String"]>;
  /** Property value source filter */
  readonly propertyFilter: Scalars["PropertyFilter"];
  /** Property value source id */
  readonly propertyValueSourceId: Scalars["String"];
  /** Property value source value */
  readonly value: Scalars["Int"];
};

export type ProductPropertyValueType = {
  readonly __typename?: "ProductPropertyValueType";
  /** PropertyValue descriptions */
  readonly descriptionTexts: ReadonlyArray<ProductPropertyValueDescriptionTextType>;
  /** Product property value id */
  readonly id: Scalars["ID"];
  readonly image?: Maybe<Scalars["String"]>;
  /** Range filter */
  readonly rangeFilter: Scalars["PropertyFilter"];
  /** Sort number */
  readonly sortNo?: Maybe<Scalars["Int"]>;
  /** Validation filter */
  readonly validationFilter: Scalars["PropertyFilter"];
  /** Value */
  readonly value: Scalars["PropertyValue"];
};

export type ProductSectionPlacementRuleType = {
  readonly __typename?: "ProductSectionPlacementRuleType";
  /** Product section placement rule id */
  readonly id: Scalars["ID"];
  /** Product section placement rule ProductSectionId1 */
  readonly productSectionId1?: Maybe<Scalars["String"]>;
  /** Product section placement rule ProductSectionId2 */
  readonly productSectionId2?: Maybe<Scalars["String"]>;
  /** Product section placement rule RangePropertyFilter */
  readonly rangePropertyFilter?: Maybe<Scalars["PropertyFilter"]>;
};

export type ProductSectionSequenceRuleType = {
  readonly __typename?: "ProductSectionSequenceRuleType";
  /** Product section sequence rule Description */
  readonly description?: Maybe<Scalars["String"]>;
  /** Product section sequence rule id */
  readonly id: Scalars["ID"];
  /** Product section sequence rule Name */
  readonly name?: Maybe<Scalars["String"]>;
  /** Product section sequence rule RangePropertyFilter */
  readonly rangePropertyFilter?: Maybe<Scalars["PropertyFilter"]>;
  /** Product section sequence rule Sequence */
  readonly sequence: Scalars["String"];
};

export type ProductSectionType = {
  readonly __typename?: "ProductSectionType";
  /** Product section id */
  readonly id: Scalars["ID"];
  /** Movable */
  readonly movable: Scalars["Boolean"];
  /** Product id */
  readonly productId: Scalars["String"];
  /** Sort number */
  readonly sortNo: Scalars["Int"];
  /** Visible filter */
  readonly visibleFilter: Scalars["PropertyFilter"];
};

export type ProductTextValidationInputType = {
  readonly errorsOnly: Scalars["Boolean"];
  /** selected languages */
  readonly languages: ReadonlyArray<Scalars["String"]>;
  readonly productId: Scalars["String"];
  readonly systemTypeId: Scalars["String"];
};

export type ProductType = {
  readonly __typename?: "ProductType";
  /** Product type id */
  readonly id: Scalars["ID"];
  /** Product type type name */
  readonly type: ProductTypeEnum;
  /** Product type description */
  readonly validateAboveStatus: SystemStatus;
};

/** Product Type */
export enum ProductTypeEnum {
  ACCESSORY = "ACCESSORY",
  CASING = "CASING",
  CASING_TEMPLATE = "CASING_TEMPLATE",
  FUNCTION = "FUNCTION",
  M_COMPARE = "M_COMPARE",
  SYSTEM = "SYSTEM",
  WIZARD = "WIZARD"
}

export type ProductValidationInputType = {
  readonly errorsOnly: Scalars["Boolean"];
  readonly productId: Scalars["String"];
  readonly rangeProperties: Scalars["String"];
  readonly systemTypeId: Scalars["String"];
};

export type PsycProClimateDataBinType = {
  readonly __typename?: "PsycProClimateDataBinType";
  /** Bin id. */
  readonly binId: Scalars["Int"];
  /** Average specific humidity in the bin. */
  readonly humidity: Scalars["Amount"];
  /** Key. */
  readonly id: Scalars["ID"];
  /** Average temperature in this climate bin. */
  readonly temperature: Scalars["Amount"];
  /** Time spent in this climate bin. */
  readonly time: Scalars["Amount"];
};

export type QuantityDefaultFormatType = {
  readonly __typename?: "QuantityDefaultFormatType";
  /** Decimal count */
  readonly decimalCount: Scalars["Int"];
  /** Quantity default id */
  readonly id: Scalars["ID"];
  /** Measure system. 1=SI, 2=IP */
  readonly measureSystem: Scalars["Int"];
  /** Quantity */
  readonly quantity: Scalars["String"];
  /** Unit */
  readonly unit: Scalars["String"];
};

export type Query = {
  readonly __typename?: "Query";
  /** Immutable product data */
  readonly product: ProductDataType;
  /** Mutable user data */
  readonly user: UserType;
};

export type RegionType = {
  readonly __typename?: "RegionType";
  /** Id of the region */
  readonly id: Scalars["ID"];
  /** Locations of the region */
  readonly locations: ReadonlyArray<LocationType>;
  /** Name of the region */
  readonly name: Scalars["String"];
};

export type RemoveEntityElementCachePatchType = {
  readonly __typename?: "RemoveEntityElementCachePatchType";
  /** entity id */
  readonly entityId: Scalars["String"];
  /** Field name */
  readonly fieldName: Scalars["String"];
  /** id */
  readonly id?: Maybe<Scalars["String"]>;
  /** Typename */
  readonly typename: Scalars["String"];
};

export type ReportInputType = {
  /** guid for the report */
  readonly id: Scalars["ID"];
  /** system type */
  readonly systemType: Scalars["String"];
};

export type ReportSettingsType = {
  readonly __typename?: "ReportSettingsType";
  /** Guid of the report */
  readonly ids: ReadonlyArray<Scalars["String"]>;
  /** The system type */
  readonly systemType: Scalars["String"];
};

export type RequestBuildInRevitInputType = {
  /** Operating case id */
  readonly operatingCaseId: Scalars["ID"];
  /** System id */
  readonly systemId: Scalars["ID"];
};

export type RequestBuildInSolidContactInputType = {
  /** System id */
  readonly systemId: Scalars["ID"];
};

export type ResultName = {
  readonly __typename?: "ResultName";
  /** Plain text result name. */
  readonly name: Scalars["String"];
  /** Result name resource string */
  readonly nameResourceString: Scalars["String"];
};

export type ResultWarningAndErrorsType = {
  readonly __typename?: "ResultWarningAndErrorsType";
  /** Consolidated warnings for the diagram results. */
  readonly diagramWarnings: ReadonlyArray<DiagramWarningType>;
  /** Consolidated warnings for the monthly binned and design results. */
  readonly summaryWarnings: ReadonlyArray<SummaryWarningType>;
};

/** Risk Level types */
export enum RiskLevel {
  HIGH = "HIGH",
  LOW = "LOW",
  NONE = "NONE"
}

/** Which type of warning?. */
export enum RoomWarningEnum {
  GENERAL_WARNING = "GENERAL_WARNING",
  HIGH_HUMIDITY = "HIGH_HUMIDITY",
  HIGH_TEMP = "HIGH_TEMP",
  LOW_HUMIDITY = "LOW_HUMIDITY",
  LOW_TEMP = "LOW_TEMP",
  TARGET_HUMIDITY_LOWERED_TO_SATURATION = "TARGET_HUMIDITY_LOWERED_TO_SATURATION"
}

export type RotorProductValidationInputType = {
  readonly errorsOnly: Scalars["Boolean"];
  readonly rotorProductId: Scalars["String"];
};

export type SalesOrganisationType = {
  readonly __typename?: "SalesOrganisationType";
  /** System type currency */
  readonly defaultCurrency: CurrencyType;
  /** Sales organisation id */
  readonly id: Scalars["ID"];
  /** Sales organisation name */
  readonly name: Scalars["String"];
};

export type SaveBinSelectionsAndCasesInputType = {
  /** Bin operating cases */
  readonly binOperatingCases: ReadonlyArray<BinOperatingCaseInputType>;
  /** Bin selections */
  readonly binSelections: Scalars["String"];
  /** System id */
  readonly systemId: Scalars["ID"];
};

export type SaveBinSelectionsAndCasesMutationResultType = {
  readonly __typename?: "SaveBinSelectionsAndCasesMutationResultType";
  /** Cache patches */
  readonly cachePatches: ReadonlyArray<CachePatchType>;
  /** Id */
  readonly id: Scalars["ID"];
};

export type SaveEnergySettingsInputType = {
  /** Energy settings */
  readonly energySettings: ReadonlyArray<EnergySettingsInputType>;
};

export type SaveEnergySettingsMutationResultType = {
  readonly __typename?: "SaveEnergySettingsMutationResultType";
  /** Cache patches */
  readonly cachePatches: ReadonlyArray<CachePatchType>;
  /** Id */
  readonly id: Scalars["ID"];
};

export type SaveOperatingCasesMutationResultType = {
  readonly __typename?: "SaveOperatingCasesMutationResultType";
  /** Cache patches */
  readonly cachePatches: ReadonlyArray<Maybe<CachePatchType>>;
  /** result id */
  readonly id: Scalars["ID"];
};

export type Screen = {
  readonly __typename?: "Screen";
  /** Screen claim filter */
  readonly claimFilter?: Maybe<Scalars["PropertyFilter"]>;
  /** Screen id */
  readonly id: Scalars["ID"];
  /** Screen property filter */
  readonly propertyFilter?: Maybe<Scalars["PropertyFilter"]>;
  /** Screen parameters */
  readonly screenParameters?: Maybe<Scalars["String"]>;
  /** Screen type enum */
  readonly screenType: ScreenType;
  /** Screen Sort number */
  readonly sortNo: Scalars["Int"];
};

/** Screen Type */
export enum ScreenType {
  ADJUST_PRICE = "ADJUST_PRICE",
  CLIMATIX_CONTROL_FILE = "CLIMATIX_CONTROL_FILE",
  CONDENSATION_ANALYSIS = "CONDENSATION_ANALYSIS",
  DATA_CENTER_EDITOR = "DATA_CENTER_EDITOR",
  ENERGY = "ENERGY",
  MECHANICAL_SKETCH = "MECHANICAL_SKETCH",
  MULTIPLE_SYSTEMS_EDITOR = "MULTIPLE_SYSTEMS_EDITOR",
  M_COMPARE = "M_COMPARE",
  OPERATING_CASES = "OPERATING_CASES",
  PID_DIAGRAM = "PID_DIAGRAM",
  PROPERTY_GROUP = "PROPERTY_GROUP",
  PSYCHROMETRICS_CHART = "PSYCHROMETRICS_CHART",
  REVIT_BUILDER = "REVIT_BUILDER",
  SOLID_CONTACT_BUILDER = "SOLID_CONTACT_BUILDER",
  SYSTEM_ACCESSORIES = "SYSTEM_ACCESSORIES",
  SYSTEM_DIAGRAM = "SYSTEM_DIAGRAM",
  SYSTEM_DOCUMENTS = "SYSTEM_DOCUMENTS",
  SYSTEM_PRINTOUT = "SYSTEM_PRINTOUT",
  SYSTEM_SIZE = "SYSTEM_SIZE"
}

export type ServerInformationType = {
  readonly __typename?: "ServerInformationType";
  /** Client commit info */
  readonly clientCommitInfo: Scalars["String"];
  /** Id */
  readonly id: Scalars["ID"];
  /** Official version info */
  readonly officialVersion: Scalars["String"];
  /** Product Db Info */
  readonly productDbInfo: Scalars["String"];
  /** Server commit info */
  readonly serverCommitInfo: Scalars["String"];
  /** Server name */
  readonly serverName: Scalars["String"];
  /** User Db Info */
  readonly userDbInfo: Scalars["String"];
  /** Version string */
  readonly version: Scalars["String"];
};

export type SetSystemPriceInformationInputType = {
  /** Currency code */
  readonly currencyCode: Scalars["String"];
  /** Master currency code */
  readonly masterCurrencyCode: Scalars["String"];
  /** Master organisation id */
  readonly masterOrganisationId: Scalars["String"];
  /** Sales organisation id */
  readonly salesOrganisationId: Scalars["String"];
  /** System id */
  readonly systemId: Scalars["ID"];
};

export type SetSystemPriceInformationMutationResultType = {
  readonly __typename?: "SetSystemPriceInformationMutationResultType";
  /** Cache patches */
  readonly cachePatches: ReadonlyArray<CachePatchType>;
  /** Result id */
  readonly id: Scalars["ID"];
};

export type SketchComponentType = {
  readonly __typename?: "SketchComponentType";
  /** Component Id */
  readonly componentId: Scalars["Guid"];
  /** Component label */
  readonly componentLabel?: Maybe<Scalars["String"]>;
  /** Id */
  readonly id: Scalars["ID"];
  /** In casing */
  readonly inCasing: Scalars["Boolean"];
  /** Label */
  readonly label?: Maybe<Scalars["String"]>;
  /** Product id */
  readonly productId: Scalars["String"];
  /** Weight */
  readonly weight?: Maybe<Scalars["String"]>;
};

/** Mechanical Sketch provider */
export enum SketchProvider {
  DUMMY = "DUMMY",
  SOLID_CONTACT = "SOLID_CONTACT",
  STATIC_IMAGE = "STATIC_IMAGE"
}

export type StatusFilterGroupType = {
  readonly enabled?: InputMaybe<Scalars["Boolean"]>;
  readonly groupBy?: InputMaybe<Scalars["Boolean"]>;
  readonly selectedStatus: ReadonlyArray<Scalars["Int"]>;
};

export type SteamCoefficientsInputType = {
  /** A max */
  readonly aMax: Scalars["Float"];
  /** A min */
  readonly aMin: Scalars["Float"];
  /** A step */
  readonly aStep: Scalars["Float"];
  /** B max */
  readonly bMax: Scalars["Float"];
  /** B min */
  readonly bMin: Scalars["Float"];
  /** B step */
  readonly bStep: Scalars["Float"];
  /** C max */
  readonly cMax: Scalars["Float"];
  /** C min */
  readonly cMin: Scalars["Float"];
  /** C step */
  readonly cStep: Scalars["Float"];
  /** D max */
  readonly dMax: Scalars["Float"];
  /** D min */
  readonly dMin: Scalars["Float"];
  /** D step */
  readonly dStep: Scalars["Float"];
  /** Geometry */
  readonly geometry: GeometryInputType;
  /** Heating case steam */
  readonly heatingCaseSteam: CoilCoeffCaseSteamInputType;
  /** Heating velocity input */
  readonly heatingVelocityInput: ReadonlyArray<VelocityInputType>;
};

export type SteamMaxPerformanceInputType = {
  /** Flow in */
  readonly flowIn: Scalars["String"];
  /** Geometry */
  readonly geometry: GeometryInputType;
  /** Humidity in */
  readonly humidityIn: Scalars["String"];
  /** Pressure in */
  readonly pressureIn: Scalars["String"];
  /** Safety on capacity */
  readonly safetyOnCapacity: Scalars["String"];
  /** Safety on surface */
  readonly safetyOnSurface: Scalars["String"];
  /** Steam state */
  readonly steamState: SteamStateInputType;
  /** Temperature in */
  readonly temperatureIn: Scalars["String"];
};

export type SteamResultsType = {
  readonly __typename?: "SteamResultsType";
  /** Air pressure drop dry */
  readonly airPressureDropDry: Scalars["String"];
  /** Air total heat */
  readonly airTotalHeat: Scalars["String"];
  /** Air velocity */
  readonly airVelocity: Scalars["String"];
  /** Error code */
  readonly errorCode: Scalars["Int"];
  /** Error text */
  readonly errorText: Scalars["String"];
  /** Flow */
  readonly flow: Scalars["String"];
  /** Has error */
  readonly hasError: Scalars["Boolean"];
  /** Humidity out */
  readonly humidityOut: Scalars["String"];
  /** Marginal */
  readonly marginal: Scalars["String"];
  /** Pressure out */
  readonly pressureOut: Scalars["String"];
  /** Sensible total capacity ratio */
  readonly sensibleTotalCapacityRatio: Scalars["String"];
  /** Steam mass flow */
  readonly steamMassFlow: Scalars["String"];
  /** Steam total heat */
  readonly steamTotalHeat: Scalars["String"];
  /** Steam velocity */
  readonly steamVelocity: Scalars["String"];
  /** Supplier code */
  readonly supplierCode: Scalars["String"];
  /** Temperature out */
  readonly temperatureOut: Scalars["String"];
  /** Total heat */
  readonly totalHeat: Scalars["String"];
};

export type SteamStateInputType = {
  /** Steam pressure */
  readonly steamPressure: Scalars["String"];
  /** Sub cooling */
  readonly subCooling: Scalars["String"];
  /** Super heat */
  readonly superHeat: Scalars["String"];
};

export type SteamTargetTemperatureInputType = {
  /** Flow in */
  readonly flowIn: Scalars["String"];
  /** Geometry */
  readonly geometry: GeometryInputType;
  /** Humidity in */
  readonly humidityIn: Scalars["String"];
  /** Pressure in */
  readonly pressureIn: Scalars["String"];
  /** Safety on capacity */
  readonly safetyOnCapacity: Scalars["String"];
  /** SafetyOnSurface */
  readonly safetyOnSurface: Scalars["String"];
  /** Steam state */
  readonly steamState: SteamStateInputType;
  /** Temperature in */
  readonly temperatureIn: Scalars["String"];
  /** Temperature target */
  readonly temperatureTarget: Scalars["String"];
};

export type SteamTargetTemperatureResultsType = {
  readonly __typename?: "SteamTargetTemperatureResultsType";
  /** Margin on total power */
  readonly marginOnTotalPower: Scalars["String"];
  /** Results */
  readonly results: SteamResultsType;
  /** Target reached */
  readonly targetReached: Scalars["Boolean"];
};

export type SummaryWarningType = {
  readonly __typename?: "SummaryWarningType";
  /** Results this warning belongs to as ResultNameType. */
  readonly localizedResultNames: ReadonlyArray<ResultName>;
  /** Which type of warning?. */
  readonly warning: RoomWarningEnum;
};

export type SurfaceTempAssessmentType = {
  readonly __typename?: "SurfaceTempAssessmentType";
  /** Air Point information */
  readonly airPoint: AirPointType;
  /** Risk Lvels */
  readonly riskLevel: RiskLevel;
  /** Temperature */
  readonly temp: Scalars["Amount"];
};

export type SymbolType = {
  readonly __typename?: "SymbolType";
  /** Height */
  readonly height: Scalars["Float"];
  /** Symbol db id */
  readonly id: Scalars["ID"];
  /** Svg */
  readonly svg: Scalars["String"];
  /** Symbol Id */
  readonly symbolId: Scalars["String"];
  /** Binary image url */
  readonly url: Scalars["String"];
  /** Width */
  readonly width: Scalars["Float"];
};

export type SystemComponentPriceRowType = {
  readonly __typename?: "SystemComponentPriceRowType";
  /** Price type */
  readonly priceType: Scalars["String"];
  /** Value */
  readonly value: Scalars["Float"];
};

export type SystemComponentPriceType = {
  readonly __typename?: "SystemComponentPriceType";
  /** Component id */
  readonly componentId: Scalars["Guid"];
  /** Price rows */
  readonly priceRows: ReadonlyArray<SystemComponentPriceRowType>;
};

export type SystemFileInputType = {
  /** System file id */
  readonly id: Scalars["ID"];
  /** Labels ids */
  readonly labelIds: ReadonlyArray<Scalars["ID"]>;
  /** System name */
  readonly name: Scalars["String"];
  /** System owner username */
  readonly owner: Scalars["String"];
};

export type SystemFileType = {
  readonly __typename?: "SystemFileType";
  /** Deleted by */
  readonly deletedBy?: Maybe<UserInfoType>;
  /** Genesys number */
  readonly genesysNo: Scalars["Int"];
  /** Amount field profile id */
  readonly id: Scalars["ID"];
  /** If the system is deleted */
  readonly isDeleted: Scalars["Boolean"];
  /** System file labels */
  readonly labels: ReadonlyArray<LabelType>;
  /** Name of the system */
  readonly name: Scalars["String"];
  /** Owner of the system file */
  readonly owner: UserInfoType;
  /** System label */
  readonly systemLabel?: Maybe<Scalars["String"]>;
  /** System type id */
  readonly systemTypeId: Scalars["String"];
  /** System revisions */
  readonly systems: ReadonlyArray<SystemItemType>;
};

export type SystemInfoType = {
  readonly __typename?: "SystemInfoType";
  /** Genesys No */
  readonly genesysNo: Scalars["Int"];
  /** Id */
  readonly id: Scalars["ID"];
  /** Revision No */
  readonly revisionNo: Scalars["Int"];
};

export type SystemItemType = {
  readonly __typename?: "SystemItemType";
  /** Airstreams */
  readonly airstreams: ReadonlyArray<AirstreamType>;
  /** Bin selections */
  readonly binSelections?: Maybe<Scalars["String"]>;
  /** Climate settings */
  readonly climateSettings?: Maybe<Scalars["String"]>;
  /** Revision comment */
  readonly comment?: Maybe<Scalars["String"]>;
  /** Components */
  readonly components: ReadonlyArray<ComponentType>;
  /** Currency */
  readonly currencyCode: Scalars["String"];
  /** Energy summary */
  readonly energySummary: EnergySummaryType;
  /** Amount field profile id */
  readonly id: Scalars["ID"];
  /** User who last modified the system */
  readonly lastModifiedBy: UserInfoType;
  /** MCompare summary */
  readonly mCompareSummary: MCompareSummaryType;
  /** Master currency */
  readonly masterCurrencyCode?: Maybe<Scalars["String"]>;
  /** Master sales organisation id */
  readonly masterSalesOrganisationId?: Maybe<Scalars["String"]>;
  /** Moisture Load information */
  readonly moistureLoadInfo?: Maybe<MoistureLoadInfoType>;
  /** Operating case use same properties */
  readonly opCaseUseSameProperties?: Maybe<ReadonlyArray<Scalars["String"]>>;
  /** Operating case use same sources */
  readonly opCaseUseSameSources?: Maybe<ReadonlyArray<Scalars["Float"]>>;
  /** Operating cases */
  readonly operatingCases: ReadonlyArray<OperatingCaseType>;
  /** Owner of the system */
  readonly owner: UserInfoType;
  /** Price calculation date */
  readonly priceCalculationDate?: Maybe<Scalars["GenesysDate"]>;
  /** Pricing information */
  readonly pricingInformation: ReadonlyArray<PricingInformationType>;
  /** Genesys revision */
  readonly revisionNo: Scalars["Int"];
  /** Sales organisation id */
  readonly salesOrganisationId: Scalars["String"];
  /** Status */
  readonly status: Scalars["Int"];
  /** When the system was last changed */
  readonly statusDate: Scalars["GenesysDate"];
  /** System component price */
  readonly systemComponentPrice: ReadonlyArray<SystemComponentPriceType>;
  /** System file/revision */
  readonly systemFile: SystemFileType;
  /** System label */
  readonly systemLabel?: Maybe<Scalars["String"]>;
  /** System validity date */
  readonly validityDate?: Maybe<Scalars["GenesysDate"]>;
};

export type SystemManagerSettingsInputType = {
  readonly lastSearchScopeType?: InputMaybe<Scalars["String"]>;
};

export type SystemManagerSettingsType = {
  readonly __typename?: "SystemManagerSettingsType";
  /** Unique id */
  readonly id: Scalars["ID"];
  /** last search scope type */
  readonly lastSearchScopeType?: Maybe<Scalars["String"]>;
};

export type SystemPricingInformationMasterType = {
  readonly __typename?: "SystemPricingInformationMasterType";
  /** Error from validation */
  readonly error?: Maybe<Scalars["String"]>;
  /** system pricing infromation */
  readonly systemPricingInformation?: Maybe<SystemPricingInformationType>;
};

export type SystemPricingInformationType = {
  readonly __typename?: "SystemPricingInformationType";
  /** Currency code */
  readonly currencyCode: Scalars["String"];
  /** Exchange rate template id */
  readonly exchangeRateTemplateId: Scalars["ID"];
  /** System id */
  readonly id: Scalars["ID"];
  /** List price */
  readonly listPrice: Scalars["Float"];
  /** Margin cost */
  readonly marginCost: Scalars["Float"];
  /** Master currency code */
  readonly masterCurrencyCode: Scalars["String"];
  /** Master sales organisation */
  readonly masterSalesOrganisationId: Scalars["String"];
  /** Master sales price */
  readonly masterSalesPrice: Scalars["Float"];
  /** System name */
  readonly name: Scalars["String"];
  /** PID number */
  readonly pid?: Maybe<Scalars["String"]>;
  /** Sales organisation */
  readonly salesOrganisationId: Scalars["String"];
  /** SystemType Id */
  readonly systemTypeId: Scalars["String"];
  /** Transfer price */
  readonly transferPrice: Scalars["Float"];
};

export type SystemPricingSearchResultType = {
  readonly __typename?: "SystemPricingSearchResultType";
  /** Genesys number */
  readonly genesysNo: Scalars["Int"];
  /** System id */
  readonly id: Scalars["ID"];
  /** Name */
  readonly name: Scalars["String"];
  /** Revision number */
  readonly revisionNo: Scalars["Int"];
};

export type SystemSearchFilterInputType = {
  /** Paginatation */
  readonly endRow: Scalars["Int"];
  /** Order by columns */
  readonly orderByColumns?: InputMaybe<ReadonlyArray<OrderColumnInputType>>;
  /** Search filter */
  readonly searchKey: Scalars["String"];
  /** Search scope */
  readonly searchScope: SystemSearchScope;
  /** Paginatation */
  readonly startRow: Scalars["Int"];
};

export type SystemSearchResultType = {
  readonly __typename?: "SystemSearchResultType";
  /** Id of the search request */
  readonly id: Scalars["ID"];
  /** Search results */
  readonly results: ReadonlyArray<SystemFileType>;
  readonly totalResults: Scalars["Int"];
};

/** Search scope */
export enum SystemSearchScope {
  ALL = "ALL",
  MY = "MY"
}

/** System Status */
export enum SystemStatus {
  AUTO_CASES_FAILED = "AUTO_CASES_FAILED",
  AUTO_CASES_SUCCESS = "AUTO_CASES_SUCCESS",
  AUTO_COMPONENTS_FAILED = "AUTO_COMPONENTS_FAILED",
  AUTO_COMPONENTS_SUCCESS = "AUTO_COMPONENTS_SUCCESS",
  CONFIGURE_SYSTEM_FAILED = "CONFIGURE_SYSTEM_FAILED",
  CONFIGURE_SYSTEM_SUCCESS = "CONFIGURE_SYSTEM_SUCCESS",
  CREATED = "CREATED",
  DESIGN_CALCULATION_FAILED = "DESIGN_CALCULATION_FAILED",
  DESIGN_CALCULATION_SUCCESS = "DESIGN_CALCULATION_SUCCESS",
  ENERGY_CALCULATION_FAILED = "ENERGY_CALCULATION_FAILED",
  ENERGY_CALCULATION_PENDING = "ENERGY_CALCULATION_PENDING",
  ENERGY_CALCULATION_SUCCESS = "ENERGY_CALCULATION_SUCCESS",
  GENERATE_CASINGS_FAILED = "GENERATE_CASINGS_FAILED",
  GENERATE_CASINGS_SUCCESS = "GENERATE_CASINGS_SUCCESS",
  LOCK_FAILED = "LOCK_FAILED",
  LOCK_SUCCESS = "LOCK_SUCCESS",
  M_COMPARE_CALCULATION_FAILED = "M_COMPARE_CALCULATION_FAILED",
  M_COMPARE_CALCULATION_SUCCESS = "M_COMPARE_CALCULATION_SUCCESS",
  ORDER_FAILED = "ORDER_FAILED",
  ORDER_SUCCESS = "ORDER_SUCCESS",
  POST_PROCESS_FAILED = "POST_PROCESS_FAILED",
  POST_PROCESS_SUCCESS = "POST_PROCESS_SUCCESS",
  PRICE_CALCULATION_FAILED = "PRICE_CALCULATION_FAILED",
  PRICE_CALCULATION_SUCCESS = "PRICE_CALCULATION_SUCCESS",
  RATING_CALCULATION_FAILED = "RATING_CALCULATION_FAILED",
  RATING_CALCULATION_SUCCESS = "RATING_CALCULATION_SUCCESS",
  SOUND_FAILED = "SOUND_FAILED",
  SOUND_SUCCESS = "SOUND_SUCCESS",
  VALIDATE_CONFIGURATION_FAILED = "VALIDATE_CONFIGURATION_FAILED",
  VALIDATE_CONFIGURATION_SUCCESS = "VALIDATE_CONFIGURATION_SUCCESS",
  VALIDATE_DESIGN_FAILED = "VALIDATE_DESIGN_FAILED",
  VALIDATE_DESIGN_SUCCESS = "VALIDATE_DESIGN_SUCCESS",
  VALIDATE_FINAL_FAILED = "VALIDATE_FINAL_FAILED",
  VALIDATE_FINAL_SUCCESS = "VALIDATE_FINAL_SUCCESS",
  VALIDATE_M_COMPARE_FAILED = "VALIDATE_M_COMPARE_FAILED",
  VALIDATE_M_COMPARE_SUCCESS = "VALIDATE_M_COMPARE_SUCCESS"
}

export type SystemStatusType = {
  readonly __typename?: "SystemStatusType";
  /** Status code */
  readonly id: Scalars["ID"];
  /** Status name */
  readonly statusName: Scalars["String"];
};

export type SystemTemplateComponentSectionType = {
  readonly __typename?: "SystemTemplateComponentSectionType";
  /** System type template component section Airstream */
  readonly airstream: Scalars["String"];
  /** System type template component section id */
  readonly id: Scalars["ID"];
  /** System type template component section ProductSectionId */
  readonly productSectionId: Scalars["String"];
  /** System type template component section SortNo */
  readonly sortNo: Scalars["Int"];
};

export type SystemTemplateComponentType = {
  readonly __typename?: "SystemTemplateComponentType";
  /** System type template component ComponentSections */
  readonly componentSections: ReadonlyArray<SystemTemplateComponentSectionType>;
  /** System type template component id */
  readonly id: Scalars["ID"];
  /** System type template component label */
  readonly label?: Maybe<Scalars["String"]>;
  /** System type template component label above */
  readonly labelAbove?: Maybe<Scalars["String"]>;
  /** System type template component product id */
  readonly productId: Scalars["String"];
  /** System type template component Properties */
  readonly properties?: Maybe<Scalars["PropertyValueSet"]>;
  /** System type template component PropertyFilter */
  readonly propertyFilter?: Maybe<Scalars["PropertyFilter"]>;
  /** System type template component SortNo */
  readonly sortNo: Scalars["Int"];
  /** System type template component VisibleProperties */
  readonly visibleProperties?: Maybe<ReadonlyArray<Scalars["String"]>>;
};

export type SystemTypeGraphType = {
  readonly __typename?: "SystemTypeGraphType";
  /** System type air point properties */
  readonly airPointProperties: ReadonlyArray<AirPointPropertyType>;
  /** All System type products */
  readonly allProducts: ReadonlyArray<GenericProductType>;
  /** System type description */
  readonly description?: Maybe<Scalars["String"]>;
  /** dhu product */
  readonly dhu: GenericProductType;
  /** System type EnergyChartPresets */
  readonly energyChartPresets: ReadonlyArray<EnergyChartPresetType>;
  /** System type EnergyListItems */
  readonly energyList: ReadonlyArray<EnergyListItemType>;
  /** System type EnergyTotalItems */
  readonly energyTotals: ReadonlyArray<EnergyTotalItemType>;
  /** System type id */
  readonly id: Scalars["ID"];
  /** System type MechanicalSketchProviders */
  readonly mechanicalSketchProviders: ReadonlyArray<MechanicalSketchProviderType>;
  /** System type name */
  readonly name: Scalars["String"];
  /** wizard product */
  readonly new: GenericProductType;
  /** wizard product */
  readonly opc: GenericProductType;
  /** System Type Plenum size property */
  readonly plenumSizeProperty: Scalars["String"];
  /** Position validation */
  readonly positionValidation: PositionValidation;
  /** System type product */
  readonly product: GenericProductType;
  /** System type ProductSectionPlacementRules */
  readonly productSectionPlacementRules: ReadonlyArray<ProductSectionPlacementRuleType>;
  /** System type ProductSectionSequenceRules */
  readonly productSectionSequenceRules: ReadonlyArray<ProductSectionSequenceRuleType>;
  /** System type product */
  readonly products: ReadonlyArray<GenericProductType>;
  /** System type Reports */
  readonly reports: ReadonlyArray<MinSystemStatus>;
  /** System type screens */
  readonly screens: ReadonlyArray<Screen>;
  /** Symbols */
  readonly symbols: ReadonlyArray<SymbolType>;
  /** System product */
  readonly sys: GenericProductType;
  /** System type case filter */
  readonly systemTypeCaseFilter: ReadonlyArray<CaseFilterType>;
  /** System type PriceSetting */
  readonly systemTypePriceSetting: ReadonlyArray<SystemTypePriceSettingType>;
  /** System type SystemTypeResults */
  readonly systemTypeResults: ReadonlyArray<SystemTypeResultType>;
  /** System type status enum */
  readonly systemTypeStatus: SystemTypeStatus;
  /** System type templates */
  readonly templates: ReadonlyArray<SystemTypeTemplateType>;
};

export type SystemTypeGraphTypeDhuArgs = {
  productId?: InputMaybe<Scalars["String"]>;
};

export type SystemTypeGraphTypeNewArgs = {
  productId?: InputMaybe<Scalars["String"]>;
};

export type SystemTypeGraphTypeOpcArgs = {
  productId?: InputMaybe<Scalars["String"]>;
};

export type SystemTypeGraphTypeProductArgs = {
  input?: InputMaybe<GetProductInputType>;
};

export type SystemTypeGraphTypeProductsArgs = {
  inputs: ReadonlyArray<InputMaybe<GetProductInputType>>;
};

export type SystemTypeGraphTypeSysArgs = {
  productId?: InputMaybe<Scalars["String"]>;
};

export type SystemTypePriceSettingType = {
  readonly __typename?: "SystemTypePriceSettingType";
  /** System type price type display exception */
  readonly displayException?: Maybe<Scalars["String"]>;
  /** System type price setting unique id */
  readonly id: Scalars["ID"];
  /** System type calculation model */
  readonly model: Scalars["String"];
};

export type SystemTypePropertySearchInputType = {
  readonly propertyName: Scalars["String"];
  readonly systemTypeId: Scalars["String"];
};

export type SystemTypeResultType = {
  readonly __typename?: "SystemTypeResultType";
  /** System type result Description */
  readonly description?: Maybe<Scalars["String"]>;
  /** System type result id */
  readonly id: Scalars["ID"];
  /** System type result QuantityConversionParams */
  readonly quantityConversionParams: ReadonlyArray<Scalars["String"]>;
  /** System type result QuantityId */
  readonly quantityId: Scalars["String"];
  /** System type result ResultName */
  readonly resultName: Scalars["String"];
};

/** System type status type */
export enum SystemTypeStatus {
  CLOSED = "CLOSED",
  DEAD_AND_BURIED = "DEAD_AND_BURIED",
  DELETE = "DELETE",
  DEVELOP = "DEVELOP",
  PRODUCTION = "PRODUCTION",
  RETIRED = "RETIRED"
}

export type SystemTypeTemplateType = {
  readonly __typename?: "SystemTypeTemplateType";
  /** System type template ClimateDataDefaults */
  readonly climateDataDefaults?: Maybe<Scalars["PropertyValueSet"]>;
  /** System type template Components */
  readonly components: ReadonlyArray<SystemTemplateComponentType>;
  /** System type template id */
  readonly id: Scalars["ID"];
  /** System type template id */
  readonly name: Scalars["String"];
  /** System type template OpCaseUseSameProperties */
  readonly opCaseUseSameProperties?: Maybe<ReadonlyArray<Scalars["String"]>>;
  /** System type template OpCaseUseSameSources */
  readonly opCaseUseSameSources?: Maybe<ReadonlyArray<Scalars["Int"]>>;
  /** System type template PropertyFilter */
  readonly propertyFilter?: Maybe<Scalars["PropertyFilter"]>;
};

export type SystemTypeTextValidationInputType = {
  readonly errorsOnly: Scalars["Boolean"];
  /** selected languages */
  readonly languages: ReadonlyArray<Scalars["String"]>;
  readonly skipRetired: Scalars["Boolean"];
  readonly systemTypeId: Scalars["String"];
};

export type SystemTypeValidationInputType = {
  readonly errorsOnly: Scalars["Boolean"];
  readonly skipRetired: Scalars["Boolean"];
  readonly systemTypeId: Scalars["String"];
};

export type SystemValidationResultType = {
  readonly __typename?: "SystemValidationResultType";
  /** if user has permission */
  readonly hasPermission: Scalars["Boolean"];
  /** if system is valid */
  readonly isValid: Scalars["Boolean"];
  /** if system is valid */
  readonly systemId: Scalars["ID"];
  /** if system is valid */
  readonly systemTypeId: Scalars["String"];
};

export type TandCFilterInputType = {
  readonly acceptanceStatusFilter?: InputMaybe<AcceptanceStatusFilterItemType>;
  readonly userItemFilter?: InputMaybe<UserItemFilterType>;
};

export type TandCResultRowType = {
  readonly __typename?: "TandCResultRowType";
  /** Accepted status */
  readonly acceptedStatus: Scalars["String"];
  /** Date */
  readonly date?: Maybe<Scalars["DateTime"]>;
  /** FirstLogin */
  readonly firstLogin?: Maybe<Scalars["GenesysDate"]>;
  /** Full name */
  readonly fullName: Scalars["String"];
  /** LastLogin */
  readonly lastLogin?: Maybe<Scalars["GenesysDate"]>;
  /** User id */
  readonly userId: Scalars["ID"];
  /** User name */
  readonly userName: Scalars["String"];
};

export type TemplateComponentComponentSectionType = {
  readonly __typename?: "TemplateComponentComponentSectionType";
  /** Template component id */
  readonly id: Scalars["ID"];
  /** Template component sections */
  readonly sections: ReadonlyArray<SystemTemplateComponentSectionType>;
};

export type TemplateComponentInputType = {
  /** System id */
  readonly id: Scalars["ID"];
  /** Properties as property value set */
  readonly properties?: InputMaybe<Scalars["String"]>;
};

export type TranslationOccurenceType = {
  readonly __typename?: "TranslationOccurenceType";
  /** Database */
  readonly database: Scalars["String"];
  /** Language code */
  readonly languageCode: Scalars["String"];
  /** Language text */
  readonly languageText: Scalars["String"];
  /** Text id */
  readonly textId: Scalars["String"];
};

export type TubeInputType = {
  /** External diameter */
  readonly externalDiameter: Scalars["String"];
  /** Fouling */
  readonly fouling: Scalars["Float"];
  /** Material thermal conductivity */
  readonly materialThermalConductivity: Scalars["String"];
  /** Material type */
  readonly materialType: Scalars["Int"];
  /** Thickness */
  readonly thickness: Scalars["String"];
};

export type UpdateComponentAccessoriesInputType = {
  /** Accessory properties per product id */
  readonly accessoriesProperties: ReadonlyArray<AccessoryEntryInputType>;
  /** Component id */
  readonly parentComponentId: Scalars["ID"];
  /** Properties */
  readonly parentComponentProperties: Scalars["String"];
  /** System id */
  readonly systemId: Scalars["ID"];
};

export type UpdateComponentAccessoriesMutationResultType = {
  readonly __typename?: "UpdateComponentAccessoriesMutationResultType";
  /** Cache patches */
  readonly cachePatches: ReadonlyArray<CachePatchType>;
  /** id */
  readonly id: Scalars["ID"];
};

export type UpdateComponentNotesInputType = {
  /** Component id */
  readonly componentId: Scalars["Guid"];
  /** External notes */
  readonly externalNotes: Scalars["String"];
  /** Internal notes */
  readonly internalNotes: Scalars["String"];
  /** System id */
  readonly systemId: Scalars["ID"];
};

export type UpdateComponentNotesMutationResultType = {
  readonly __typename?: "UpdateComponentNotesMutationResultType";
  /** Cache patches */
  readonly cachePatches: ReadonlyArray<CachePatchType>;
  /** id */
  readonly id: Scalars["ID"];
};

export type UpdateComponentPropertiesInputType = {
  /** Component id */
  readonly componentId: Scalars["Guid"];
  /** Properties */
  readonly properties: Scalars["String"];
  /** System id */
  readonly systemId: Scalars["ID"];
};

export type UpdateComponentPropertiesMutationResultType = {
  readonly __typename?: "UpdateComponentPropertiesMutationResultType";
  /** Cache patches */
  readonly cachePatches: ReadonlyArray<CachePatchType>;
  /** id */
  readonly id: Scalars["ID"];
};

export type UpdateFailedType = {
  readonly __typename?: "UpdateFailedType";
  readonly errorMessage: Scalars["String"];
};

export type UpdateFieldCachePatchType = {
  readonly __typename?: "UpdateFieldCachePatchType";
  /** Field value */
  readonly entityFieldValue: EntityFieldValueType;
  /** Field name */
  readonly fieldName: Scalars["String"];
  /** id */
  readonly id?: Maybe<Scalars["String"]>;
  /** Typename */
  readonly typename: Scalars["String"];
};

export type UpdateLastOpenedSystemsMutationResultType = {
  readonly __typename?: "UpdateLastOpenedSystemsMutationResultType";
  /** Cache patches */
  readonly cachePatches: ReadonlyArray<CachePatchType>;
  /** id */
  readonly id: Scalars["ID"];
};

export type UpdateMoistureLoadFileAccessInputType = {
  /** claims to see file */
  readonly allowedClaims?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  /** usernames of allowed users */
  readonly allowedUsers?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  /** Moisture Load file id */
  readonly moistureLoadFileId: Scalars["ID"];
};

export type UpdateMoistureLoadFileInputType = {
  /** Moisture Load file id */
  readonly id: Scalars["ID"];
  /** Labels ids */
  readonly labelIds: ReadonlyArray<Scalars["ID"]>;
  /** The Name of the moisture load file */
  readonly name: Scalars["String"];
  /** The Owner */
  readonly owner: Scalars["String"];
};

export type UpdateMoistureLoadInputMutationResultType = {
  readonly __typename?: "UpdateMoistureLoadInputMutationResultType";
  /** id */
  readonly id: Scalars["ID"];
};

export type UpdateMoistureLoadMutationType = {
  readonly __typename?: "UpdateMoistureLoadMutationType";
  /** error messages if any */
  readonly errorMessage?: Maybe<Scalars["String"]>;
  /** id */
  readonly id: Scalars["ID"];
};

export type UpdatePricingFileResultType = PricingFileType | UpdateFailedType;

export type UpdatePricingMutationResultType = {
  readonly __typename?: "UpdatePricingMutationResultType";
  /** Cache patches */
  readonly cachePatches: ReadonlyArray<CachePatchType>;
  /** id */
  readonly id: Scalars["ID"];
};

export type UpdateSystemFileResultType = SystemFileType | UpdateFailedType;

export type UpdateSystemInputType = {
  /** Force update */
  readonly forceUpdate: Scalars["Boolean"];
  /** System id */
  readonly systemId: Scalars["ID"];
  /** Target status */
  readonly targetStatus: Scalars["Int"];
};

export type UpdateSystemManagerSettingsMutationResultType = {
  readonly __typename?: "UpdateSystemManagerSettingsMutationResultType";
  /** Cache patches */
  readonly cachePatches: ReadonlyArray<CachePatchType>;
  /** id */
  readonly id: Scalars["ID"];
};

export type UpdateSystemRejectResultType = {
  readonly __typename?: "UpdateSystemRejectResultType";
  /** Reject reason */
  readonly rejectReason?: Maybe<Scalars["String"]>;
  /** Trace Id */
  readonly traceId?: Maybe<Scalars["String"]>;
};

export type UpdateSystemResultNonBlockingType = {
  readonly __typename?: "UpdateSystemResultNonBlockingType";
  /** Trace id */
  readonly traceId?: Maybe<Scalars["String"]>;
};

export type UpdateSystemResultType =
  | UpdateSystemRejectResultType
  | UpdateSystemSuccessResultType;

export type UpdateSystemSuccessResultType = {
  readonly __typename?: "UpdateSystemSuccessResultType";
  /** System status */
  readonly status: Scalars["Int"];
  /** Trace id */
  readonly traceId?: Maybe<Scalars["String"]>;
};

export type UpdateWizardSettingsMutationResultType = {
  readonly __typename?: "UpdateWizardSettingsMutationResultType";
  /** Cache patches */
  readonly cachePatches: ReadonlyArray<CachePatchType>;
  /** id */
  readonly id: Scalars["ID"];
};

export type UsageResultType = {
  readonly __typename?: "UsageResultType";
  /** Id of the search request */
  readonly id: Scalars["ID"];
  /** Search results */
  readonly results: ReadonlyArray<UsageSummaryItemType>;
};

export type UsageSearchFilterInputType = {
  /** Filter by date. */
  readonly dateFilter: Scalars["Boolean"];
  /** Group by date. */
  readonly dateGroup: Scalars["Boolean"];
  /** Deleted. */
  readonly deleted: ReadonlyArray<Scalars["Int"]>;
  /** Filter by deleted. */
  readonly deletedFilter: Scalars["Boolean"];
  /** Group by deleted. */
  readonly deletedGroup: Scalars["Boolean"];
  /** From date. */
  readonly fromDate?: InputMaybe<Scalars["GenesysDate"]>;
  /** Include Genesys number. */
  readonly includeGenesysNo: Scalars["Boolean"];
  /** Filter by sales organisation */
  readonly salesOrganisationFilter: Scalars["Boolean"];
  /** Group by sales organisation. */
  readonly salesOrganisationGroup: Scalars["Boolean"];
  /** Sales organisations. */
  readonly salesOrganisations: ReadonlyArray<Scalars["String"]>;
  /** Status. */
  readonly status: ReadonlyArray<Scalars["Int"]>;
  /** Filter by status. */
  readonly statusFilter: Scalars["Boolean"];
  /** Group by status. */
  readonly statusGroup: Scalars["Boolean"];
  /** Filter by system type. */
  readonly systemTypeFilter: Scalars["Boolean"];
  /** Group by system type. */
  readonly systemTypeGroup: Scalars["Boolean"];
  /** System types. */
  readonly systemTypes: ReadonlyArray<Scalars["String"]>;
  /** To date. */
  readonly toDate?: InputMaybe<Scalars["GenesysDate"]>;
  /** Filter by user name. */
  readonly userFilter: Scalars["Boolean"];
  /** Group by user name. */
  readonly userGroup: Scalars["Boolean"];
  /** User name. */
  readonly username: Scalars["String"];
};

export type UsageSummaryItemType = {
  readonly __typename?: "UsageSummaryItemType";
  /** Date. */
  readonly date?: Maybe<Scalars["GenesysDate"]>;
  /** Genesys no string. */
  readonly genesysNoString?: Maybe<Scalars["String"]>;
  /** Id */
  readonly id?: Maybe<Scalars["ID"]>;
  /** Is deleted. */
  readonly isDeleted?: Maybe<Scalars["Boolean"]>;
  /** Last usage. */
  readonly lastUsage?: Maybe<Scalars["GenesysDate"]>;
  /** Sales organisation. */
  readonly salesOrganisationId?: Maybe<Scalars["String"]>;
  /** Status id. */
  readonly statusId?: Maybe<Scalars["Int"]>;
  /** System type id. */
  readonly systemTypeId?: Maybe<Scalars["String"]>;
  /** Systems count. */
  readonly systemsCount: Scalars["Int"];
  /** User full name. */
  readonly userFullName?: Maybe<Scalars["String"]>;
  /** User name. */
  readonly userName?: Maybe<Scalars["String"]>;
};

export type UserInfoType = {
  readonly __typename?: "UserInfoType";
  /** User id */
  readonly id: Scalars["ID"];
  /** User fullname */
  readonly name: Scalars["String"];
  /** Username */
  readonly userName: Scalars["String"];
};

export type UserItemFilterType = {
  readonly selectedUser?: InputMaybe<Scalars["String"]>;
};

export type UserProfileType = {
  readonly __typename?: "UserProfileType";
  /** Is user active */
  readonly active: Scalars["Boolean"];
  /** user claims */
  readonly applicationClaims: ReadonlyArray<ApplicationClaimType>;
  /** Gets user available currencies */
  readonly currencies: ReadonlyArray<CurrencyType>;
  /** First login timestamp */
  readonly firstLogin: Scalars["GenesysDate"];
  /** Checks if user has accepted terms and conditions */
  readonly hasAcceptedTerms: Scalars["Boolean"];
  /** Id of the UserProfile */
  readonly id: Scalars["ID"];
  /** User Labels */
  readonly labels: ReadonlyArray<LabelType>;
  /** last login timestamp */
  readonly lastLogin: Scalars["GenesysDate"];
  /** User full name */
  readonly name: Scalars["String"];
  /** User salesorganisations */
  readonly salesOrganisations: ReadonlyArray<SalesOrganisationType>;
  /** UserName */
  readonly userName: Scalars["String"];
  /** User Settings */
  readonly userSettings: UserSettingsType;
};

export type UserSettingsClimateInputType = {
  readonly climateDataSource?: InputMaybe<Scalars["String"]>;
  readonly coolingDataType?: InputMaybe<Scalars["String"]>;
  readonly coolingOccurence?: InputMaybe<Scalars["String"]>;
  readonly countryName: Scalars["String"];
  readonly heatingDataType?: InputMaybe<Scalars["String"]>;
  readonly heatingOccurence?: InputMaybe<Scalars["String"]>;
  readonly locationId: Scalars["String"];
  readonly manualData?: InputMaybe<Scalars["String"]>;
  readonly regionName: Scalars["String"];
};

export type UserSettingsClimateType = {
  readonly __typename?: "UserSettingsClimateType";
  /** Climate data source. (ASHRAE/Manual) */
  readonly climateDataSource?: Maybe<Scalars["String"]>;
  /** Climate cooling data type */
  readonly coolingDataType?: Maybe<Scalars["String"]>;
  /** Climate cooling occurrence */
  readonly coolingOccurence?: Maybe<Scalars["String"]>;
  /** Climate country */
  readonly country?: Maybe<CountryType>;
  /** Climate heating data type */
  readonly heatingDataType?: Maybe<Scalars["String"]>;
  /** Climate heating occurence */
  readonly heatingOccurence?: Maybe<Scalars["String"]>;
  /** Unique id */
  readonly id: Scalars["ID"];
  /** Climate location */
  readonly location?: Maybe<LocationType>;
  /** Climate selected manual data */
  readonly manualData?: Maybe<Scalars["String"]>;
  /** Climate region */
  readonly region?: Maybe<RegionType>;
};

export type UserSettingsCultureInputType = {
  readonly currency?: InputMaybe<Scalars["String"]>;
  readonly language?: InputMaybe<Scalars["String"]>;
  readonly locale?: InputMaybe<Scalars["String"]>;
};

export type UserSettingsCultureType = {
  readonly __typename?: "UserSettingsCultureType";
  /** Selected currency */
  readonly currency?: Maybe<CurrencyType>;
  /** Unique id */
  readonly id: Scalars["ID"];
  /** Selected language */
  readonly language?: Maybe<LanguageType>;
  /** Selected locale */
  readonly locale?: Maybe<LocaleType>;
};

export type UserSettingsPsychrometricChartInputType = {
  readonly chartType?: InputMaybe<Scalars["String"]>;
  readonly humidityMax?: InputMaybe<Scalars["String"]>;
  readonly limits?: InputMaybe<Scalars["String"]>;
  readonly temperatureMax?: InputMaybe<Scalars["String"]>;
  readonly temperatureMin?: InputMaybe<Scalars["String"]>;
};

export type UserSettingsSalesInputType = {
  readonly defaultSalesOrganisationCode?: InputMaybe<Scalars["String"]>;
};

export type UserSettingsSalesType = {
  readonly __typename?: "UserSettingsSalesType";
  /** User default sales organisation */
  readonly defaultSalesOrganisation?: Maybe<Scalars["String"]>;
  /** Unique id */
  readonly id: Scalars["ID"];
};

export type UserSettingsSystemsSummaryInputType = {
  readonly selectedSummaryItems: Scalars["String"];
};

export type UserSettingsType = {
  readonly __typename?: "UserSettingsType";
  /** The selected amount field profile */
  readonly amountFieldProfiles: ReadonlyArray<AmountFieldProfileType>;
  /** Climate settings */
  readonly climate: UserSettingsClimateType;
  /** Culture settings */
  readonly culture: UserSettingsCultureType;
  /** Energy settings */
  readonly energySettings: ReadonlyArray<EnergySettingsType>;
  /** Users favorites systems */
  readonly favoritesSystems: ReadonlyArray<Scalars["String"]>;
  /** Unique id */
  readonly id: Scalars["ID"];
  /** Last opened systems */
  readonly lastOpenedSystems: ReadonlyArray<SystemItemType>;
  /** Moisture load tool user settings */
  readonly moistureLoadToolUserSettings?: Maybe<MoistureLoadCalcInputSettingsType>;
  /** Psychrometric chart settings */
  readonly psychrometricChartSettings: ReadonlyArray<GenericSettingsType>;
  /** Report settings */
  readonly reportSettings: ReadonlyArray<ReportSettingsType>;
  /** Sales settings */
  readonly sales: UserSettingsSalesType;
  /** seen news items */
  readonly seenNewsItems: ReadonlyArray<Scalars["Int"]>;
  /** The selected amount field profile */
  readonly selectedAmountFieldProfile: AmountFieldProfileType;
  /** System manager settings */
  readonly systemManagerSettings: SystemManagerSettingsType;
  /** Systems Summary user settings */
  readonly systemsSummaryUserSettings: ReadonlyArray<GenericSettingsType>;
  /** Wizard settings */
  readonly wizardSettings: WizardSettingsType;
};

export type UserType = {
  readonly __typename?: "UserType";
  /** Search products */
  readonly advancedProductSearch: AdvancedProductSearchResultType;
  /** Calculate a locked system */
  readonly calculateLockedSystem: CalculateLockedSystemResultType;
  /** Calculate a wizard system */
  readonly calculateNewSystem: CalculateNewSystemResultType;
  /** Calculate the dehumification capacity of new system */
  readonly calculateNewSystemDehumidificationCapacity: Scalars["String"];
  /** Calculate Oilon */
  readonly calculateOilon: OilonResultType;
  /** Calculate coil pro coefficients for condensor coils */
  readonly coilProCondensorCoefficients: HeatingCoefficientsResultsType;
  /** Calculate coil pro max performance for condensor coils */
  readonly coilProCondensorMaxPerformance: ReadonlyArray<FreonResultsType>;
  /** Calculate coil pro target temperature for condensor coils */
  readonly coilProCondensorTargetTemperature: ReadonlyArray<FreonTargetTemperatureResultsType>;
  /** Calculate coil pro coefficients for dx coils */
  readonly coilProDxCoefficients: CoolingCoefficientsResultsType;
  /** Calculate coil pro max performance for dx coils */
  readonly coilProDxMaxPerformance: ReadonlyArray<FreonResultsType>;
  /** Calculate coil pro target temperature for dx coils */
  readonly coilProDxTargetTemperature: ReadonlyArray<FreonTargetTemperatureResultsType>;
  /** Calculate coil pro coefficients for fluid cooling coils */
  readonly coilProFluidCoolingCoefficients: CoolingCoefficientsResultsType;
  /** Calculate coil pro coefficients for dx cooling coils */
  readonly coilProFluidHeatingCoefficients: HeatingCoefficientsResultsType;
  /** Calculate coil pro max performance for fluid coils */
  readonly coilProFluidMaxPerformance: ReadonlyArray<FluidResultsType>;
  /** Calculate coil pro target temperature for fluid coils */
  readonly coilProFluidTargetTemperature: ReadonlyArray<FluidResultsType>;
  /** Calculate coil pro coefficients for steam coils */
  readonly coilProSteamCoefficients: HeatingCoefficientsResultsType;
  /** Calculate coil pro max performance for steam coils */
  readonly coilProSteamMaxPerformance: ReadonlyArray<SteamResultsType>;
  /** Calculate coil pro with target temperature for steam coils */
  readonly coilProSteamTargetTemperature: ReadonlyArray<SteamTargetTemperatureResultsType>;
  /** Component */
  readonly component: ComponentType;
  /** Energy compare summary */
  readonly energyCompareSummary: EnergyCompareSummaryType;
  /** Calculate fan pro performance  */
  readonly fanProFanPerformance: ReadonlyArray<FanResultsType>;
  /** Genesys number to file */
  readonly genesysNumberToFile: SystemFileType;
  /** Get state of the Revit build */
  readonly getBuildInRevitState: BuildInRevitStateType;
  /** Get state of the SolidContact build */
  readonly getBuildInSolidContactState: BuildInSolidContactStateType;
  /** Get calculated properties for component */
  readonly getCalculatedProperties: Scalars["PropertyValueSet"];
  /** Get calculated properties for accessory */
  readonly getCalculatedPropertiesForAccessory: Scalars["PropertyValueSet"];
  /** t&c Acceptance status */
  readonly hasUserHasAcceptedTandC: Scalars["Boolean"];
  /** Mechanical sketch */
  readonly mechanicalSketchMetaData: MechanicalSketchMetaDataType;
  /** Search usage statistics */
  readonly mlcUsageSearch: MlcUsageResultType;
  /** Get specific moisture load by Id */
  readonly moistureLoad?: Maybe<MoistureLoadItemType>;
  /** Get specific moisture load by moisture load no and revision no */
  readonly moistureLoadByMoistureLoadNo?: Maybe<MoistureLoadItemType>;
  /** Moisture load calculation */
  readonly moistureLoadCalculation?: Maybe<MoistureLoadResponseType>;
  /** moisture laod system search */
  readonly moistureLoadSystemSearch: ReadonlyArray<MoistureLoadSystemSearchResultType>;
  /** Compare System Revisions */
  readonly nevadaCompareSystemRevisions: NevadaResultType;
  /** Get specific pricing */
  readonly pricing: PricingType;
  /** Pricing articles */
  readonly pricingArticles: ReadonlyArray<PricingArticleType>;
  /** Get specific pricing by pricingNo and revision */
  readonly pricingByPricingNo?: Maybe<PricingType>;
  /** Pricings for system */
  readonly pricingsForSystem: ReadonlyArray<PricingType>;
  /** Moisture Load search */
  readonly searchMoistureLoads: MoistureLoadSearchResultType;
  /** Pricing search */
  readonly searchPricings: PricingSearchResultType;
  /** System search */
  readonly searchSystems: SystemSearchResultType;
  /** System */
  readonly system: SystemItemType;
  /** System by genesysNo and revision */
  readonly systemByGenesysNo?: Maybe<SystemItemType>;
  /** Compares System with same system type */
  readonly systemCompare: NevadaResultType;
  /** system pricing information */
  readonly systemPricingInformationMaster: SystemPricingInformationMasterType;
  /** system pricing search */
  readonly systemPricingSearch: ReadonlyArray<SystemPricingSearchResultType>;
  /** Systems */
  readonly systems: ReadonlyArray<SystemItemType>;
  /** t&c Acceptance status */
  readonly tAndCAcceptanceStatus: ReadonlyArray<TandCResultRowType>;
  /** Search usage statistics */
  readonly usageSearch: UsageResultType;
  /** Current user */
  readonly userProfile: UserProfileType;
  /** Validates system */
  readonly validateSystem: SystemValidationResultType;
  /** Validates system */
  readonly validateSystemGenesysNo?: Maybe<SystemValidationResultType>;
  /** WikiJS page items */
  readonly wikiJsPages: ReadonlyArray<WikiJsPageItemType>;
};

export type UserTypeAdvancedProductSearchArgs = {
  filter: AdvancedProductSearchInputType;
};

export type UserTypeCalculateLockedSystemArgs = {
  lockedSystemInput: CalculateLockedSystemInputType;
};

export type UserTypeCalculateNewSystemArgs = {
  systemSettings: CreateSystemInputType;
};

export type UserTypeCalculateNewSystemDehumidificationCapacityArgs = {
  systemSettings: CreateSystemInputType;
};

export type UserTypeCalculateOilonArgs = {
  oilonInput: OilonInputType;
};

export type UserTypeCoilProCondensorCoefficientsArgs = {
  input: CondensorCoefficientsInputType;
};

export type UserTypeCoilProCondensorMaxPerformanceArgs = {
  input: ReadonlyArray<FreonMaxPerformanceInputType>;
};

export type UserTypeCoilProCondensorTargetTemperatureArgs = {
  input: ReadonlyArray<FreonTargetTemperatureInputType>;
};

export type UserTypeCoilProDxCoefficientsArgs = {
  input: DxCoefficientsInputType;
};

export type UserTypeCoilProDxMaxPerformanceArgs = {
  input: ReadonlyArray<FreonMaxPerformanceInputType>;
};

export type UserTypeCoilProDxTargetTemperatureArgs = {
  input: ReadonlyArray<FreonTargetTemperatureInputType>;
};

export type UserTypeCoilProFluidCoolingCoefficientsArgs = {
  input: FluidCoolingCoefficientsInputType;
};

export type UserTypeCoilProFluidHeatingCoefficientsArgs = {
  input: FluidHeatingCoefficientsInputType;
};

export type UserTypeCoilProFluidMaxPerformanceArgs = {
  input: ReadonlyArray<FluidMaxPerformanceInputType>;
};

export type UserTypeCoilProFluidTargetTemperatureArgs = {
  input: ReadonlyArray<FluidTargetTemperatureInputType>;
};

export type UserTypeCoilProSteamCoefficientsArgs = {
  input: SteamCoefficientsInputType;
};

export type UserTypeCoilProSteamMaxPerformanceArgs = {
  input: ReadonlyArray<SteamMaxPerformanceInputType>;
};

export type UserTypeCoilProSteamTargetTemperatureArgs = {
  input: ReadonlyArray<SteamTargetTemperatureInputType>;
};

export type UserTypeComponentArgs = {
  componentId: Scalars["ID"];
  systemId: Scalars["ID"];
};

export type UserTypeEnergyCompareSummaryArgs = {
  multisystem: Scalars["Boolean"];
  systemIds: ReadonlyArray<Scalars["ID"]>;
};

export type UserTypeFanProFanPerformanceArgs = {
  input: ReadonlyArray<FanPerformanceInputType>;
};

export type UserTypeGenesysNumberToFileArgs = {
  genesysNo: Scalars["Float"];
};

export type UserTypeGetBuildInRevitStateArgs = {
  input: BuildInRevitStateInputType;
};

export type UserTypeGetBuildInSolidContactStateArgs = {
  input: BuildInSolidContactStateInputType;
};

export type UserTypeGetCalculatedPropertiesArgs = {
  input: GetCalculatedPropertiesInputType;
};

export type UserTypeGetCalculatedPropertiesForAccessoryArgs = {
  input: GetCalculatedPropertiesForAccessoryInputType;
};

export type UserTypeHasUserHasAcceptedTandCArgs = {
  userName: Scalars["String"];
};

export type UserTypeMechanicalSketchMetaDataArgs = {
  input: MechanicalSketchInputType;
};

export type UserTypeMlcUsageSearchArgs = {
  filter: MlcUsageSearchFilterInputType;
};

export type UserTypeMoistureLoadArgs = {
  id: Scalars["ID"];
};

export type UserTypeMoistureLoadByMoistureLoadNoArgs = {
  moistureLoadNo: Scalars["Int"];
  revisionNo: Scalars["Int"];
};

export type UserTypeMoistureLoadCalculationArgs = {
  input: MoistureLoadInputType;
};

export type UserTypeMoistureLoadSystemSearchArgs = {
  searchText: Scalars["String"];
};

export type UserTypeNevadaCompareSystemRevisionsArgs = {
  input: NevadaCompareSystemRevisionsInputType;
};

export type UserTypePricingArgs = {
  id: Scalars["ID"];
};

export type UserTypePricingByPricingNoArgs = {
  pricingNo: Scalars["Int"];
  revisionNo: Scalars["Int"];
};

export type UserTypePricingsForSystemArgs = {
  systemId: Scalars["ID"];
};

export type UserTypeSearchMoistureLoadsArgs = {
  filter: MoistureLoadSearchFilterInputType;
};

export type UserTypeSearchPricingsArgs = {
  filter: PricingSearchFilterInputType;
};

export type UserTypeSearchSystemsArgs = {
  filter: SystemSearchFilterInputType;
};

export type UserTypeSystemArgs = {
  id: Scalars["ID"];
};

export type UserTypeSystemByGenesysNoArgs = {
  genesysNo: Scalars["Int"];
  revisionNo: Scalars["Int"];
};

export type UserTypeSystemCompareArgs = {
  input: CompareSystemsInputType;
};

export type UserTypeSystemPricingInformationMasterArgs = {
  systemId: Scalars["String"];
};

export type UserTypeSystemPricingSearchArgs = {
  searchText: Scalars["String"];
};

export type UserTypeSystemsArgs = {
  ids: ReadonlyArray<Scalars["ID"]>;
};

export type UserTypeTAndCAcceptanceStatusArgs = {
  filter: TandCFilterInputType;
};

export type UserTypeUsageSearchArgs = {
  filter: UsageSearchFilterInputType;
};

export type UserTypeValidateSystemArgs = {
  systemId: Scalars["ID"];
};

export type UserTypeValidateSystemGenesysNoArgs = {
  genesysNo: Scalars["Int"];
  revisionNo: Scalars["Int"];
};

export type UserTypeWikiJsPagesArgs = {
  limit: Scalars["Int"];
  tags: ReadonlyArray<Scalars["String"]>;
};

export type UsersFilterGroupType = {
  readonly enabled?: InputMaybe<Scalars["Boolean"]>;
  readonly groupBy?: InputMaybe<Scalars["Boolean"]>;
  readonly selectedUsers?: InputMaybe<Scalars["String"]>;
};

export type ValidationPreValidationResultType = {
  readonly __typename?: "ValidationPreValidationResultType";
  /** List of errors */
  readonly errors: ReadonlyArray<Scalars["String"]>;
  /** Ok to continue validation? */
  readonly ok: Scalars["Boolean"];
};

/** VariantDataType */
export enum VariantDataType {
  EXACTLY_ONE = "EXACTLY_ONE",
  ONE_OR_MORE = "ONE_OR_MORE",
  ONE_OR_ZERO = "ONE_OR_ZERO",
  UNDEFINED = "UNDEFINED",
  ZERO_OR_MORE = "ZERO_OR_MORE"
}

export type VariantDataValidationInputType = {
  readonly errorsOnly: Scalars["Boolean"];
  readonly filter: Scalars["String"];
  readonly productId: Scalars["String"];
  readonly rangeProperties: Scalars["String"];
  readonly systemTypeId: Scalars["String"];
  readonly tableName: Scalars["String"];
};

export type VariantListingInputType = {
  readonly productId: Scalars["String"];
  readonly rangeProperties: Scalars["String"];
  /** properties to use for generation */
  readonly selectedProperties: ReadonlyArray<VariantListingPropertyType>;
};

export type VariantListingPreValidationInputType = {
  readonly productId: Scalars["String"];
  readonly rangeProperties: Scalars["String"];
  readonly systemTypeId: Scalars["String"];
};

export type VariantListingPreValidationResultType = {
  readonly __typename?: "VariantListingPreValidationResultType";
  /** List of errors */
  readonly errors: ReadonlyArray<Scalars["String"]>;
  /** Ok to continue validation? */
  readonly ok: Scalars["Boolean"];
};

export type VariantListingPropertyType = {
  readonly propertyName: Scalars["String"];
  readonly values: ReadonlyArray<Scalars["Int"]>;
};

export type VariantListingResultType = {
  readonly __typename?: "VariantListingResultType";
  /** Generation message */
  readonly message: Scalars["String"];
  /** List of variants */
  readonly variants: ReadonlyArray<Scalars["PropertyValueSet"]>;
};

export type VelocityInputType = {
  /** Supplier power */
  readonly supplierPower: Scalars["String"];
  /** Supplier pressure */
  readonly supplierPressure: Scalars["String"];
  /** Velocity */
  readonly velocity: Scalars["String"];
};

export type WikiJsPageItemType = {
  readonly __typename?: "WikiJsPageItemType";
  /** Page description */
  readonly description: Scalars["String"];
  /** Content of page */
  readonly htmlContent?: Maybe<Scalars["String"]>;
  /** Page item id */
  readonly id: Scalars["ID"];
  /** Content of page */
  readonly markdownContent?: Maybe<Scalars["String"]>;
  /** Page path */
  readonly path: Scalars["String"];
  /** Page item title */
  readonly title: Scalars["String"];
};

export type WizardSettingsInputType = {
  readonly lastCreatedSystemType?: InputMaybe<Scalars["String"]>;
  readonly lastSelectedLabels?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
};

export type WizardSettingsType = {
  readonly __typename?: "WizardSettingsType";
  /** Create system type */
  readonly createSystemType: ReadonlyArray<CreateSystemType>;
  /** Unique id */
  readonly id: Scalars["ID"];
  /** Last created system type */
  readonly lastCreatedSystemType?: Maybe<Scalars["String"]>;
  /** Last selectedLabels */
  readonly lastSelectedLabels: ReadonlyArray<Scalars["String"]>;
};

/** Min system status */
export type MinSystemStatus = {
  readonly __typename?: "minSystemStatus";
  /** System type report ClaimFilter */
  readonly claimFilter?: Maybe<Scalars["String"]>;
  /** System type report DefaultParameters */
  readonly defaultParameters?: Maybe<Scalars["String"]>;
  /** System type report unique id */
  readonly id: Scalars["ID"];
  /** Min system status */
  readonly minSystemStatus: Scalars["Int"];
  /** System type report Parameters */
  readonly parameters?: Maybe<Scalars["String"]>;
  /** System type report PropertyFilter */
  readonly propertyFilter?: Maybe<Scalars["PropertyFilter"]>;
  /** System type report ReportId */
  readonly reportId: Scalars["String"];
  /** System type report TextId */
  readonly textId?: Maybe<Scalars["String"]>;
  /** System type user report Parameters */
  readonly userParameters?: Maybe<Scalars["String"]>;
};

export type ClimateDataDialog_UserProfileQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ClimateDataDialog_UserProfileQuery = {
  readonly __typename?: "Query";
  readonly user: {
    readonly __typename?: "UserType";
    readonly userProfile: {
      readonly __typename?: "UserProfileType";
      readonly id: string;
      readonly userSettings: {
        readonly __typename?: "UserSettingsType";
        readonly id: string;
        readonly climate: {
          readonly __typename?: "UserSettingsClimateType";
          readonly id: string;
          readonly heatingDataType?: string | null;
          readonly coolingDataType?: string | null;
          readonly heatingOccurence?: string | null;
          readonly coolingOccurence?: string | null;
          readonly climateDataSource?: string | null;
          readonly manualData?: string | null;
          readonly country?: {
            readonly __typename?: "CountryType";
            readonly id: string;
          } | null;
          readonly region?: {
            readonly __typename?: "RegionType";
            readonly id: string;
          } | null;
          readonly location?: {
            readonly __typename?: "LocationType";
            readonly id: string;
          } | null;
        };
      };
    };
  };
};

export type ClimateDataDialog_CountriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ClimateDataDialog_CountriesQuery = {
  readonly __typename?: "Query";
  readonly product: {
    readonly __typename?: "ProductDataType";
    readonly countries: ReadonlyArray<{
      readonly __typename?: "CountryType";
      readonly id: string;
      readonly name: string;
      readonly regions: ReadonlyArray<{
        readonly __typename?: "RegionType";
        readonly id: string;
        readonly name: string;
        readonly locations: ReadonlyArray<{
          readonly __typename?: "LocationType";
          readonly id: string;
          readonly name: string;
          readonly latitude: string;
          readonly longitude: string;
          readonly elevation: string;
          readonly binLocationId: string;
        }>;
      }>;
    }>;
  };
};

export type ClimateDataDialog_DatapointsQueryVariables = Exact<{
  locationId: Scalars["String"];
}>;

export type ClimateDataDialog_DatapointsQuery = {
  readonly __typename?: "Query";
  readonly product: {
    readonly __typename?: "ProductDataType";
    readonly dataPointsForLocationId?: ReadonlyArray<{
      readonly __typename?: "DataPointType";
      readonly id: string;
      readonly caseType: string;
      readonly climateDataType: string;
      readonly annualOccurence?: string | null;
      readonly temperature: string;
      readonly humidity: string;
      readonly windSpeed: string;
    }> | null;
  };
};

export type AddComponentVariables = Exact<{
  input: AddComponentInputType;
}>;

export type AddComponent = {
  readonly __typename?: "Mutation";
  readonly addComponent: {
    readonly __typename?: "AddComponentMutationResultType";
    readonly id: string;
    readonly cachePatches: ReadonlyArray<
      | {
          readonly __typename: "DeleteEntityCachePatchType";
          readonly deletedId: string;
          readonly typename: string;
        }
      | {
          readonly __typename: "InsertElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly index: number;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | {
          readonly __typename: "InvalidateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
        }
      | {
          readonly __typename: "RemoveEntityElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityId: string;
        }
      | {
          readonly __typename: "UpdateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
    >;
  };
};

export type AddOrUpdateMCompareComponentVariables = Exact<{
  input: AddOrUpdateMCompareComponentInputType;
}>;

export type AddOrUpdateMCompareComponent = {
  readonly __typename?: "Mutation";
  readonly addOrUpdateMCompareComponent: {
    readonly __typename?: "AddOrUpdateMCompareComponentMutationResultType";
    readonly id: string;
    readonly cachePatches: ReadonlyArray<
      | {
          readonly __typename: "DeleteEntityCachePatchType";
          readonly deletedId: string;
          readonly typename: string;
        }
      | {
          readonly __typename: "InsertElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly index: number;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | {
          readonly __typename: "InvalidateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
        }
      | {
          readonly __typename: "RemoveEntityElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityId: string;
        }
      | {
          readonly __typename: "UpdateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
    >;
  };
};

export type CopyMoistureLoadToNewFileVariables = Exact<{
  moistureLoadId: Scalars["ID"];
}>;

export type CopyMoistureLoadToNewFile = {
  readonly __typename?: "Mutation";
  readonly copyMoistureLoadToNewFile: {
    readonly __typename?: "MoistureLoadFileType";
    readonly id: string;
    readonly moistureLoadNo: number;
  };
};

export type CopyPricingToNewPricingFileVariables = Exact<{
  pricingId: Scalars["ID"];
}>;

export type CopyPricingToNewPricingFile = {
  readonly __typename?: "Mutation";
  readonly copyPricingToNewPricingFile: {
    readonly __typename?: "PricingFileType";
    readonly id: string;
    readonly name: string;
    readonly pricingNo: number;
    readonly owner: {
      readonly __typename?: "UserInfoType";
      readonly id: string;
      readonly name: string;
      readonly userName: string;
    };
    readonly latestPricing: {
      readonly __typename?: "PricingType";
      readonly id: string;
      readonly name: string;
      readonly comment?: string | null;
      readonly status: PricingStatus;
      readonly statusDate: number;
      readonly revisionNo: number;
      readonly masterMode: boolean;
      readonly currencyCode: string;
      readonly salesOrganisationId?: string | null;
      readonly masterSalesOrganisationId?: string | null;
      readonly exchangeRateTemplateId?: string | null;
      readonly owner: {
        readonly __typename?: "UserInfoType";
        readonly id: string;
        readonly name: string;
        readonly userName: string;
      };
      readonly pricingRows: ReadonlyArray<{
        readonly __typename?: "PricingRowType";
        readonly id: string;
        readonly articleNo: string;
        readonly description: string;
        readonly transferPricePerUnit?: number | null;
        readonly masterPricePerUnit?: number | null;
        readonly systemId?: string | null;
        readonly rowNo: number;
        readonly type: string;
        readonly visualizerCode: string;
        readonly quantity: number;
        readonly unit: string;
        readonly costPerUnit?: number | null;
        readonly pid?: string | null;
      }>;
      readonly pricingFile: {
        readonly __typename?: "PricingFileType";
        readonly id: string;
        readonly name: string;
        readonly pricingNo: number;
        readonly labels: ReadonlyArray<{
          readonly __typename?: "LabelType";
          readonly id: string;
          readonly name: string;
          readonly userProfileId: string;
        }>;
      };
    };
    readonly pricings: ReadonlyArray<{
      readonly __typename?: "PricingType";
      readonly id: string;
      readonly name: string;
      readonly comment?: string | null;
      readonly status: PricingStatus;
      readonly statusDate: number;
      readonly revisionNo: number;
      readonly masterMode: boolean;
      readonly currencyCode: string;
      readonly salesOrganisationId?: string | null;
      readonly masterSalesOrganisationId?: string | null;
      readonly exchangeRateTemplateId?: string | null;
      readonly owner: {
        readonly __typename?: "UserInfoType";
        readonly id: string;
        readonly name: string;
        readonly userName: string;
      };
      readonly pricingRows: ReadonlyArray<{
        readonly __typename?: "PricingRowType";
        readonly id: string;
        readonly articleNo: string;
        readonly description: string;
        readonly transferPricePerUnit?: number | null;
        readonly masterPricePerUnit?: number | null;
        readonly systemId?: string | null;
        readonly rowNo: number;
        readonly type: string;
        readonly visualizerCode: string;
        readonly quantity: number;
        readonly unit: string;
        readonly costPerUnit?: number | null;
        readonly pid?: string | null;
      }>;
      readonly pricingFile: {
        readonly __typename?: "PricingFileType";
        readonly id: string;
        readonly name: string;
        readonly pricingNo: number;
        readonly labels: ReadonlyArray<{
          readonly __typename?: "LabelType";
          readonly id: string;
          readonly name: string;
          readonly userProfileId: string;
        }>;
      };
    }>;
    readonly labels: ReadonlyArray<{
      readonly __typename?: "LabelType";
      readonly id: string;
      readonly name: string;
      readonly userProfileId: string;
    }>;
  };
};

export type CopySystemAndTransferToSelfMutationVariables = Exact<{
  systemId: Scalars["ID"];
  systemName: Scalars["String"];
}>;

export type CopySystemAndTransferToSelfMutation = {
  readonly __typename?: "Mutation";
  readonly copySystemAndTransferToSelf: {
    readonly __typename?: "SystemFileType";
    readonly id: string;
    readonly name: string;
    readonly genesysNo: number;
    readonly systemTypeId: string;
    readonly isDeleted: boolean;
    readonly owner: {
      readonly __typename?: "UserInfoType";
      readonly id: string;
      readonly name: string;
      readonly userName: string;
    };
    readonly labels: ReadonlyArray<{
      readonly __typename?: "LabelType";
      readonly id: string;
      readonly name: string;
      readonly userProfileId: string;
    }>;
    readonly systems: ReadonlyArray<{
      readonly __typename?: "SystemItemType";
      readonly id: string;
      readonly revisionNo: number;
      readonly status: number;
      readonly statusDate: number;
      readonly comment?: string | null;
      readonly owner: {
        readonly __typename?: "UserInfoType";
        readonly id: string;
        readonly name: string;
        readonly userName: string;
      };
    }>;
  };
};

export type CopySystemToNewSystemFileVariables = Exact<{
  systemId: Scalars["ID"];
}>;

export type CopySystemToNewSystemFile = {
  readonly __typename?: "Mutation";
  readonly copySystemToNewSystemFile: {
    readonly __typename?: "SystemFileType";
    readonly id: string;
    readonly name: string;
    readonly genesysNo: number;
    readonly systemTypeId: string;
    readonly isDeleted: boolean;
    readonly owner: {
      readonly __typename?: "UserInfoType";
      readonly id: string;
      readonly name: string;
      readonly userName: string;
    };
    readonly labels: ReadonlyArray<{
      readonly __typename?: "LabelType";
      readonly id: string;
      readonly name: string;
      readonly userProfileId: string;
    }>;
    readonly systems: ReadonlyArray<{
      readonly __typename?: "SystemItemType";
      readonly id: string;
      readonly revisionNo: number;
      readonly status: number;
      readonly statusDate: number;
      readonly comment?: string | null;
      readonly owner: {
        readonly __typename?: "UserInfoType";
        readonly id: string;
        readonly name: string;
        readonly userName: string;
      };
    }>;
  };
};

export type CreateLabelVariables = Exact<{
  label: LabelInputType;
}>;

export type CreateLabel = {
  readonly __typename?: "Mutation";
  readonly createLabel: {
    readonly __typename?: "UserProfileType";
    readonly id: string;
    readonly labels: ReadonlyArray<{
      readonly __typename?: "LabelType";
      readonly id: string;
      readonly name: string;
      readonly userProfileId: string;
    }>;
  };
};

export type CreateMoistureLoadVariables = Exact<{
  input: CreateMoistureLoadInputType;
}>;

export type CreateMoistureLoad = {
  readonly __typename?: "Mutation";
  readonly createMoistureLoad: {
    readonly __typename?: "CreateMoistureLoadMutationResultType";
    readonly id: string;
    readonly moistureLoadId: string;
    readonly revisionNo: number;
    readonly moistureLoadNo: number;
  };
};

export type CreateNewMoistureLoadRevisionVariables = Exact<{
  moistureLoadFileId: Scalars["ID"];
  name: Scalars["String"];
}>;

export type CreateNewMoistureLoadRevision = {
  readonly __typename?: "Mutation";
  readonly createNewMoistureLoadRevision: {
    readonly __typename?: "CreateMoistureLoadMutationResultType";
    readonly id: string;
    readonly revisionNo: number;
    readonly moistureLoadNo: number;
  };
};

export type CreateNewPricingRevisionVariables = Exact<{
  pricingFileId: Scalars["ID"];
  name: Scalars["String"];
}>;

export type CreateNewPricingRevision = {
  readonly __typename?: "Mutation";
  readonly createNewPricingRevision: {
    readonly __typename?: "CreatePricingMutationResultType";
    readonly id: string;
    readonly revisionNo: number;
    readonly pricingNo: number;
    readonly cachePatches: ReadonlyArray<
      | {
          readonly __typename: "DeleteEntityCachePatchType";
          readonly deletedId: string;
          readonly typename: string;
        }
      | {
          readonly __typename: "InsertElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly index: number;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | {
          readonly __typename: "InvalidateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
        }
      | {
          readonly __typename: "RemoveEntityElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityId: string;
        }
      | {
          readonly __typename: "UpdateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
    >;
  };
};

export type CreateNewSystemRevisionVariables = Exact<{
  systemFileId: Scalars["ID"];
  comment: Scalars["String"];
}>;

export type CreateNewSystemRevision = {
  readonly __typename?: "Mutation";
  readonly createNewSystemRevision: {
    readonly __typename?: "SystemFileType";
    readonly id: string;
    readonly name: string;
    readonly genesysNo: number;
    readonly systemTypeId: string;
    readonly isDeleted: boolean;
    readonly owner: {
      readonly __typename?: "UserInfoType";
      readonly id: string;
      readonly name: string;
      readonly userName: string;
    };
    readonly labels: ReadonlyArray<{
      readonly __typename?: "LabelType";
      readonly id: string;
      readonly name: string;
      readonly userProfileId: string;
    }>;
    readonly systems: ReadonlyArray<{
      readonly __typename?: "SystemItemType";
      readonly id: string;
      readonly revisionNo: number;
      readonly status: number;
      readonly statusDate: number;
      readonly comment?: string | null;
      readonly owner: {
        readonly __typename?: "UserInfoType";
        readonly id: string;
        readonly name: string;
        readonly userName: string;
      };
    }>;
  };
};

export type CreateNewSystemsVariables = Exact<{
  input: ReadonlyArray<CreateSystemInputType> | CreateSystemInputType;
}>;

export type CreateNewSystems = {
  readonly __typename?: "Mutation";
  readonly createSystem: ReadonlyArray<string>;
};

export type CreatePricingVariables = Exact<{
  name: Scalars["String"];
  labels: ReadonlyArray<LabelInputType> | LabelInputType;
  systemId?: InputMaybe<Scalars["String"]>;
}>;

export type CreatePricing = {
  readonly __typename?: "Mutation";
  readonly createPricing: {
    readonly __typename?: "CreatePricingMutationResultType";
    readonly id: string;
    readonly pricingId: string;
    readonly revisionNo: number;
    readonly pricingNo: number;
    readonly cachePatches: ReadonlyArray<
      | {
          readonly __typename: "DeleteEntityCachePatchType";
          readonly deletedId: string;
          readonly typename: string;
        }
      | {
          readonly __typename: "InsertElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly index: number;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | {
          readonly __typename: "InvalidateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
        }
      | {
          readonly __typename: "RemoveEntityElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityId: string;
        }
      | {
          readonly __typename: "UpdateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
    >;
  };
};

export type DeleteAmountFieldFormatVariables = Exact<{
  amountFieldFormat: DeleteAmountFieldFormatInputType;
}>;

export type DeleteAmountFieldFormat = {
  readonly __typename?: "Mutation";
  readonly deleteAmountFieldFormat: {
    readonly __typename?: "AmountFieldProfileType";
    readonly id: string;
    readonly name: string;
    readonly measureSystem: number;
    readonly isEditable: boolean;
    readonly amountFieldFormats: ReadonlyArray<{
      readonly __typename?: "AmountFieldFormatType";
      readonly id: string;
      readonly fieldGroup: string;
      readonly fieldName: string;
      readonly measureSystem: number;
      readonly unit: string;
      readonly decimalCount: number;
    }>;
  };
};

export type DeleteComponentVariables = Exact<{
  input: DeleteComponentInputType;
}>;

export type DeleteComponent = {
  readonly __typename?: "Mutation";
  readonly deleteComponent: {
    readonly __typename?: "DeleteComponentMutationResultType";
    readonly id: string;
    readonly cachePatches: ReadonlyArray<
      | {
          readonly __typename: "DeleteEntityCachePatchType";
          readonly deletedId: string;
          readonly typename: string;
        }
      | {
          readonly __typename: "InsertElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly index: number;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | {
          readonly __typename: "InvalidateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
        }
      | {
          readonly __typename: "RemoveEntityElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityId: string;
        }
      | {
          readonly __typename: "UpdateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
    >;
  };
};

export type DeleteLabelVariables = Exact<{
  labelId: Scalars["ID"];
}>;

export type DeleteLabel = {
  readonly __typename?: "Mutation";
  readonly deleteLabel: {
    readonly __typename?: "UserProfileType";
    readonly id: string;
    readonly labels: ReadonlyArray<{
      readonly __typename?: "LabelType";
      readonly id: string;
      readonly name: string;
      readonly userProfileId: string;
    }>;
  };
};

export type DeleteMCompareComponentVariables = Exact<{
  input: DeleteMCompareComponentInputType;
}>;

export type DeleteMCompareComponent = {
  readonly __typename?: "Mutation";
  readonly deleteMCompareComponent: {
    readonly __typename?: "DeleteMCompareComponentMutationResultType";
    readonly id: string;
    readonly cachePatches: ReadonlyArray<
      | {
          readonly __typename: "DeleteEntityCachePatchType";
          readonly deletedId: string;
          readonly typename: string;
        }
      | {
          readonly __typename: "InsertElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly index: number;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | {
          readonly __typename: "InvalidateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
        }
      | {
          readonly __typename: "RemoveEntityElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityId: string;
        }
      | {
          readonly __typename: "UpdateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
    >;
  };
};

export type DeleteMoistureLoadFilesVariables = Exact<{
  moistureLoadFileIds: ReadonlyArray<Scalars["ID"]> | Scalars["ID"];
}>;

export type DeleteMoistureLoadFiles = {
  readonly __typename?: "Mutation";
  readonly deleteMoistureLoadFiles: {
    readonly __typename?: "DeletetionMutationResultType";
    readonly errorMessage: string;
    readonly deletionSuccessful: boolean;
  };
};

export type DeletePricingFilesVariables = Exact<{
  pricingFileIds: ReadonlyArray<Scalars["ID"]> | Scalars["ID"];
}>;

export type DeletePricingFiles = {
  readonly __typename?: "Mutation";
  readonly deletePricingFiles: boolean;
};

export type DeleteSystemFileVariables = Exact<{
  systemFileIds: ReadonlyArray<Scalars["ID"]> | Scalars["ID"];
}>;

export type DeleteSystemFile = {
  readonly __typename?: "Mutation";
  readonly deleteSystemFile: {
    readonly __typename?: "DeleteSystemFileType";
    readonly errorMessage: string;
    readonly deletionSuccessful: boolean;
  };
};

export type EditRevisionCommentVariables = Exact<{
  systemId: Scalars["ID"];
  comment?: InputMaybe<Scalars["String"]>;
}>;

export type EditRevisionComment = {
  readonly __typename?: "Mutation";
  readonly editRevisionComment: {
    readonly __typename?: "SystemItemType";
    readonly id: string;
    readonly revisionNo: number;
    readonly status: number;
    readonly statusDate: number;
    readonly comment?: string | null;
    readonly owner: {
      readonly __typename?: "UserInfoType";
      readonly id: string;
      readonly name: string;
      readonly userName: string;
    };
    readonly systemFile: {
      readonly __typename?: "SystemFileType";
      readonly id: string;
      readonly name: string;
      readonly genesysNo: number;
      readonly systemTypeId: string;
      readonly isDeleted: boolean;
      readonly owner: {
        readonly __typename?: "UserInfoType";
        readonly id: string;
        readonly name: string;
        readonly userName: string;
      };
      readonly labels: ReadonlyArray<{
        readonly __typename?: "LabelType";
        readonly id: string;
        readonly name: string;
        readonly userProfileId: string;
      }>;
      readonly systems: ReadonlyArray<{
        readonly __typename?: "SystemItemType";
        readonly id: string;
        readonly revisionNo: number;
        readonly status: number;
        readonly statusDate: number;
        readonly comment?: string | null;
        readonly owner: {
          readonly __typename?: "UserInfoType";
          readonly id: string;
          readonly name: string;
          readonly userName: string;
        };
      }>;
    };
  };
};

export type ExchangeComponentVariables = Exact<{
  input: ExchangeComponentInputType;
}>;

export type ExchangeComponent = {
  readonly __typename?: "Mutation";
  readonly exchangeComponent: {
    readonly __typename?: "ExchangeComponentMutationResultType";
    readonly id: string;
    readonly cachePatches: ReadonlyArray<
      | {
          readonly __typename: "DeleteEntityCachePatchType";
          readonly deletedId: string;
          readonly typename: string;
        }
      | {
          readonly __typename: "InsertElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly index: number;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | {
          readonly __typename: "InvalidateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
        }
      | {
          readonly __typename: "RemoveEntityElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityId: string;
        }
      | {
          readonly __typename: "UpdateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
    >;
  };
};

export type LockMoistureLoadVariables = Exact<{
  moistureLoadId: Scalars["ID"];
}>;

export type LockMoistureLoad = {
  readonly __typename?: "Mutation";
  readonly lockMoistureLoad: {
    readonly __typename?: "UpdateMoistureLoadMutationType";
    readonly id: string;
  };
};

export type MoveComponentVariables = Exact<{
  input: MoveComponentInputType;
}>;

export type MoveComponent = {
  readonly __typename?: "Mutation";
  readonly moveComponent: {
    readonly __typename?: "MoveComponentMutationResultType";
    readonly id: string;
    readonly cachePatches: ReadonlyArray<
      | {
          readonly __typename: "DeleteEntityCachePatchType";
          readonly deletedId: string;
          readonly typename: string;
        }
      | {
          readonly __typename: "InsertElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly index: number;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | {
          readonly __typename: "InvalidateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
        }
      | {
          readonly __typename: "RemoveEntityElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityId: string;
        }
      | {
          readonly __typename: "UpdateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
    >;
  };
};

export type RecoverSystemFileMutationVariables = Exact<{
  systemFileId: Scalars["ID"];
}>;

export type RecoverSystemFileMutation = {
  readonly __typename?: "Mutation";
  readonly recoverSystemFile: {
    readonly __typename?: "SystemFileType";
    readonly id: string;
    readonly name: string;
    readonly genesysNo: number;
    readonly systemTypeId: string;
    readonly isDeleted: boolean;
    readonly owner: {
      readonly __typename?: "UserInfoType";
      readonly id: string;
      readonly name: string;
      readonly userName: string;
    };
    readonly labels: ReadonlyArray<{
      readonly __typename?: "LabelType";
      readonly id: string;
      readonly name: string;
      readonly userProfileId: string;
    }>;
    readonly systems: ReadonlyArray<{
      readonly __typename?: "SystemItemType";
      readonly id: string;
      readonly revisionNo: number;
      readonly status: number;
      readonly statusDate: number;
      readonly comment?: string | null;
      readonly owner: {
        readonly __typename?: "UserInfoType";
        readonly id: string;
        readonly name: string;
        readonly userName: string;
      };
    }>;
  };
};

export type ResetUserSettingsVariables = Exact<{ [key: string]: never }>;

export type ResetUserSettings = {
  readonly __typename?: "Mutation";
  readonly resetUserSettings: {
    readonly __typename?: "UserSettingsType";
    readonly id: string;
    readonly amountFieldProfiles: ReadonlyArray<{
      readonly __typename?: "AmountFieldProfileType";
      readonly id: string;
      readonly name: string;
      readonly measureSystem: number;
      readonly isEditable: boolean;
      readonly amountFieldFormats: ReadonlyArray<{
        readonly __typename?: "AmountFieldFormatType";
        readonly id: string;
        readonly fieldGroup: string;
        readonly fieldName: string;
        readonly measureSystem: number;
        readonly unit: string;
        readonly decimalCount: number;
      }>;
    }>;
    readonly culture: {
      readonly __typename?: "UserSettingsCultureType";
      readonly id: string;
      readonly locale?: {
        readonly __typename?: "LocaleType";
        readonly id: string;
        readonly name: string;
      } | null;
      readonly language?: {
        readonly __typename?: "LanguageType";
        readonly id: string;
        readonly texts: any;
      } | null;
      readonly currency?: {
        readonly __typename?: "CurrencyType";
        readonly id: string;
      } | null;
    };
    readonly sales: {
      readonly __typename?: "UserSettingsSalesType";
      readonly id: string;
      readonly defaultSalesOrganisation?: string | null;
    };
    readonly selectedAmountFieldProfile: {
      readonly __typename?: "AmountFieldProfileType";
      readonly id: string;
      readonly name: string;
      readonly measureSystem: number;
      readonly isEditable: boolean;
      readonly amountFieldFormats: ReadonlyArray<{
        readonly __typename?: "AmountFieldFormatType";
        readonly id: string;
        readonly fieldGroup: string;
        readonly fieldName: string;
        readonly measureSystem: number;
        readonly unit: string;
        readonly decimalCount: number;
      }>;
    };
  };
};

export type SaveAmountFieldFormatVariables = Exact<{
  amountFieldFormat: AmountFieldFormatInputType;
}>;

export type SaveAmountFieldFormat = {
  readonly __typename?: "Mutation";
  readonly saveAmountFieldFormat: {
    readonly __typename?: "AmountFieldProfileType";
    readonly id: string;
    readonly name: string;
    readonly measureSystem: number;
    readonly isEditable: boolean;
    readonly amountFieldFormats: ReadonlyArray<{
      readonly __typename?: "AmountFieldFormatType";
      readonly id: string;
      readonly fieldGroup: string;
      readonly fieldName: string;
      readonly measureSystem: number;
      readonly unit: string;
      readonly decimalCount: number;
    }>;
  };
};

export type SaveBinSelectionAndCasesVariables = Exact<{
  input: SaveBinSelectionsAndCasesInputType;
}>;

export type SaveBinSelectionAndCases = {
  readonly __typename?: "Mutation";
  readonly saveBinSelectionsAndCases: {
    readonly __typename?: "SaveBinSelectionsAndCasesMutationResultType";
    readonly id: string;
    readonly cachePatches: ReadonlyArray<
      | {
          readonly __typename: "DeleteEntityCachePatchType";
          readonly deletedId: string;
          readonly typename: string;
        }
      | {
          readonly __typename: "InsertElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly index: number;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | {
          readonly __typename: "InvalidateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
        }
      | {
          readonly __typename: "RemoveEntityElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityId: string;
        }
      | {
          readonly __typename: "UpdateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
    >;
  };
};

export type UpdateComponentPropertiesVariables = Exact<{
  input: UpdateComponentPropertiesInputType;
}>;

export type UpdateComponentProperties = {
  readonly __typename?: "Mutation";
  readonly updateComponentProperties: {
    readonly __typename?: "UpdateComponentPropertiesMutationResultType";
    readonly id: string;
    readonly cachePatches: ReadonlyArray<
      | {
          readonly __typename: "DeleteEntityCachePatchType";
          readonly deletedId: string;
          readonly typename: string;
        }
      | {
          readonly __typename: "InsertElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly index: number;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | {
          readonly __typename: "InvalidateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
        }
      | {
          readonly __typename: "RemoveEntityElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityId: string;
        }
      | {
          readonly __typename: "UpdateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
    >;
  };
};

export type SaveEnergySettingsVariables = Exact<{
  input: SaveEnergySettingsInputType;
}>;

export type SaveEnergySettings = {
  readonly __typename?: "Mutation";
  readonly saveEnergySettings: {
    readonly __typename?: "SaveEnergySettingsMutationResultType";
    readonly id: string;
    readonly cachePatches: ReadonlyArray<
      | {
          readonly __typename: "DeleteEntityCachePatchType";
          readonly deletedId: string;
          readonly typename: string;
        }
      | {
          readonly __typename: "InsertElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly index: number;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | {
          readonly __typename: "InvalidateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
        }
      | {
          readonly __typename: "RemoveEntityElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityId: string;
        }
      | {
          readonly __typename: "UpdateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
    >;
  };
};

export type SaveMoistureLoadInputsAndCalculateVariables = Exact<{
  moistureLoadinputs: MoistureLoadInputType;
  moistureLoadId: Scalars["ID"];
}>;

export type SaveMoistureLoadInputsAndCalculate = {
  readonly __typename?: "Mutation";
  readonly saveMoistureLoadInputsAndCalculate: {
    readonly __typename?: "MoistureLoadResponseType";
    readonly logTraceId?: string | null;
    readonly success: boolean;
    readonly moistureLoadSettingsErrors: ReadonlyArray<{
      readonly __typename?: "MoistureLoadSettingsErrorsType";
      readonly resultGroup: string;
      readonly moistureLoadSettings: string;
      readonly errors: ReadonlyArray<string>;
    }>;
    readonly result?: {
      readonly __typename?: "MoistureLoadResultType";
      readonly allBinResults: ReadonlyArray<string>;
      readonly diagramResults: ReadonlyArray<string>;
      readonly diagramResultsToShow: ReadonlyArray<string>;
      readonly summaryAndMonthlyResultsToShow: ReadonlyArray<string>;
      readonly resultWarnings: {
        readonly __typename?: "ResultWarningAndErrorsType";
        readonly diagramWarnings: ReadonlyArray<{
          readonly __typename?: "DiagramWarningType";
          readonly warning: RoomWarningEnum;
          readonly plainTextResultNames: ReadonlyArray<string>;
        }>;
        readonly summaryWarnings: ReadonlyArray<{
          readonly __typename?: "SummaryWarningType";
          readonly warning: RoomWarningEnum;
          readonly localizedResultNames: ReadonlyArray<{
            readonly __typename?: "ResultName";
            readonly name: string;
            readonly nameResourceString: string;
          }>;
        }>;
      };
      readonly monthlyResults: ReadonlyArray<{
        readonly __typename?: "NamedResultType";
        readonly propertyValueSet: string;
        readonly resultName: {
          readonly __typename?: "ResultName";
          readonly name: string;
          readonly nameResourceString: string;
        };
      }>;
      readonly summaryResults: ReadonlyArray<{
        readonly __typename?: "NamedResultType";
        readonly propertyValueSet: string;
        readonly resultName: {
          readonly __typename?: "ResultName";
          readonly name: string;
          readonly nameResourceString: string;
        };
      }>;
      readonly maxLoadResults: ReadonlyArray<{
        readonly __typename?: "CaseMaxLoadResultType";
        readonly id: string;
        readonly selectedFlowId?: number | null;
        readonly caseType: MaxLoadTypeEnum;
        readonly needResourceString: string;
        readonly caseName: string;
        readonly usedRoomControlSettings: string;
        readonly operatingCaseResults: string;
        readonly roomWarnings: ReadonlyArray<RoomWarningEnum>;
        readonly flowSuggestions: ReadonlyArray<{
          readonly __typename?: "CaseFlowSuggestionType";
          readonly id: number;
          readonly flowSourceId: FlowSourceEnum;
          readonly isValid: boolean;
          readonly result: string;
        }>;
      }>;
    } | null;
  };
};

export type SaveMoistureLoadInputsVariables = Exact<{
  moistureLoadinputs: MoistureLoadCalcInputType;
  moistureLoadId: Scalars["ID"];
}>;

export type SaveMoistureLoadInputs = {
  readonly __typename?: "Mutation";
  readonly saveMoistureLoadInputs: {
    readonly __typename?: "UpdateMoistureLoadInputMutationResultType";
    readonly id: string;
  };
};

export type SaveOperatingCasesVariables = Exact<{
  input: OperatingCasesInputType;
}>;

export type SaveOperatingCases = {
  readonly __typename?: "Mutation";
  readonly saveOperatingCases: {
    readonly __typename?: "SaveOperatingCasesMutationResultType";
    readonly id: string;
    readonly cachePatches: ReadonlyArray<
      | {
          readonly __typename: "DeleteEntityCachePatchType";
          readonly deletedId: string;
          readonly typename: string;
        }
      | {
          readonly __typename: "InsertElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly index: number;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | {
          readonly __typename: "InvalidateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
        }
      | {
          readonly __typename: "RemoveEntityElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityId: string;
        }
      | {
          readonly __typename: "UpdateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | null
    >;
  };
};

export type SetActiveAmountProfileVariables = Exact<{
  amountProfileId: Scalars["String"];
}>;

export type SetActiveAmountProfile = {
  readonly __typename?: "Mutation";
  readonly setActiveAmountProfile: {
    readonly __typename?: "UserSettingsType";
    readonly id: string;
    readonly selectedAmountFieldProfile: {
      readonly __typename?: "AmountFieldProfileType";
      readonly id: string;
      readonly name: string;
      readonly measureSystem: number;
      readonly isEditable: boolean;
      readonly amountFieldFormats: ReadonlyArray<{
        readonly __typename?: "AmountFieldFormatType";
        readonly id: string;
        readonly fieldGroup: string;
        readonly fieldName: string;
        readonly measureSystem: number;
        readonly unit: string;
        readonly decimalCount: number;
      }>;
    };
  };
};

export type SetAmountProfileVariables = Exact<{
  amountProfile: AmountProfileInputType;
}>;

export type SetAmountProfile = {
  readonly __typename?: "Mutation";
  readonly setAmountProfileSettings: {
    readonly __typename?: "UserSettingsType";
    readonly id: string;
    readonly amountFieldProfiles: ReadonlyArray<{
      readonly __typename?: "AmountFieldProfileType";
      readonly id: string;
      readonly name: string;
      readonly measureSystem: number;
      readonly isEditable: boolean;
      readonly amountFieldFormats: ReadonlyArray<{
        readonly __typename?: "AmountFieldFormatType";
        readonly id: string;
        readonly fieldGroup: string;
        readonly fieldName: string;
        readonly measureSystem: number;
        readonly unit: string;
        readonly decimalCount: number;
      }>;
    }>;
  };
};

export type SetInitialSettingsVariables = Exact<{
  amountProfileId: Scalars["String"];
  userSettingsCulture: UserSettingsCultureInputType;
  userSettingsClimate: UserSettingsClimateInputType;
}>;

export type SetInitialSettings = {
  readonly __typename?: "Mutation";
  readonly setInitialSettings: {
    readonly __typename?: "UserSettingsType";
    readonly id: string;
    readonly amountFieldProfiles: ReadonlyArray<{
      readonly __typename?: "AmountFieldProfileType";
      readonly id: string;
      readonly name: string;
      readonly measureSystem: number;
      readonly isEditable: boolean;
      readonly amountFieldFormats: ReadonlyArray<{
        readonly __typename?: "AmountFieldFormatType";
        readonly id: string;
        readonly fieldGroup: string;
        readonly fieldName: string;
        readonly measureSystem: number;
        readonly unit: string;
        readonly decimalCount: number;
      }>;
    }>;
    readonly culture: {
      readonly __typename?: "UserSettingsCultureType";
      readonly id: string;
      readonly locale?: {
        readonly __typename?: "LocaleType";
        readonly id: string;
        readonly name: string;
      } | null;
      readonly language?: {
        readonly __typename?: "LanguageType";
        readonly id: string;
        readonly texts: any;
      } | null;
      readonly currency?: {
        readonly __typename?: "CurrencyType";
        readonly id: string;
      } | null;
    };
    readonly sales: {
      readonly __typename?: "UserSettingsSalesType";
      readonly id: string;
      readonly defaultSalesOrganisation?: string | null;
    };
    readonly selectedAmountFieldProfile: {
      readonly __typename?: "AmountFieldProfileType";
      readonly id: string;
      readonly name: string;
      readonly measureSystem: number;
      readonly isEditable: boolean;
      readonly amountFieldFormats: ReadonlyArray<{
        readonly __typename?: "AmountFieldFormatType";
        readonly id: string;
        readonly fieldGroup: string;
        readonly fieldName: string;
        readonly measureSystem: number;
        readonly unit: string;
        readonly decimalCount: number;
      }>;
    };
  };
};

export type SetMoistureLoadAclMutationVariables = Exact<{
  input: UpdateMoistureLoadFileAccessInputType;
}>;

export type SetMoistureLoadAclMutation = {
  readonly __typename?: "Mutation";
  readonly setMoistureLoadACL: {
    readonly __typename?: "UpdateMoistureLoadMutationType";
    readonly id: string;
    readonly errorMessage?: string | null;
  };
};

export type SetSelectedLoadCaseFlowVariables = Exact<{
  moistureLoadId: Scalars["ID"];
  caseId: Scalars["ID"];
  flowId: Scalars["Int"];
}>;

export type SetSelectedLoadCaseFlow = {
  readonly __typename?: "Mutation";
  readonly setSelectedLoadCaseFlow: {
    readonly __typename?: "UpdateMoistureLoadMutationType";
    readonly id: string;
  };
};

export type SetSystemMoistureLoadIdVariables = Exact<{
  moistureLoadId?: InputMaybe<Scalars["ID"]>;
  systemId: Scalars["ID"];
}>;

export type SetSystemMoistureLoadId = {
  readonly __typename?: "Mutation";
  readonly setSystemMoistureLoadId: boolean;
};

export type SetSystemPriceInformationVariables = Exact<{
  input: SetSystemPriceInformationInputType;
}>;

export type SetSystemPriceInformation = {
  readonly __typename?: "Mutation";
  readonly setSystemPriceInformation: {
    readonly __typename?: "SetSystemPriceInformationMutationResultType";
    readonly id: string;
    readonly cachePatches: ReadonlyArray<
      | {
          readonly __typename: "DeleteEntityCachePatchType";
          readonly deletedId: string;
          readonly typename: string;
        }
      | {
          readonly __typename: "InsertElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly index: number;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | {
          readonly __typename: "InvalidateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
        }
      | {
          readonly __typename: "RemoveEntityElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityId: string;
        }
      | {
          readonly __typename: "UpdateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
    >;
  };
};

export type SetUserSettingsClimateVariables = Exact<{
  userSettingsClimate: UserSettingsClimateInputType;
}>;

export type SetUserSettingsClimate = {
  readonly __typename?: "Mutation";
  readonly setUserSettingsClimate: {
    readonly __typename?: "UserSettingsType";
    readonly id: string;
    readonly climate: {
      readonly __typename?: "UserSettingsClimateType";
      readonly id: string;
      readonly heatingDataType?: string | null;
      readonly coolingDataType?: string | null;
      readonly heatingOccurence?: string | null;
      readonly coolingOccurence?: string | null;
      readonly climateDataSource?: string | null;
      readonly manualData?: string | null;
      readonly country?: {
        readonly __typename?: "CountryType";
        readonly id: string;
      } | null;
      readonly region?: {
        readonly __typename?: "RegionType";
        readonly id: string;
      } | null;
      readonly location?: {
        readonly __typename?: "LocationType";
        readonly id: string;
      } | null;
    };
  };
};

export type SetUserSettingsCultureVariables = Exact<{
  userSettingsCulture: UserSettingsCultureInputType;
}>;

export type SetUserSettingsCulture = {
  readonly __typename?: "Mutation";
  readonly setUserSettingsCulture: {
    readonly __typename?: "UserSettingsType";
    readonly id: string;
    readonly culture: {
      readonly __typename?: "UserSettingsCultureType";
      readonly id: string;
      readonly locale?: {
        readonly __typename?: "LocaleType";
        readonly id: string;
        readonly name: string;
      } | null;
      readonly language?: {
        readonly __typename?: "LanguageType";
        readonly id: string;
        readonly texts: any;
      } | null;
      readonly currency?: {
        readonly __typename?: "CurrencyType";
        readonly id: string;
      } | null;
    };
  };
};

export type SetUserSettingsPsychrometricChartVariables = Exact<{
  newsettings: UserSettingsPsychrometricChartInputType;
}>;

export type SetUserSettingsPsychrometricChart = {
  readonly __typename?: "Mutation";
  readonly setUserSettingsPsychrometricChart: {
    readonly __typename?: "UserSettingsType";
    readonly id: string;
  };
};

export type SetUserSettingsSalesVariables = Exact<{
  userSettingsSales: UserSettingsSalesInputType;
}>;

export type SetUserSettingsSales = {
  readonly __typename?: "Mutation";
  readonly setUserSettingsSales: {
    readonly __typename?: "UserSettingsType";
    readonly id: string;
    readonly sales: {
      readonly __typename?: "UserSettingsSalesType";
      readonly id: string;
      readonly defaultSalesOrganisation?: string | null;
    };
  };
};

export type SetUserSettingsSystemsSummaryVariables = Exact<{
  newsettings: UserSettingsSystemsSummaryInputType;
}>;

export type SetUserSettingsSystemsSummary = {
  readonly __typename?: "Mutation";
  readonly setUserSettingsSystemsSummary: {
    readonly __typename?: "UserSettingsType";
    readonly id: string;
  };
};

export type SetUserTermsAndConditionsStatusVariables = Exact<{
  hasAccepted: Scalars["Boolean"];
}>;

export type SetUserTermsAndConditionsStatus = {
  readonly __typename?: "Mutation";
  readonly setUserTermsAndConditionsStatus: {
    readonly __typename?: "UserProfileType";
    readonly id: string;
  };
};

export type ToggleFavoriteSystemVariables = Exact<{
  systemId: Scalars["String"];
}>;

export type ToggleFavoriteSystem = {
  readonly __typename?: "Mutation";
  readonly toggleFavoriteSystem: ReadonlyArray<string | null>;
};

export type ToggleReportFor5ystemTypeVariables = Exact<{
  reportInfo: ReportInputType;
}>;

export type ToggleReportFor5ystemType = {
  readonly __typename?: "Mutation";
  readonly toggleReportsForsystemType: {
    readonly __typename?: "UserSettingsType";
    readonly id: string;
    readonly reportSettings: ReadonlyArray<{
      readonly __typename?: "ReportSettingsType";
      readonly ids: ReadonlyArray<string>;
      readonly systemType: string;
    }>;
  };
};

export type UpdateComponentAccessoriesVariables = Exact<{
  input: UpdateComponentAccessoriesInputType;
}>;

export type UpdateComponentAccessories = {
  readonly __typename?: "Mutation";
  readonly updateComponentAccessories: {
    readonly __typename?: "UpdateComponentAccessoriesMutationResultType";
    readonly id: string;
    readonly cachePatches: ReadonlyArray<
      | {
          readonly __typename: "DeleteEntityCachePatchType";
          readonly deletedId: string;
          readonly typename: string;
        }
      | {
          readonly __typename: "InsertElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly index: number;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | {
          readonly __typename: "InvalidateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
        }
      | {
          readonly __typename: "RemoveEntityElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityId: string;
        }
      | {
          readonly __typename: "UpdateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
    >;
  };
};

export type UpdateComponentNotesVariables = Exact<{
  input: UpdateComponentNotesInputType;
}>;

export type UpdateComponentNotes = {
  readonly __typename?: "Mutation";
  readonly updateComponentNotes: {
    readonly __typename?: "UpdateComponentNotesMutationResultType";
    readonly id: string;
    readonly cachePatches: ReadonlyArray<
      | {
          readonly __typename: "DeleteEntityCachePatchType";
          readonly deletedId: string;
          readonly typename: string;
        }
      | {
          readonly __typename: "InsertElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly index: number;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | {
          readonly __typename: "InvalidateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
        }
      | {
          readonly __typename: "RemoveEntityElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityId: string;
        }
      | {
          readonly __typename: "UpdateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
    >;
  };
};

export type UpdateLabelVariables = Exact<{
  label: LabelInputType;
}>;

export type UpdateLabel = {
  readonly __typename?: "Mutation";
  readonly updateLabel: {
    readonly __typename?: "LabelType";
    readonly id: string;
    readonly name: string;
  };
};

export type UpdateLastOpenedSystemsVariables = Exact<{
  systemId: Scalars["String"];
}>;

export type UpdateLastOpenedSystems = {
  readonly __typename?: "Mutation";
  readonly updateLastOpenedSystems: {
    readonly __typename?: "UpdateLastOpenedSystemsMutationResultType";
    readonly id: string;
    readonly cachePatches: ReadonlyArray<
      | {
          readonly __typename: "DeleteEntityCachePatchType";
          readonly deletedId: string;
          readonly typename: string;
        }
      | {
          readonly __typename: "InsertElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly index: number;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | {
          readonly __typename: "InvalidateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
        }
      | {
          readonly __typename: "RemoveEntityElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityId: string;
        }
      | {
          readonly __typename: "UpdateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
    >;
  };
};

export type UpdateMoistureLoadFileVariables = Exact<{
  input: UpdateMoistureLoadFileInputType;
}>;

export type UpdateMoistureLoadFile = {
  readonly __typename?: "Mutation";
  readonly updateMoistureLoadFile: {
    readonly __typename?: "UpdateMoistureLoadMutationType";
    readonly id: string;
    readonly errorMessage?: string | null;
  };
};

export type UpdatePricingFileVariables = Exact<{
  pricingFile: PricingFileInputType;
}>;

export type UpdatePricingFile = {
  readonly __typename?: "Mutation";
  readonly updatePricingFile:
    | {
        readonly __typename: "PricingFileType";
        readonly id: string;
        readonly name: string;
        readonly pricingNo: number;
        readonly owner: {
          readonly __typename?: "UserInfoType";
          readonly id: string;
          readonly name: string;
          readonly userName: string;
        };
        readonly latestPricing: {
          readonly __typename?: "PricingType";
          readonly id: string;
          readonly name: string;
          readonly comment?: string | null;
          readonly status: PricingStatus;
          readonly statusDate: number;
          readonly revisionNo: number;
          readonly masterMode: boolean;
          readonly currencyCode: string;
          readonly salesOrganisationId?: string | null;
          readonly masterSalesOrganisationId?: string | null;
          readonly exchangeRateTemplateId?: string | null;
          readonly owner: {
            readonly __typename?: "UserInfoType";
            readonly id: string;
            readonly name: string;
            readonly userName: string;
          };
          readonly pricingRows: ReadonlyArray<{
            readonly __typename?: "PricingRowType";
            readonly id: string;
            readonly articleNo: string;
            readonly description: string;
            readonly transferPricePerUnit?: number | null;
            readonly masterPricePerUnit?: number | null;
            readonly systemId?: string | null;
            readonly rowNo: number;
            readonly type: string;
            readonly visualizerCode: string;
            readonly quantity: number;
            readonly unit: string;
            readonly costPerUnit?: number | null;
            readonly pid?: string | null;
          }>;
          readonly pricingFile: {
            readonly __typename?: "PricingFileType";
            readonly id: string;
            readonly name: string;
            readonly pricingNo: number;
            readonly labels: ReadonlyArray<{
              readonly __typename?: "LabelType";
              readonly id: string;
              readonly name: string;
              readonly userProfileId: string;
            }>;
          };
        };
        readonly pricings: ReadonlyArray<{
          readonly __typename?: "PricingType";
          readonly id: string;
          readonly name: string;
          readonly comment?: string | null;
          readonly status: PricingStatus;
          readonly statusDate: number;
          readonly revisionNo: number;
          readonly masterMode: boolean;
          readonly currencyCode: string;
          readonly salesOrganisationId?: string | null;
          readonly masterSalesOrganisationId?: string | null;
          readonly exchangeRateTemplateId?: string | null;
          readonly owner: {
            readonly __typename?: "UserInfoType";
            readonly id: string;
            readonly name: string;
            readonly userName: string;
          };
          readonly pricingRows: ReadonlyArray<{
            readonly __typename?: "PricingRowType";
            readonly id: string;
            readonly articleNo: string;
            readonly description: string;
            readonly transferPricePerUnit?: number | null;
            readonly masterPricePerUnit?: number | null;
            readonly systemId?: string | null;
            readonly rowNo: number;
            readonly type: string;
            readonly visualizerCode: string;
            readonly quantity: number;
            readonly unit: string;
            readonly costPerUnit?: number | null;
            readonly pid?: string | null;
          }>;
          readonly pricingFile: {
            readonly __typename?: "PricingFileType";
            readonly id: string;
            readonly name: string;
            readonly pricingNo: number;
            readonly labels: ReadonlyArray<{
              readonly __typename?: "LabelType";
              readonly id: string;
              readonly name: string;
              readonly userProfileId: string;
            }>;
          };
        }>;
        readonly labels: ReadonlyArray<{
          readonly __typename?: "LabelType";
          readonly id: string;
          readonly name: string;
          readonly userProfileId: string;
        }>;
      }
    | {
        readonly __typename: "UpdateFailedType";
        readonly errorMessage: string;
      };
};

export type UpdatePricingNameVariables = Exact<{
  pricingId: Scalars["ID"];
  name: Scalars["String"];
}>;

export type UpdatePricingName = {
  readonly __typename?: "Mutation";
  readonly updatePricingName: {
    readonly __typename?: "UpdatePricingMutationResultType";
    readonly id: string;
    readonly cachePatches: ReadonlyArray<
      | {
          readonly __typename: "DeleteEntityCachePatchType";
          readonly deletedId: string;
          readonly typename: string;
        }
      | {
          readonly __typename: "InsertElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly index: number;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | {
          readonly __typename: "InvalidateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
        }
      | {
          readonly __typename: "RemoveEntityElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityId: string;
        }
      | {
          readonly __typename: "UpdateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
    >;
  };
};

export type UpdatePricingVariables = Exact<{
  pricing: PricingInputType;
}>;

export type UpdatePricing = {
  readonly __typename?: "Mutation";
  readonly updatePricing: {
    readonly __typename?: "UpdatePricingMutationResultType";
    readonly id: string;
    readonly cachePatches: ReadonlyArray<
      | {
          readonly __typename: "DeleteEntityCachePatchType";
          readonly deletedId: string;
          readonly typename: string;
        }
      | {
          readonly __typename: "InsertElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly index: number;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | {
          readonly __typename: "InvalidateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
        }
      | {
          readonly __typename: "RemoveEntityElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityId: string;
        }
      | {
          readonly __typename: "UpdateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
    >;
  };
};

export type UpdateSeenNewsItemMutationVariables = Exact<{
  id: ReadonlyArray<Scalars["Int"]> | Scalars["Int"];
}>;

export type UpdateSeenNewsItemMutation = {
  readonly __typename?: "Mutation";
  readonly updateSeenNewsItem: {
    readonly __typename?: "UserSettingsType";
    readonly id: string;
  };
};

export type UpdateSystemFileVariables = Exact<{
  systemFile: SystemFileInputType;
}>;

export type UpdateSystemFile = {
  readonly __typename?: "Mutation";
  readonly updateSystemFile:
    | {
        readonly __typename: "SystemFileType";
        readonly id: string;
        readonly name: string;
        readonly genesysNo: number;
        readonly systemTypeId: string;
        readonly isDeleted: boolean;
        readonly owner: {
          readonly __typename?: "UserInfoType";
          readonly id: string;
          readonly name: string;
          readonly userName: string;
        };
        readonly labels: ReadonlyArray<{
          readonly __typename?: "LabelType";
          readonly id: string;
          readonly name: string;
          readonly userProfileId: string;
        }>;
        readonly systems: ReadonlyArray<{
          readonly __typename?: "SystemItemType";
          readonly id: string;
          readonly revisionNo: number;
          readonly status: number;
          readonly statusDate: number;
          readonly comment?: string | null;
          readonly owner: {
            readonly __typename?: "UserInfoType";
            readonly id: string;
            readonly name: string;
            readonly userName: string;
          };
        }>;
      }
    | {
        readonly __typename: "UpdateFailedType";
        readonly errorMessage: string;
      };
};

export type UpdateSystemManagerSettingsVariables = Exact<{
  systemManagerSettings: SystemManagerSettingsInputType;
}>;

export type UpdateSystemManagerSettings = {
  readonly __typename?: "Mutation";
  readonly updateSystemManagerSettings: {
    readonly __typename?: "UpdateSystemManagerSettingsMutationResultType";
    readonly id: string;
    readonly cachePatches: ReadonlyArray<
      | {
          readonly __typename: "DeleteEntityCachePatchType";
          readonly deletedId: string;
          readonly typename: string;
        }
      | {
          readonly __typename: "InsertElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly index: number;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | {
          readonly __typename: "InvalidateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
        }
      | {
          readonly __typename: "RemoveEntityElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityId: string;
        }
      | {
          readonly __typename: "UpdateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
    >;
  };
};

export type UpdateWizardSettingsVariables = Exact<{
  wizardSettings: WizardSettingsInputType;
}>;

export type UpdateWizardSettings = {
  readonly __typename?: "Mutation";
  readonly updateWizardSettings: {
    readonly __typename?: "UpdateWizardSettingsMutationResultType";
    readonly id: string;
    readonly cachePatches: ReadonlyArray<
      | {
          readonly __typename: "DeleteEntityCachePatchType";
          readonly deletedId: string;
          readonly typename: string;
        }
      | {
          readonly __typename: "InsertElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly index: number;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
      | {
          readonly __typename: "InvalidateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
        }
      | {
          readonly __typename: "RemoveEntityElementCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityId: string;
        }
      | {
          readonly __typename: "UpdateFieldCachePatchType";
          readonly id?: string | null;
          readonly typename: string;
          readonly fieldName: string;
          readonly entityFieldValue: {
            readonly __typename: "EntityFieldValueType";
            readonly stringValue?: string | null;
            readonly booleanValue?: boolean | null;
            readonly intValue?: number | null;
            readonly floatValue?: number | null;
            readonly entityFieldValueArray?: ReadonlyArray<{
              readonly __typename: "EntityFieldValueType";
              readonly stringValue?: string | null;
              readonly booleanValue?: boolean | null;
              readonly intValue?: number | null;
              readonly floatValue?: number | null;
            } | null> | null;
          };
        }
    >;
  };
};

export type ScreenAmountProfileProviderProductDataVariables = Exact<{
  [key: string]: never;
}>;

export type ScreenAmountProfileProviderProductData = {
  readonly __typename?: "Query";
  readonly product: {
    readonly __typename?: "ProductDataType";
    readonly genesysNumberPrefixes: {
      readonly __typename?: "GenesysNumberPrefixType";
      readonly id: string;
      readonly genesysNo: string;
      readonly pricingNo: string;
    };
    readonly fieldDefaults: ReadonlyArray<{
      readonly __typename?: "FieldDefaultFormatType";
      readonly id: string;
      readonly fieldGroup: string;
      readonly fieldName: string;
      readonly measureSystem: number;
      readonly unit: string;
      readonly decimalCount: number;
    }>;
    readonly quantityDefaults: ReadonlyArray<{
      readonly __typename?: "QuantityDefaultFormatType";
      readonly id: string;
      readonly measureSystem: number;
      readonly quantity: string;
      readonly unit: string;
      readonly decimalCount: number;
    }>;
  };
};

export type ScreenAmountProfileProviderUserDataVariables = Exact<{
  [key: string]: never;
}>;

export type ScreenAmountProfileProviderUserData = {
  readonly __typename?: "Query";
  readonly user: {
    readonly __typename?: "UserType";
    readonly userProfile: {
      readonly __typename?: "UserProfileType";
      readonly id: string;
      readonly userSettings: {
        readonly __typename?: "UserSettingsType";
        readonly id: string;
        readonly amountFieldProfiles: ReadonlyArray<{
          readonly __typename?: "AmountFieldProfileType";
          readonly id: string;
          readonly name: string;
          readonly measureSystem: number;
          readonly isEditable: boolean;
          readonly amountFieldFormats: ReadonlyArray<{
            readonly __typename?: "AmountFieldFormatType";
            readonly id: string;
            readonly fieldGroup: string;
            readonly fieldName: string;
            readonly measureSystem: number;
            readonly unit: string;
            readonly decimalCount: number;
          }>;
        }>;
        readonly selectedAmountFieldProfile: {
          readonly __typename?: "AmountFieldProfileType";
          readonly id: string;
          readonly measureSystem: number;
          readonly amountFieldFormats: ReadonlyArray<{
            readonly __typename?: "AmountFieldFormatType";
            readonly id: string;
            readonly fieldGroup: string;
            readonly fieldName: string;
            readonly measureSystem: number;
            readonly unit: string;
            readonly decimalCount: number;
          }>;
        };
      };
    };
  };
};

export type TranslationQueryVariables = Exact<{
  language: Scalars["String"];
}>;

export type TranslationQuery = {
  readonly __typename?: "Query";
  readonly product: {
    readonly __typename?: "ProductDataType";
    readonly language?: {
      readonly __typename?: "LanguageType";
      readonly id: string;
      readonly texts: any;
    } | null;
  };
};

export type UpdateSystemStatusVariables = Exact<{
  input: UpdateSystemInputType;
}>;

export type UpdateSystemStatus = {
  readonly __typename?: "Mutation";
  readonly updateSystem:
    | {
        readonly __typename: "UpdateSystemRejectResultType";
        readonly rejectReason?: string | null;
      }
    | {
        readonly __typename: "UpdateSystemSuccessResultType";
        readonly status: number;
      };
};

export type CalculateEnergyBinCaseVariables = Exact<{
  input: CalculateEnergyBinCaseInputType;
}>;

export type CalculateEnergyBinCase = {
  readonly __typename?: "Mutation";
  readonly calculateEnergyBinCase: boolean;
};

export type CheckSystemStatusQueryVariables = Exact<{
  systemId: Scalars["ID"];
}>;

export type CheckSystemStatusQuery = {
  readonly __typename?: "Query";
  readonly user: {
    readonly __typename?: "UserType";
    readonly system: {
      readonly __typename?: "SystemItemType";
      readonly id: string;
      readonly status: number;
      readonly operatingCases: ReadonlyArray<{
        readonly __typename?: "OperatingCaseType";
        readonly id: string;
        readonly caseType: number;
      }>;
    };
  };
};

export type UserLabelProviderVariables = Exact<{ [key: string]: never }>;

export type UserLabelProvider = {
  readonly __typename?: "Query";
  readonly user: {
    readonly __typename?: "UserType";
    readonly userProfile: {
      readonly __typename?: "UserProfileType";
      readonly id: string;
      readonly labels: ReadonlyArray<{
        readonly __typename?: "LabelType";
        readonly id: string;
        readonly name: string;
      }>;
    };
  };
};

export type UserProfileProviderUserDataVariables = Exact<{
  [key: string]: never;
}>;

export type UserProfileProviderUserData = {
  readonly __typename?: "Query";
  readonly user: {
    readonly __typename?: "UserType";
    readonly userProfile: {
      readonly __typename?: "UserProfileType";
      readonly id: string;
      readonly name: string;
      readonly userName: string;
      readonly lastLogin: number;
      readonly applicationClaims: ReadonlyArray<{
        readonly __typename?: "ApplicationClaimType";
        readonly id: string;
        readonly key: string;
        readonly context: string;
        readonly values: ReadonlyArray<string>;
      }>;
      readonly currencies: ReadonlyArray<{
        readonly __typename?: "CurrencyType";
        readonly id: string;
        readonly name: string;
        readonly signBefore?: string | null;
        readonly signAfter?: string | null;
      }>;
      readonly userSettings: {
        readonly __typename?: "UserSettingsType";
        readonly id: string;
        readonly culture: {
          readonly __typename?: "UserSettingsCultureType";
          readonly id: string;
          readonly locale?: {
            readonly __typename?: "LocaleType";
            readonly id: string;
          } | null;
          readonly language?: {
            readonly __typename?: "LanguageType";
            readonly id: string;
          } | null;
          readonly currency?: {
            readonly __typename?: "CurrencyType";
            readonly id: string;
          } | null;
        };
        readonly climate: {
          readonly __typename?: "UserSettingsClimateType";
          readonly id: string;
          readonly location?: {
            readonly __typename?: "LocationType";
            readonly id: string;
          } | null;
        };
        readonly wizardSettings: {
          readonly __typename?: "WizardSettingsType";
          readonly id: string;
          readonly lastCreatedSystemType?: string | null;
          readonly lastSelectedLabels: ReadonlyArray<string>;
        };
        readonly sales: {
          readonly __typename?: "UserSettingsSalesType";
          readonly id: string;
          readonly defaultSalesOrganisation?: string | null;
        };
      };
      readonly salesOrganisations: ReadonlyArray<{
        readonly __typename?: "SalesOrganisationType";
        readonly id: string;
        readonly name: string;
      }>;
    };
  };
};

export type GetCalculatedPropertiesVariables = Exact<{
  getCalculatedPropertiesInput: GetCalculatedPropertiesInputType;
}>;

export type GetCalculatedProperties = {
  readonly __typename?: "Query";
  readonly user: {
    readonly __typename?: "UserType";
    readonly getCalculatedProperties: string;
  };
};

export type GetCalculatedPropertiesForAccessoryVariables = Exact<{
  getCalculatedPropertiesForAccessoryInput: GetCalculatedPropertiesForAccessoryInputType;
}>;

export type GetCalculatedPropertiesForAccessory = {
  readonly __typename?: "Query";
  readonly user: {
    readonly __typename?: "UserType";
    readonly getCalculatedPropertiesForAccessory: string;
  };
};

type CachePatchFragment_DeleteEntityCachePatchType_ = {
  readonly __typename: "DeleteEntityCachePatchType";
  readonly deletedId: string;
  readonly typename: string;
};

type CachePatchFragment_InsertElementCachePatchType_ = {
  readonly __typename: "InsertElementCachePatchType";
  readonly id?: string | null;
  readonly typename: string;
  readonly fieldName: string;
  readonly index: number;
  readonly entityFieldValue: {
    readonly __typename: "EntityFieldValueType";
    readonly stringValue?: string | null;
    readonly booleanValue?: boolean | null;
    readonly intValue?: number | null;
    readonly floatValue?: number | null;
    readonly entityFieldValueArray?: ReadonlyArray<{
      readonly __typename: "EntityFieldValueType";
      readonly stringValue?: string | null;
      readonly booleanValue?: boolean | null;
      readonly intValue?: number | null;
      readonly floatValue?: number | null;
    } | null> | null;
  };
};

type CachePatchFragment_InvalidateFieldCachePatchType_ = {
  readonly __typename: "InvalidateFieldCachePatchType";
  readonly id?: string | null;
  readonly typename: string;
  readonly fieldName: string;
};

type CachePatchFragment_RemoveEntityElementCachePatchType_ = {
  readonly __typename: "RemoveEntityElementCachePatchType";
  readonly id?: string | null;
  readonly typename: string;
  readonly fieldName: string;
  readonly entityId: string;
};

type CachePatchFragment_UpdateFieldCachePatchType_ = {
  readonly __typename: "UpdateFieldCachePatchType";
  readonly id?: string | null;
  readonly typename: string;
  readonly fieldName: string;
  readonly entityFieldValue: {
    readonly __typename: "EntityFieldValueType";
    readonly stringValue?: string | null;
    readonly booleanValue?: boolean | null;
    readonly intValue?: number | null;
    readonly floatValue?: number | null;
    readonly entityFieldValueArray?: ReadonlyArray<{
      readonly __typename: "EntityFieldValueType";
      readonly stringValue?: string | null;
      readonly booleanValue?: boolean | null;
      readonly intValue?: number | null;
      readonly floatValue?: number | null;
    } | null> | null;
  };
};

export type CachePatchFragment =
  | CachePatchFragment_DeleteEntityCachePatchType_
  | CachePatchFragment_InsertElementCachePatchType_
  | CachePatchFragment_InvalidateFieldCachePatchType_
  | CachePatchFragment_RemoveEntityElementCachePatchType_
  | CachePatchFragment_UpdateFieldCachePatchType_;

export type EntitiFieldValue = {
  readonly __typename: "EntityFieldValueType";
  readonly stringValue?: string | null;
  readonly booleanValue?: boolean | null;
  readonly intValue?: number | null;
  readonly floatValue?: number | null;
  readonly entityFieldValueArray?: ReadonlyArray<{
    readonly __typename: "EntityFieldValueType";
    readonly stringValue?: string | null;
    readonly booleanValue?: boolean | null;
    readonly intValue?: number | null;
    readonly floatValue?: number | null;
  } | null> | null;
};

export type EnergyResultEnergyTotalItem = {
  readonly __typename?: "EnergyTotalItemType";
  readonly id: string;
  readonly name: string;
  readonly sortNo: number;
};

export type EnergyResultEnergyListItem = {
  readonly __typename?: "EnergyListItemType";
  readonly id: string;
  readonly name: string;
  readonly sortNo: number;
};

export type EnergyResultChartPresets = {
  readonly __typename?: "EnergyChartPresetType";
  readonly id: string;
  readonly name: string;
  readonly y1Label: string;
  readonly y1AxisType: string;
  readonly y1PerfParams: ReadonlyArray<string>;
  readonly y1UnitSi: string;
  readonly y1UnitIp: string;
  readonly y2Label: string;
  readonly y2AxisType: string;
  readonly y2PerfParams: ReadonlyArray<string>;
  readonly y2UnitSi: string;
  readonly y2UnitIp: string;
};

export type EnergyResultOperatingCaseResult = {
  readonly __typename?: "OperatingCaseResultType";
  readonly id: string;
  readonly settings?: string | null;
  readonly componentId: string;
};

export type EnergyResultOperatingCase = {
  readonly __typename?: "OperatingCaseType";
  readonly id: string;
  readonly caseType: number;
  readonly binData?: string | null;
  readonly results: ReadonlyArray<{
    readonly __typename?: "OperatingCaseResultType";
    readonly id: string;
    readonly settings?: string | null;
    readonly componentId: string;
  }>;
};

export type EnergyResultComponent = {
  readonly __typename?: "ComponentType";
  readonly id: string;
  readonly productId: string;
  readonly properties?: string | null;
};

export type EnergyResultSystem = {
  readonly __typename?: "SystemItemType";
  readonly id: string;
  readonly binSelections?: string | null;
  readonly revisionNo: number;
  readonly status: number;
  readonly systemFile: {
    readonly __typename?: "SystemFileType";
    readonly id: string;
    readonly name: string;
    readonly genesysNo: number;
    readonly systemTypeId: string;
  };
  readonly components: ReadonlyArray<{
    readonly __typename?: "ComponentType";
    readonly id: string;
    readonly productId: string;
    readonly properties?: string | null;
  }>;
  readonly operatingCases: ReadonlyArray<{
    readonly __typename?: "OperatingCaseType";
    readonly id: string;
    readonly caseType: number;
    readonly binData?: string | null;
    readonly results: ReadonlyArray<{
      readonly __typename?: "OperatingCaseResultType";
      readonly id: string;
      readonly settings?: string | null;
      readonly componentId: string;
    }>;
  }>;
};

export type CompleteEnergyParam = {
  readonly __typename?: "EnergyParamType";
  readonly paramName: string;
  readonly amountFieldValue: {
    readonly __typename?: "AmountFieldValueType";
    readonly amount: string;
    readonly amountField: {
      readonly __typename?: "AmountFieldIdType";
      readonly fieldGroup: string;
      readonly fieldName: string;
    };
  };
};

export type CompleteEnergyCost = {
  readonly __typename?: "EnergyCostGraphType";
  readonly name: string;
  readonly cost: number;
  readonly currencySymbol: string;
};

export type CompleteCoeffResult = {
  readonly __typename?: "CoeffResultType";
  readonly airVelocity: string;
  readonly airflow: string;
  readonly supplierPower: string;
  readonly coilProPower: string;
  readonly diffPower: string;
  readonly diffPowerPercentage: string;
  readonly supplierAirPressureDrop: string;
  readonly coilProAirPressureDrop: string;
  readonly diffAirPressureDrop: string;
  readonly diffAirPressureDropPercentage: string;
};

export type CompleteCoilProHeatingCoeffResult = {
  readonly __typename?: "HeatingCoefficientsResultsType";
  readonly hasError: boolean;
  readonly errorText: string;
  readonly coeffs: {
    readonly __typename?: "CoeffType";
    readonly aDry: number;
    readonly bDry: number;
    readonly cDry: number;
    readonly dDry: number;
    readonly aWet: number;
    readonly bWet: number;
    readonly cWet: number;
    readonly dWet: number;
  };
  readonly heatingResults?: ReadonlyArray<{
    readonly __typename?: "CoeffResultType";
    readonly airVelocity: string;
    readonly airflow: string;
    readonly supplierPower: string;
    readonly coilProPower: string;
    readonly diffPower: string;
    readonly diffPowerPercentage: string;
    readonly supplierAirPressureDrop: string;
    readonly coilProAirPressureDrop: string;
    readonly diffAirPressureDrop: string;
    readonly diffAirPressureDropPercentage: string;
  }> | null;
};

export type CompleteCoilProCoolingCoeffResult = {
  readonly __typename?: "CoolingCoefficientsResultsType";
  readonly hasError: boolean;
  readonly errorText: string;
  readonly coeffs: {
    readonly __typename?: "CoeffType";
    readonly aDry: number;
    readonly bDry: number;
    readonly cDry: number;
    readonly dDry: number;
    readonly aWet: number;
    readonly bWet: number;
    readonly cWet: number;
    readonly dWet: number;
  };
  readonly coolingResults?: ReadonlyArray<{
    readonly __typename?: "CoeffResultType";
    readonly airVelocity: string;
    readonly airflow: string;
    readonly supplierPower: string;
    readonly coilProPower: string;
    readonly diffPower: string;
    readonly diffPowerPercentage: string;
    readonly supplierAirPressureDrop: string;
    readonly coilProAirPressureDrop: string;
    readonly diffAirPressureDrop: string;
    readonly diffAirPressureDropPercentage: string;
  }> | null;
  readonly heatingResults?: ReadonlyArray<{
    readonly __typename?: "CoeffResultType";
    readonly airVelocity: string;
    readonly airflow: string;
    readonly supplierPower: string;
    readonly coilProPower: string;
    readonly diffPower: string;
    readonly diffPowerPercentage: string;
    readonly supplierAirPressureDrop: string;
    readonly coilProAirPressureDrop: string;
    readonly diffAirPressureDrop: string;
    readonly diffAirPressureDropPercentage: string;
  }> | null;
};

export type CompleteCoilProFreonResults = {
  readonly __typename?: "FreonResultsType";
  readonly hasError: boolean;
  readonly errorCode: number;
  readonly errorText: string;
  readonly airPressureDrop: string;
  readonly airPressureDropDry: string;
  readonly airTotalCapacity: string;
  readonly temperatureOut: string;
  readonly humidityOut: string;
  readonly pressureOut: string;
  readonly flow: string;
  readonly airVelocity: string;
  readonly condensedWater: string;
  readonly sensibleTotalCapacityRatio: string;
  readonly marginal: string;
  readonly freonPressureDrop: string;
  readonly freonMassFlow: string;
  readonly freonLiquidVelocity: string;
  readonly freonGasVelocity: string;
  readonly supplierCode: string;
};

export type CompleteCoilProFreonTargetTemperatureResults = {
  readonly __typename?: "FreonTargetTemperatureResultsType";
  readonly targetReached: boolean;
  readonly marginOnTotalPower: string;
  readonly results: {
    readonly __typename?: "FreonResultsType";
    readonly hasError: boolean;
    readonly errorCode: number;
    readonly errorText: string;
    readonly airPressureDrop: string;
    readonly airPressureDropDry: string;
    readonly airTotalCapacity: string;
    readonly temperatureOut: string;
    readonly humidityOut: string;
    readonly pressureOut: string;
    readonly flow: string;
    readonly airVelocity: string;
    readonly condensedWater: string;
    readonly sensibleTotalCapacityRatio: string;
    readonly marginal: string;
    readonly freonPressureDrop: string;
    readonly freonMassFlow: string;
    readonly freonLiquidVelocity: string;
    readonly freonGasVelocity: string;
    readonly supplierCode: string;
  };
};

export type CompleteCoilProSteamResults = {
  readonly __typename?: "SteamResultsType";
  readonly hasError: boolean;
  readonly errorCode: number;
  readonly errorText: string;
  readonly airPressureDropDry: string;
  readonly airTotalHeat: string;
  readonly steamTotalHeat: string;
  readonly totalHeat: string;
  readonly temperatureOut: string;
  readonly humidityOut: string;
  readonly pressureOut: string;
  readonly flow: string;
  readonly airVelocity: string;
  readonly sensibleTotalCapacityRatio: string;
  readonly marginal: string;
  readonly steamMassFlow: string;
  readonly steamVelocity: string;
  readonly supplierCode: string;
};

export type CompleteCoilProSteamTargetTemperatureResults = {
  readonly __typename?: "SteamTargetTemperatureResultsType";
  readonly targetReached: boolean;
  readonly marginOnTotalPower: string;
  readonly results: {
    readonly __typename?: "SteamResultsType";
    readonly hasError: boolean;
    readonly errorCode: number;
    readonly errorText: string;
    readonly airPressureDropDry: string;
    readonly airTotalHeat: string;
    readonly steamTotalHeat: string;
    readonly totalHeat: string;
    readonly temperatureOut: string;
    readonly humidityOut: string;
    readonly pressureOut: string;
    readonly flow: string;
    readonly airVelocity: string;
    readonly sensibleTotalCapacityRatio: string;
    readonly marginal: string;
    readonly steamMassFlow: string;
    readonly steamVelocity: string;
    readonly supplierCode: string;
  };
};

export type CompleteFanProFanResults = {
  readonly __typename?: "FanResultsType";
  readonly hasError: boolean;
  readonly errorCode: number;
  readonly errorText?: string | null;
  readonly version: string;
  readonly airVelocity: string;
  readonly staticEfficiency: string;
  readonly staticPressure: string;
  readonly dynamicPressure: string;
  readonly totalPressure: string;
  readonly totalEfficiency: string;
  readonly totalPowerConsumption: string;
  readonly shaftPower: string;
  readonly soundPowerLevelsInlet: string;
  readonly soundPowerLevelsOutlet: string;
  readonly fanSpeed: string;
  readonly fanMaxSpeed: string;
  readonly airFlowPerFan: string;
  readonly specificFanPower: string;
  readonly temperatureOut: string;
  readonly humidityOut: string;
  readonly massFlow: string;
  readonly current: string;
  readonly cosPhi: string;
};

export type CompleteCoilProFluidResults = {
  readonly __typename?: "FluidResultsType";
  readonly hasError: boolean;
  readonly errorCode: number;
  readonly errorText?: string | null;
  readonly airPressureDrop: string;
  readonly airPressureDropDry: string;
  readonly airTotalCapacity: string;
  readonly temperatureOut: string;
  readonly humidityOut: string;
  readonly pressureOut: string;
  readonly flow: string;
  readonly airVelocity: string;
  readonly condensedWater: string;
  readonly sensibleTotalCapacityRatio: string;
  readonly fluidPressureDrop: string;
  readonly fluidTemperatureIn: string;
  readonly fluidTemperatureOut: string;
  readonly fluidVolumeFlow: string;
  readonly fluidDensity: string;
  readonly fluidVelocity: string;
  readonly reynoldsNumberFluid: string;
  readonly wetRatio: string;
  readonly supplierCode: string;
};

export type CompleteAmountFieldFormat = {
  readonly __typename?: "AmountFieldFormatType";
  readonly id: string;
  readonly fieldGroup: string;
  readonly fieldName: string;
  readonly measureSystem: number;
  readonly unit: string;
  readonly decimalCount: number;
};

export type CompleteAmountFieldProfile = {
  readonly __typename?: "AmountFieldProfileType";
  readonly id: string;
  readonly name: string;
  readonly measureSystem: number;
  readonly isEditable: boolean;
  readonly amountFieldFormats: ReadonlyArray<{
    readonly __typename?: "AmountFieldFormatType";
    readonly id: string;
    readonly fieldGroup: string;
    readonly fieldName: string;
    readonly measureSystem: number;
    readonly unit: string;
    readonly decimalCount: number;
  }>;
};

export type CompleteLocale = {
  readonly __typename?: "LocaleType";
  readonly id: string;
  readonly name: string;
};

export type CompleteLanguage = {
  readonly __typename?: "LanguageType";
  readonly id: string;
  readonly texts: any;
};

export type CompleteCurrency = {
  readonly __typename?: "CurrencyType";
  readonly id: string;
};

export type CompleteUserCultureSettings = {
  readonly __typename?: "UserSettingsCultureType";
  readonly id: string;
  readonly locale?: {
    readonly __typename?: "LocaleType";
    readonly id: string;
    readonly name: string;
  } | null;
  readonly language?: {
    readonly __typename?: "LanguageType";
    readonly id: string;
    readonly texts: any;
  } | null;
  readonly currency?: {
    readonly __typename?: "CurrencyType";
    readonly id: string;
  } | null;
};

export type CompleteUserClimateSettings = {
  readonly __typename?: "UserSettingsClimateType";
  readonly id: string;
  readonly heatingDataType?: string | null;
  readonly coolingDataType?: string | null;
  readonly heatingOccurence?: string | null;
  readonly coolingOccurence?: string | null;
  readonly climateDataSource?: string | null;
  readonly manualData?: string | null;
  readonly country?: {
    readonly __typename?: "CountryType";
    readonly id: string;
  } | null;
  readonly region?: {
    readonly __typename?: "RegionType";
    readonly id: string;
  } | null;
  readonly location?: {
    readonly __typename?: "LocationType";
    readonly id: string;
  } | null;
};

export type CompleteUserSettingsSales = {
  readonly __typename?: "UserSettingsSalesType";
  readonly id: string;
  readonly defaultSalesOrganisation?: string | null;
};

export type CompleteUserSettings = {
  readonly __typename?: "UserSettingsType";
  readonly id: string;
  readonly amountFieldProfiles: ReadonlyArray<{
    readonly __typename?: "AmountFieldProfileType";
    readonly id: string;
    readonly name: string;
    readonly measureSystem: number;
    readonly isEditable: boolean;
    readonly amountFieldFormats: ReadonlyArray<{
      readonly __typename?: "AmountFieldFormatType";
      readonly id: string;
      readonly fieldGroup: string;
      readonly fieldName: string;
      readonly measureSystem: number;
      readonly unit: string;
      readonly decimalCount: number;
    }>;
  }>;
  readonly culture: {
    readonly __typename?: "UserSettingsCultureType";
    readonly id: string;
    readonly locale?: {
      readonly __typename?: "LocaleType";
      readonly id: string;
      readonly name: string;
    } | null;
    readonly language?: {
      readonly __typename?: "LanguageType";
      readonly id: string;
      readonly texts: any;
    } | null;
    readonly currency?: {
      readonly __typename?: "CurrencyType";
      readonly id: string;
    } | null;
  };
  readonly sales: {
    readonly __typename?: "UserSettingsSalesType";
    readonly id: string;
    readonly defaultSalesOrganisation?: string | null;
  };
  readonly selectedAmountFieldProfile: {
    readonly __typename?: "AmountFieldProfileType";
    readonly id: string;
    readonly name: string;
    readonly measureSystem: number;
    readonly isEditable: boolean;
    readonly amountFieldFormats: ReadonlyArray<{
      readonly __typename?: "AmountFieldFormatType";
      readonly id: string;
      readonly fieldGroup: string;
      readonly fieldName: string;
      readonly measureSystem: number;
      readonly unit: string;
      readonly decimalCount: number;
    }>;
  };
};

export type CompleteOwnerInfo = {
  readonly __typename?: "UserInfoType";
  readonly id: string;
  readonly name: string;
  readonly userName: string;
};

export type CompleteLabel = {
  readonly __typename?: "LabelType";
  readonly id: string;
  readonly name: string;
  readonly userProfileId: string;
};

export type CompletePricingRow = {
  readonly __typename?: "PricingRowType";
  readonly id: string;
  readonly articleNo: string;
  readonly description: string;
  readonly transferPricePerUnit?: number | null;
  readonly masterPricePerUnit?: number | null;
  readonly systemId?: string | null;
  readonly rowNo: number;
  readonly type: string;
  readonly visualizerCode: string;
  readonly quantity: number;
  readonly unit: string;
  readonly costPerUnit?: number | null;
  readonly pid?: string | null;
};

export type CompletePricing = {
  readonly __typename?: "PricingType";
  readonly id: string;
  readonly name: string;
  readonly comment?: string | null;
  readonly status: PricingStatus;
  readonly statusDate: number;
  readonly revisionNo: number;
  readonly masterMode: boolean;
  readonly currencyCode: string;
  readonly salesOrganisationId?: string | null;
  readonly masterSalesOrganisationId?: string | null;
  readonly exchangeRateTemplateId?: string | null;
  readonly owner: {
    readonly __typename?: "UserInfoType";
    readonly id: string;
    readonly name: string;
    readonly userName: string;
  };
  readonly pricingRows: ReadonlyArray<{
    readonly __typename?: "PricingRowType";
    readonly id: string;
    readonly articleNo: string;
    readonly description: string;
    readonly transferPricePerUnit?: number | null;
    readonly masterPricePerUnit?: number | null;
    readonly systemId?: string | null;
    readonly rowNo: number;
    readonly type: string;
    readonly visualizerCode: string;
    readonly quantity: number;
    readonly unit: string;
    readonly costPerUnit?: number | null;
    readonly pid?: string | null;
  }>;
  readonly pricingFile: {
    readonly __typename?: "PricingFileType";
    readonly id: string;
    readonly name: string;
    readonly pricingNo: number;
    readonly labels: ReadonlyArray<{
      readonly __typename?: "LabelType";
      readonly id: string;
      readonly name: string;
      readonly userProfileId: string;
    }>;
  };
};

export type PricingFile = {
  readonly __typename?: "PricingFileType";
  readonly id: string;
  readonly name: string;
  readonly pricingNo: number;
  readonly labels: ReadonlyArray<{
    readonly __typename?: "LabelType";
    readonly id: string;
    readonly name: string;
    readonly userProfileId: string;
  }>;
};

export type CompletePricingFile = {
  readonly __typename?: "PricingFileType";
  readonly id: string;
  readonly name: string;
  readonly pricingNo: number;
  readonly owner: {
    readonly __typename?: "UserInfoType";
    readonly id: string;
    readonly name: string;
    readonly userName: string;
  };
  readonly latestPricing: {
    readonly __typename?: "PricingType";
    readonly id: string;
    readonly name: string;
    readonly comment?: string | null;
    readonly status: PricingStatus;
    readonly statusDate: number;
    readonly revisionNo: number;
    readonly masterMode: boolean;
    readonly currencyCode: string;
    readonly salesOrganisationId?: string | null;
    readonly masterSalesOrganisationId?: string | null;
    readonly exchangeRateTemplateId?: string | null;
    readonly owner: {
      readonly __typename?: "UserInfoType";
      readonly id: string;
      readonly name: string;
      readonly userName: string;
    };
    readonly pricingRows: ReadonlyArray<{
      readonly __typename?: "PricingRowType";
      readonly id: string;
      readonly articleNo: string;
      readonly description: string;
      readonly transferPricePerUnit?: number | null;
      readonly masterPricePerUnit?: number | null;
      readonly systemId?: string | null;
      readonly rowNo: number;
      readonly type: string;
      readonly visualizerCode: string;
      readonly quantity: number;
      readonly unit: string;
      readonly costPerUnit?: number | null;
      readonly pid?: string | null;
    }>;
    readonly pricingFile: {
      readonly __typename?: "PricingFileType";
      readonly id: string;
      readonly name: string;
      readonly pricingNo: number;
      readonly labels: ReadonlyArray<{
        readonly __typename?: "LabelType";
        readonly id: string;
        readonly name: string;
        readonly userProfileId: string;
      }>;
    };
  };
  readonly pricings: ReadonlyArray<{
    readonly __typename?: "PricingType";
    readonly id: string;
    readonly name: string;
    readonly comment?: string | null;
    readonly status: PricingStatus;
    readonly statusDate: number;
    readonly revisionNo: number;
    readonly masterMode: boolean;
    readonly currencyCode: string;
    readonly salesOrganisationId?: string | null;
    readonly masterSalesOrganisationId?: string | null;
    readonly exchangeRateTemplateId?: string | null;
    readonly owner: {
      readonly __typename?: "UserInfoType";
      readonly id: string;
      readonly name: string;
      readonly userName: string;
    };
    readonly pricingRows: ReadonlyArray<{
      readonly __typename?: "PricingRowType";
      readonly id: string;
      readonly articleNo: string;
      readonly description: string;
      readonly transferPricePerUnit?: number | null;
      readonly masterPricePerUnit?: number | null;
      readonly systemId?: string | null;
      readonly rowNo: number;
      readonly type: string;
      readonly visualizerCode: string;
      readonly quantity: number;
      readonly unit: string;
      readonly costPerUnit?: number | null;
      readonly pid?: string | null;
    }>;
    readonly pricingFile: {
      readonly __typename?: "PricingFileType";
      readonly id: string;
      readonly name: string;
      readonly pricingNo: number;
      readonly labels: ReadonlyArray<{
        readonly __typename?: "LabelType";
        readonly id: string;
        readonly name: string;
        readonly userProfileId: string;
      }>;
    };
  }>;
  readonly labels: ReadonlyArray<{
    readonly __typename?: "LabelType";
    readonly id: string;
    readonly name: string;
    readonly userProfileId: string;
  }>;
};

export type CompleteSystemFile = {
  readonly __typename?: "SystemFileType";
  readonly id: string;
  readonly name: string;
  readonly genesysNo: number;
  readonly systemTypeId: string;
  readonly isDeleted: boolean;
  readonly owner: {
    readonly __typename?: "UserInfoType";
    readonly id: string;
    readonly name: string;
    readonly userName: string;
  };
  readonly labels: ReadonlyArray<{
    readonly __typename?: "LabelType";
    readonly id: string;
    readonly name: string;
    readonly userProfileId: string;
  }>;
  readonly systems: ReadonlyArray<{
    readonly __typename?: "SystemItemType";
    readonly id: string;
    readonly revisionNo: number;
    readonly status: number;
    readonly statusDate: number;
    readonly comment?: string | null;
    readonly owner: {
      readonly __typename?: "UserInfoType";
      readonly id: string;
      readonly name: string;
      readonly userName: string;
    };
  }>;
};

export type CompleteSystemItem = {
  readonly __typename?: "SystemItemType";
  readonly id: string;
  readonly revisionNo: number;
  readonly status: number;
  readonly statusDate: number;
  readonly comment?: string | null;
  readonly owner: {
    readonly __typename?: "UserInfoType";
    readonly id: string;
    readonly name: string;
    readonly userName: string;
  };
  readonly systemFile: {
    readonly __typename?: "SystemFileType";
    readonly id: string;
    readonly name: string;
    readonly genesysNo: number;
    readonly systemTypeId: string;
    readonly isDeleted: boolean;
    readonly owner: {
      readonly __typename?: "UserInfoType";
      readonly id: string;
      readonly name: string;
      readonly userName: string;
    };
    readonly labels: ReadonlyArray<{
      readonly __typename?: "LabelType";
      readonly id: string;
      readonly name: string;
      readonly userProfileId: string;
    }>;
    readonly systems: ReadonlyArray<{
      readonly __typename?: "SystemItemType";
      readonly id: string;
      readonly revisionNo: number;
      readonly status: number;
      readonly statusDate: number;
      readonly comment?: string | null;
      readonly owner: {
        readonly __typename?: "UserInfoType";
        readonly id: string;
        readonly name: string;
        readonly userName: string;
      };
    }>;
  };
};

export type CompletePricingArticle = {
  readonly __typename?: "PricingArticleType";
  readonly id: string;
  readonly salesOrganisationId: string;
  readonly articleNo: string;
  readonly description: string;
  readonly quantity: number;
  readonly unit: string;
  readonly costPerUnit: number;
  readonly transferPricePerUnit: number;
  readonly sortNo: number;
  readonly currencyCode: string;
  readonly templateGroup?: string | null;
};

export type CompleteSystemTemplateComponentSectionType = {
  readonly __typename?: "SystemTemplateComponentSectionType";
  readonly id: string;
  readonly airstream: string;
  readonly productSectionId: string;
  readonly sortNo: number;
};

export type CompleteTemplateComponentComponentSectionType = {
  readonly __typename?: "TemplateComponentComponentSectionType";
  readonly id: string;
  readonly sections: ReadonlyArray<{
    readonly __typename?: "SystemTemplateComponentSectionType";
    readonly id: string;
    readonly airstream: string;
    readonly productSectionId: string;
    readonly sortNo: number;
  }>;
};

export type MoistureLoadToolUserSettings = {
  readonly __typename?: "MoistureLoadCalcInputSettingsType";
  readonly id: string;
  readonly settings: string;
  readonly caseOverridesDesignSummer?: string | null;
  readonly caseOverridesDesignWinter?: string | null;
  readonly caseOverridesJanuary?: string | null;
  readonly caseOverridesFebruary?: string | null;
  readonly caseOverridesMarch?: string | null;
  readonly caseOverridesApril?: string | null;
  readonly caseOverridesMay?: string | null;
  readonly caseOverridesJune?: string | null;
  readonly caseOverridesJuly?: string | null;
  readonly caseOverridesAugust?: string | null;
  readonly caseOverridesSeptember?: string | null;
  readonly caseOverridesOctober?: string | null;
  readonly caseOverridesNovember?: string | null;
  readonly caseOverridesDecember?: string | null;
  readonly binSize: string;
  readonly binDataLocation: string;
  readonly coolingDataType: string;
  readonly climateSettings: string;
  readonly systemOperationTime?: string | null;
  readonly diffusionThroughMaterialsOperationTime?: string | null;
  readonly evaporationOfWaterOperationTime?: string | null;
  readonly evaporationFromMaterialsOperationTime?: string | null;
  readonly emissionFromCombustionOperationTime?: string | null;
  readonly emissionFromPeopleOperationTime?: string | null;
  readonly heatTransmissionOperationTime?: string | null;
  readonly intentionalVentilationOperationTime?: string | null;
  readonly staticMoistureLoadOperationTime?: string | null;
  readonly staticHeatLoadOperationTime?: string | null;
  readonly unintendedVentilationOperationTime?: string | null;
};

export type MoistureLoadResultFragment = {
  readonly __typename?: "MoistureLoadResultType";
  readonly allBinResults: ReadonlyArray<string>;
  readonly diagramResults: ReadonlyArray<string>;
  readonly diagramResultsToShow: ReadonlyArray<string>;
  readonly summaryAndMonthlyResultsToShow: ReadonlyArray<string>;
  readonly resultWarnings: {
    readonly __typename?: "ResultWarningAndErrorsType";
    readonly diagramWarnings: ReadonlyArray<{
      readonly __typename?: "DiagramWarningType";
      readonly warning: RoomWarningEnum;
      readonly plainTextResultNames: ReadonlyArray<string>;
    }>;
    readonly summaryWarnings: ReadonlyArray<{
      readonly __typename?: "SummaryWarningType";
      readonly warning: RoomWarningEnum;
      readonly localizedResultNames: ReadonlyArray<{
        readonly __typename?: "ResultName";
        readonly name: string;
        readonly nameResourceString: string;
      }>;
    }>;
  };
  readonly monthlyResults: ReadonlyArray<{
    readonly __typename?: "NamedResultType";
    readonly propertyValueSet: string;
    readonly resultName: {
      readonly __typename?: "ResultName";
      readonly name: string;
      readonly nameResourceString: string;
    };
  }>;
  readonly summaryResults: ReadonlyArray<{
    readonly __typename?: "NamedResultType";
    readonly propertyValueSet: string;
    readonly resultName: {
      readonly __typename?: "ResultName";
      readonly name: string;
      readonly nameResourceString: string;
    };
  }>;
  readonly maxLoadResults: ReadonlyArray<{
    readonly __typename?: "CaseMaxLoadResultType";
    readonly id: string;
    readonly selectedFlowId?: number | null;
    readonly caseType: MaxLoadTypeEnum;
    readonly needResourceString: string;
    readonly caseName: string;
    readonly usedRoomControlSettings: string;
    readonly operatingCaseResults: string;
    readonly roomWarnings: ReadonlyArray<RoomWarningEnum>;
    readonly flowSuggestions: ReadonlyArray<{
      readonly __typename?: "CaseFlowSuggestionType";
      readonly id: number;
      readonly flowSourceId: FlowSourceEnum;
      readonly isValid: boolean;
      readonly result: string;
    }>;
  }>;
};
